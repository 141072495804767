import { Identifier } from "auth-ui-client-api";

export const inputIdForIdentifier = (identifier: Identifier): string => {
  switch (true) {
    case identifier.type === "MOBILE":
      return "mobile";
    case identifier.type === "EMAIL":
      return "email";
    case identifier.type === "SELECTED_ACCOUNT":
      return "account";
    default:
      return "login";
  }
};
