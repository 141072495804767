import { Identifier, StorageItem } from "auth-ui-client-api";
import { CaptchaWithValue } from "../../context/captcha/types";

export class ChangePasswordFormData {
  captcha: CaptchaWithValue;
  storage: StorageItem | null;
  identifier: Identifier;
  oldPassword: string | null;
  newPassword: string;
  repeatPassword: string;
  browser: string | undefined;
  os: string | undefined;

  constructor(
    captcha: CaptchaWithValue,
    storage: StorageItem | null,
    identifier: Identifier,
    oldPassword: string | null,
    newPassword: string,
    repeatPassword: string,
    browser: string | undefined,
    os: string | undefined
  ) {
    this.captcha = captcha;
    this.storage = storage;
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
    this.repeatPassword = repeatPassword;
    this.browser = browser;
    this.os = os;

    switch (identifier.type) {
      case "LOGIN":
        this.identifier = {
          type: identifier.type,
          login: identifier.login,
          email: null,
          mobile: null,
          account: null,
          assignment: null,
        };
        break;
      case "EMAIL":
        this.identifier = {
          type: identifier.type,
          login: null,
          email: identifier.email,
          mobile: null,
          account: null,
          assignment: null,
        };
        break;
      case "MOBILE":
        this.identifier = {
          type: identifier.type,
          login: null,
          email: null,
          mobile: identifier.mobile,
          account: null,
          assignment: null,
        };
        break;
      case "SELECTED_ACCOUNT":
        this.identifier = {
          type: identifier.type,
          login: null,
          email: null,
          mobile: null,
          account: identifier.account,
          assignment: identifier.assignment,
        };
        break;
    }
  }
}

export class ChangePasswordFormValidData {
  captcha: CaptchaWithValue;
  storage: StorageItem;
  identifier: Identifier;
  oldPassword: string;
  newPassword: string;
  browser?: string;
  os?: string;

  constructor(
    captcha: CaptchaWithValue,
    storage: StorageItem,
    identifier: Identifier,
    oldPassword: string,
    newPassword: string,
    browser?: string,
    os?: string
  ) {
    this.captcha = captcha;
    this.storage = storage;
    this.identifier = identifier;
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
    this.browser = browser;
    this.os = os;
  }
}
