import React from "react";
import { EmailConfirmationResultCode } from "auth-ui-server";
import { defaultMessages, ErrorMessages } from "../../localization/ErrorMessages";
import { errorMessage, Message, successMessage } from "./Feedback";

export function emailConfirmMessage(
  code: EmailConfirmationResultCode,
  maskedEmail?: string,
  messages: ErrorMessages = defaultMessages
): Message {
  if (code === "CONFIRMED") {
    return successMessage(() => <SuccessMessage maskedEmail={maskedEmail} />);
  }
  switch (code) {
    case "BAD_WORKSTATION":
      return errorMessage(messages.CONFIRM_EMAIL_BAD_WORKSTATION_MESSAGE);
    case "USER_NOT_VALID":
      return errorMessage(messages.CONFIRM_EMAIL_USER_NOT_VALID_MESSAGE);
    case "CODE_REQUIRED":
      return errorMessage(messages.CODE_REQUIRED_MESSAGE);
    case "CODE_INVALID_OR_EXPIRED":
      return errorMessage(messages.CODE_INVALID_OR_EXPIRED_MESSAGE);
    case "DEFAULT_ERROR":
      return errorMessage(messages.DEFAULT_ERROR_MESSAGE);
  }
}

function SuccessMessage(props: { maskedEmail?: string }): React.ReactElement {
  return <span>Адрес электронной почты {props.maskedEmail && <strong>{props.maskedEmail} </strong>}подтвержден </span>;
}
