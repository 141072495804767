import { StorageItem } from "auth-ui-client-api";
import { CaptchaWithValue } from "../../context/captcha/types";

export class StartRecoveryFormData {
  captcha: CaptchaWithValue;
  storage: StorageItem | null;
  email: string | null;

  constructor(captcha: CaptchaWithValue, storage: StorageItem | null, email: string | null) {
    this.captcha = captcha;
    this.storage = storage;
    this.email = email;
  }
}

export class StartRecoveryFormValidData {
  captcha: CaptchaWithValue;
  storage: StorageItem;
  email: string;

  constructor(captcha: CaptchaWithValue, storage: StorageItem, email: string) {
    this.captcha = captcha;
    this.storage = storage;
    this.email = email;
  }
}
