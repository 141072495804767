import get from "./common/get";
import { PasswordPolicyApiResponse } from "auth-ui-client-api";

/**
 * Запрос со страницы, ошибка в лог и пустой ответ
 * @param storageId
 */
export function passwordPolicyForChangeApiRequest(storageId: string): Promise<PasswordPolicyApiResponse | null> {
  return get<PasswordPolicyApiResponse>({ url: `./api/public/password-policy-change?storageId=${storageId}` }).catch(
    (err) => {
      console.error(`Ошибка при получении политик паролей для хранилища ${storageId}: ${err}`);
      return null;
    }
  );
}
