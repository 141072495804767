import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from "react";
import { CaptchaReducer } from "./CaptchaReducer";
import { CaptchaChangeAction } from "./captchaActions";
import { CaptchaWithValue } from "./types";

export const CaptchaContext = createContext<[CaptchaWithValue, Dispatch<CaptchaChangeAction>]>([
  { captchaImage: null, captchaId: null, captchaValue: "" },
  () => undefined,
]);

export const CaptchaProvider = ({ children, captcha }: CaptchaProviderProps): JSX.Element => (
  <CaptchaContext.Provider value={useReducer(CaptchaReducer, { ...captcha, captchaValue: "" })}>
    {children}
  </CaptchaContext.Provider>
);

export const useCaptchaContext = (): CaptchaContext => {
  return useContext<CaptchaContext>(CaptchaContext);
};

export type CaptchaContext = [CaptchaWithValue, Dispatch<CaptchaChangeAction>];

type CaptchaProviderProps = {
  children: ReactNode;
  captcha: Captcha;
};

export type Captcha = {
  captchaId: string;
  captchaImage: string;
  showCaptcha: boolean;
};
