import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useAutofillEffect } from "../../hooks/useAutofillEffect";
import { Input, InputRef } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useFirefoxAutofillEffect } from "../../hooks/useFirefoxAutofillEffect";

export function PasswordInput({
  id,
  name,
  value,
  setter,
  canViewPassword,
  viewPasswordLimitSec,
  placeholder = "",
  testId,
  autoComplete,
  browser,
}: Props): React.FunctionComponentElement<Props> {
  const [type, setType] = useState("password");
  const field = useRef<InputRef>(null);
  const hidePasswordTimer = useRef<number>();

  useAutofillEffect(field, setter);
  useFirefoxAutofillEffect(setType, browser);

  useEffect(() => {
    return () => window.clearTimeout(hidePasswordTimer.current);
  }, []);

  const clickEyeHandler = () => {
    const passwordHidden = type === "password";
    if (passwordHidden) {
      setType("text");
      hidePasswordTimer.current = window.setTimeout(() => {
        setType("password");
      }, viewPasswordLimitSec * 1000);
    } else {
      setType("password");
      window.clearTimeout(hidePasswordTimer.current);
    }
  };

  const keyPressEyeHandler = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      clickEyeHandler();
    }
  };

  return (
    <>
      <Input
        id={id}
        ref={field}
        title={placeholder}
        role="textbox"
        name={name}
        className="form-control"
        type={type}
        placeholder={placeholder}
        value={value}
        maxLength={256}
        onChange={(event) => {
          event.preventDefault();
          setter(event.target.value);
        }}
        data-testid={testId}
        autoComplete={autoComplete}
      />
      {canViewPassword && (
        <div
          tabIndex={0}
          className="password-view"
          onClick={clickEyeHandler}
          onKeyPress={keyPressEyeHandler}
          title={type === "text" ? "Скрыть пароль" : "Показать пароль"}
        >
          {type === "text" ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      )}
    </>
  );
}

type Props = {
  id?: string;
  name?: string;
  value: string;
  setter: (val: string) => void;
  canViewPassword: boolean;
  viewPasswordLimitSec: number;
  placeholder?: string;
  containerClassName?: string;
  testId: string;
  autoComplete?: string;
  browser?: string;
};
