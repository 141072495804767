import React, { ReactElement } from "react";
import { AccountLoggedIn, AccountNeverLoggedIn } from "auth-ui-client-api";
import { SessionListItem } from "./SessionListItem";
import { FieldNames } from "../../localization/FieldNames";

export const LastLogonPanel = ({
  lastLogonData,
  isLoginIdentifierType,
  fieldNames,
  userIcon,
  arrowUpIcon,
  arrowDownIcon,
}: Props): JSX.Element => {
  const hasLogonData = (logonData: Props["lastLogonData"]): logonData is AccountLoggedIn =>
    !logonData.accountNeverLoggedIn;

  return (
    <div className={`LastLogonPanel${!hasLogonData(lastLogonData) ? " no-sessions" : ""}`}>
      {!hasLogonData(lastLogonData) ? (
        <h3>Вход в систему не выполнялся</h3>
      ) : (
        <>
          <h3 className="last-logon-header">Последний вход в систему</h3>
          <div>
            <SessionListItem
              item={lastLogonData.data}
              isLoginIdentifierType={isLoginIdentifierType}
              fieldNames={fieldNames}
              userIcon={userIcon}
              arrowUpIcon={arrowUpIcon}
              arrowDownIcon={arrowDownIcon}
            />
          </div>
        </>
      )}
    </div>
  );
};

type Props = {
  lastLogonData: AccountNeverLoggedIn | AccountLoggedIn;
  isLoginIdentifierType: boolean;
  fieldNames: FieldNames;
  userIcon?: ReactElement;
  arrowUpIcon?: ReactElement;
  arrowDownIcon?: ReactElement;
};
