import { Dispatch, SetStateAction, useEffect } from "react";
import { checkWarnings } from "../components/message/checkWarnings";
import { Message, warningMessage } from "../components/message/Feedback";
import { defaultMessages } from "../localization/ErrorMessages";

export const SESSIONCONFIRM_KEY = "sessionConfirmToken";

export const setSessionConfirmTokenToLocalStorage = (window: Window, newSessionConfirmToken: string): void => {
  window.localStorage.setItem(SESSIONCONFIRM_KEY, JSON.stringify(newSessionConfirmToken));
};

export const useSessionConfirmToken = (
  window: Window,
  sessionConfirmProp:
    | {
        sessionConfirmToken: string;
        warnPasswordExpiresInDays?: number | null;
        warnAccountExpiresInDays?: number | null;
      }
    | undefined,
  setSessionConfirmToken: Dispatch<SetStateAction<string | null>>,
  messages = defaultMessages,
  service: string | null,
  setShowLoginForm: (value: boolean) => void,
  setFeedback: (value: Message[]) => void
): void => {
  useEffect(() => {
    if (!service) {
      return;
    }
    if (sessionConfirmProp) {
      const redirectForSessionConfirm = (sessionConfirmToken: string) => {
        setSessionConfirmTokenToLocalStorage(global.window, sessionConfirmToken);
        setSessionConfirmToken(sessionConfirmToken);
      };

      const warnings = checkWarnings(
        () => redirectForSessionConfirm(sessionConfirmProp.sessionConfirmToken),
        service,
        messages,
        sessionConfirmProp.warnAccountExpiresInDays,
        sessionConfirmProp.warnPasswordExpiresInDays
      );
      if (warnings.length) {
        setShowLoginForm(false);
        setFeedback(warnings.map((warn) => warningMessage(warn)));
      } else {
        redirectForSessionConfirm(sessionConfirmProp.sessionConfirmToken);
      }
    } else {
      const sessionConfirm = window.localStorage.getItem(SESSIONCONFIRM_KEY);
      const sessionConfirmFromLocalStorage = sessionConfirm && sessionConfirm.length ? JSON.parse(sessionConfirm) : "";
      if (!sessionConfirmFromLocalStorage.length) {
        return;
      }
      setSessionConfirmToken(sessionConfirmFromLocalStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
