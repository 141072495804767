import React, { ReactElement } from "react";
import { SelectLabelProps } from "./properties";

export function SelectLabel<DomainObject>({
  domainObject,
  searchParam,
  render,
  className,
}: SelectLabelProps<DomainObject>): ReactElement {
  return <div className={className ?? undefined}>{render(domainObject, searchParam)}</div>;
}
