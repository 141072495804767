import { Dispatch, SetStateAction, useEffect } from "react";
import { detect } from "detect-browser";

declare global {
  interface Window {
    platform: any;
  }
}

export const useNavigatorData = (
  setOs: Dispatch<SetStateAction<string | undefined>>,
  setBrowser: Dispatch<SetStateAction<string | undefined>>
): void => {
  useEffect(() => {
    const browser = detect();
    if (!browser) {
      return;
    }
    if (browser.os) {
      setOs(browser.os);
    }
    const browserName = browser.name;
    if (!browserName) {
      return;
    }
    const browserVersion = browser.version ? ` ${browser.version}` : "";
    setBrowser(`${capitalize(browserName)}${browserVersion}`);
  }, [setBrowser, setOs]);
};

const capitalize = (str: string) => str.replace(/^\w/, (c) => c.toUpperCase());
