import { AdditionalInfoField } from "./AdditionalInfoField";
import { additionalInfo, IdentifierTypeLocalization } from "./utils/additionalInfo";
import React from "react";
import { AdditionalData } from "auth-ui-client-api";

export const AdditionalInfoPanel = ({ data, localization }: Props): JSX.Element => {
  return (
    <>
      <div className="AdditionalInfoPanel">
        <AdditionalInfoField data={additionalInfo(data.identifierType, localization)} />
      </div>
    </>
  );
};

type Props = { data: AdditionalData; localization: IdentifierTypeLocalization };
