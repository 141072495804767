import React, { Dispatch, ReactElement, SetStateAction, useEffect, useRef } from "react";
import { ERROR_PAGE_MESSAGE } from "../../localization/ErrorMessages";
import { CloseAlertButton } from "../buttons/CloseAlertButton";

export type Message = {
  key: string;
  text: FeedbackMessageContent;
  level: string;
  className: string;
};

export type RedirectData = {
  link: string;
  message: string;
};

export type FeedbackMessageContent = string | ComponentCreator;

export type FeedbackProps = {
  feedbackMessages: Message[];
  setFeedbackMessages?: Dispatch<SetStateAction<Message[]>>;
  returnText?: string;
};

export type MessageProps = {
  message: Message;
  onCloseBtn?: () => void;
  returnText?: string;
};

export type ComponentCreator = () => ReactElement;

export function Feedback({ feedbackMessages, setFeedbackMessages, returnText }: FeedbackProps): ReactElement | null {
  const deleteMessage = (messageKey: string): void => {
    if (feedbackMessages.find((item) => item.key === messageKey)?.text === ERROR_PAGE_MESSAGE) {
      window.location.href = `${window.location.origin}${window.location.pathname}?service=${
        Object.fromEntries(new URL(window.location.toString()).searchParams.entries()).service
      }`;
    } else {
      setFeedbackMessages && setFeedbackMessages(feedbackMessages.filter((item) => item.key !== messageKey));
    }
  };

  const feedbackContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    feedbackContainer.current?.scrollIntoView();
  }, [feedbackMessages]);

  return feedbackMessages.length ? (
    <div className="feedback" ref={feedbackContainer}>
      {feedbackMessages.map((item) => (
        <FeedbackMessage
          key={item.key}
          message={item}
          onCloseBtn={
            setFeedbackMessages &&
            (() => {
              deleteMessage(item.key);
            })
          }
          returnText={returnText}
        />
      ))}
    </div>
  ) : null;
}

function FeedbackMessage(props: MessageProps) {
  const {
    message: { text, level, className },
    onCloseBtn,
  } = props;

  const finalClassName = `alert alert-${level} ${className || ""}`;

  return (
    <div role="alert" className={finalClassName}>
      {typeof text === "string" ? <span> {text} </span> : text()}
      {onCloseBtn && <CloseAlertButton onClick={onCloseBtn} dataTestId={"CloseAlertButton"} />}
    </div>
  );
}

export const errorMessage = (text: FeedbackMessageContent, className?: string): Message => {
  return createMessage(text, "error", className);
};

export const successMessage = (text: FeedbackMessageContent, className?: string): Message => {
  return createMessage(text, "success", className);
};

export const infoMessage = (text: FeedbackMessageContent, className?: string): Message => {
  return createMessage(text, "info", className);
};

export const warningMessage = (text: FeedbackMessageContent, className?: string): Message => {
  return createMessage(text, "warning", className);
};

const createMessage = (text: FeedbackMessageContent, level: string, className?: string): Message => {
  return {
    key: uniqueKey(),
    text: text,
    level: level,
    className: className || "",
  };
};

function uniqueKey(): string {
  return "_" + Math.random().toString(36).substr(2, 9);
}
