import { defaultMessages, ErrorMessages } from "./ErrorMessages";
import { errorMessage, Message, successMessage } from "../components/message/Feedback";
import { AccessDeniedServerPropsCode, LoginServerPropsCode } from "auth-ui-server";
import { LogoutMessage } from "../components/message/LogoutMessage";
import React from "react";
import { MessageWithRedirect } from "../components/message/MessageWithRedirect";
import { recoveryPasswordLink } from "../components/clientLinks";
import { contactsErrorMessage } from "../submit/localization/contactsErrorMessage";

export function getChangePasswordPageError(
  code: string,
  service: string | null,
  messages: ErrorMessages = defaultMessages
): Message {
  switch (code) {
    case "SERVICE_REQUIRED":
      return errorMessage(messages.SERVICE_REQUIRED_MESSAGE);
    case "SERVICE_NOT_FOUND":
      return errorMessage(`${messages.SERVICE_NOT_FOUND_MESSAGE} ${service}`);
    case "GENERAL_ERROR":
      return errorMessage(messages.GENERAL_ERROR_MESSAGE);
  }
  throw new Error(`Код ошибки не локализован: ${code}`);
}

export function getLoginPageError(
  code: LoginServerPropsCode,
  service: string | null,
  messages: ErrorMessages = defaultMessages
): Message {
  switch (code) {
    case "SERVICE_REQUIRED":
      return errorMessage(messages.SERVICE_REQUIRED_MESSAGE);
    case "SERVICE_NOT_FOUND":
      return errorMessage(`${messages.SERVICE_NOT_FOUND_MESSAGE} ${service}`);
    case "SERVICE_LOCKED":
      return errorMessage(messages.SERVICE_LOCKED_MESSAGE);
    case "SERVICE_SCHEDULE_VIOLATION":
      return errorMessage(messages.SERVICE_SCHEDULE_VIOLATION_MESSAGE);
    case "BLOCKED_BY_WORK_ABSENCE":
      return errorMessage(messages.BLOCKED_BY_WORK_ABSENCE_MESSAGE);
    case "USER_NOT_FOUND":
    case "INTERNAL_ERROR":
    case "UNAVAILABLE":
    case "VALIDATION_ERROR":
    case "INVALID_TOKEN":
    case "GENERAL_ERROR":
      return errorMessage(messages.GENERAL_ERROR_MESSAGE);
    case "LOGOUT_ERROR":
      return errorMessage(messages.LOGOUT_ERROR_MESSAGE);
    case "LOGOUT_SUCCESS":
      return successMessage(messages.LOGOUT_SUCCESS_MESSAGE);
    case "PASSWORD_CHANGED_SUCCESS":
      return successMessage(messages.PASSWORD_CHANGED_MESSAGE);
    case "ACCOUNT_DISABLED":
      return errorMessage(messages.ACCOUNT_DISABLED_MESSAGE);
    case "BAD_CREDENTIALS":
      return errorMessage(messages.BAD_CREDENTIALS_LOGIN_MESSAGE);
  }
  throw new Error(`Код ошибки не локализован: ${code}`);
}

export function getRecoverPageError(
  code: string,
  service: string | null,
  storageId: string | null,
  messages: ErrorMessages = defaultMessages
): Message {
  switch (code) {
    case "TOKEN_REQUIRED":
      return errorMessage(messages.RECOVER_PASSWORD_TOKEN_REQUIRED_MESSAGE);
    case "SERVICE_NOT_FOUND":
      return errorMessage(`${messages.SERVICE_NOT_FOUND_MESSAGE} ${service}`);
    case "SERVICE_REQUIRED":
      return errorMessage(messages.SERVICE_REQUIRED_MESSAGE);
    case "INVALID_TOKEN":
      if (!service) {
        return errorMessage(messages.SERVICE_REQUIRED_MESSAGE);
      }
      return errorMessage(() => (
        <MessageWithRedirect
          url={recoveryPasswordLink({ service, storageId })}
          messageTitle="Переход на начало восстановления пароля"
          linkTitle="Перейти к восстановлению пароля"
        >
          {messages.INVALID_TOKEN_MESSAGE}
        </MessageWithRedirect>
      ));
    case "GENERAL_ERROR":
      return errorMessage(messages.GENERAL_ERROR_MESSAGE);
  }
  throw new Error(`Код ошибки не локализован: ${code}`);
}

export function getSetPasswordPageError(code: string, messages: ErrorMessages = defaultMessages): Message {
  switch (code) {
    case "TOKEN_REQUIRED":
      return errorMessage(messages.SET_PASSWORD_TOKEN_REQUIRED_MESSAGE);
    case "INVALID_TOKEN":
      return errorMessage(messages.INVALID_SET_PASSWORD_TOKEN_MESSAGE);
    case "GENERAL_ERROR":
      return errorMessage(messages.GENERAL_ERROR_MESSAGE);
  }
  throw new Error(`Код ошибки не локализован: ${code}`);
}

export function getStartRecoveryPageError(
  code: string,
  service?: string,
  messages: ErrorMessages = defaultMessages
): Message {
  switch (code) {
    case "SERVICE_REQUIRED":
      return errorMessage(messages.SERVICE_REQUIRED_MESSAGE);
    case "SERVICE_NOT_FOUND":
      return errorMessage(`${messages.SERVICE_NOT_FOUND_MESSAGE} ${service}`);
    case "GENERAL_ERROR":
      return errorMessage(messages.GENERAL_ERROR_MESSAGE);
  }
  throw new Error(`Код ошибки не локализован: ${code}`);
}

export function getAccessDeniedPageError(
  code: AccessDeniedServerPropsCode,
  service: string | null,
  messages: ErrorMessages = defaultMessages
): Message {
  switch (code) {
    case "SERVICE_SCHEDULE_VIOLATION":
      return errorMessage(messages.SERVICE_SCHEDULE_VIOLATION_MESSAGE);
    case "EMAIL_NOT_DEFINED":
    case "MOBILE_NOT_DEFINED":
    case "EMAIL_NOT_CONFIRMED":
    case "MOBILE_NOT_CONFIRMED":
      return errorMessage(() => <LogoutMessage service={service}>{contactsErrorMessage(code)}</LogoutMessage>);
    case "ACCESS_PERIOD_NOT_STARTED":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.ACCESS_PERIOD_NOT_STARTED_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "ACCESS_PERIOD_ENDED":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.ACCESS_PERIOD_ENDED_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "ACCOUNT_DISABLED":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.ACCOUNT_DISABLED_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "NOT_HIRED":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.NOT_HIRED_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "FIRED":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.FIRED_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "BLOCKED_BY_WORK_ABSENCE":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.BLOCKED_BY_WORK_ABSENCE_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "BAD_HOURS":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.BAD_HOURS_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "SERVICE_LOCKED":
      return errorMessage(messages.SERVICE_LOCKED_MESSAGE);
    case "NOT_ENOUGH_RIGHTS":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{messages.NOT_ENOUGH_RIGHTS_MESSAGE}</span>
        </LogoutMessage>
      ));
    case "SERVICE_NOT_FOUND":
      return errorMessage(messages.SERVICE_NOT_FOUND_MESSAGE);
    case "USER_ARCHIVED":
      return errorMessage(messages.USER_ARCHIVED_MESSAGE);
    case "ASSIGNMENT_ARCHIVED":
      return errorMessage(messages.ASSIGNMENT_ARCHIVED_MESSAGE);
    case "ACCOUNT_ARCHIVED":
      return errorMessage(messages.ACCOUNT_ARCHIVED_MESSAGE);
    case "TICKET_CHECK_ERROR":
    case "GENERAL_ACCESS_DENIED_ERROR":
      return errorMessage(() => (
        <LogoutMessage service={service}>
          <span>{`${messages.GENERAL_ERROR_MESSAGE}`}</span>
        </LogoutMessage>
      ));
  }
  throw new Error(`Код ошибки не локализован: ${code}`);
}
