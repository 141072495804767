import {
  CaptchaChangeAction,
  setCaptchaImage,
  setCaptchaValue,
  setShowCaptcha,
} from "../../context/captcha/captchaActions";
import { Dispatch } from "react";

export const setNewCaptcha = (dispatchCaptcha: Dispatch<CaptchaChangeAction>, captcha?: string | null): void => {
  const showCaptcha = !!captcha;
  dispatchCaptcha(setShowCaptcha(showCaptcha));
  dispatchCaptcha(setCaptchaImage(captcha || null));
  dispatchCaptcha(setCaptchaValue(""));
};

export const clearCaptcha = (dispatchCaptcha: Dispatch<CaptchaChangeAction>): void => {
  dispatchCaptcha(setShowCaptcha(false));
  dispatchCaptcha(setCaptchaImage(null));
  dispatchCaptcha(setCaptchaValue(""));
};
