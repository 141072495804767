import post from "./common/post";
import { TFAEmailApiRequest, TFAEmailApiResponse } from "auth-ui-client-api";

interface RequestBody {
  login: string | null;
  mobile: string | null;
  email: string | null;
  storageId: string;
}

export async function tfaEmailApiRequest(body: RequestBody): Promise<TFAEmailApiResponse> {
  return await tfaEmailRequest(body);
}

function tfaEmailRequest(body: TFAEmailApiRequest): Promise<TFAEmailApiResponse> {
  return post<TFAEmailApiRequest, TFAEmailApiResponse>({
    url: `./api/public/confirmed-email`,
    body,
  });
}
