import { Identifier } from "auth-ui-client-api";

export const changePasswordLink = ({
  service,
  identifier,
  storageId,
}: {
  service: string;
  identifier?: Identifier | null;
  storageId?: string | null;
}): string => {
  const params: Record<string, string> = {};
  setParam(params, "service", service);
  setIdentifierParams(params, identifier);
  setParam(params, "storageId", storageId);
  return createUrl("./change-password", params);
};

export const recoveryPasswordLink = ({
  service,
  storageId,
}: {
  service: string;
  storageId?: string | null;
}): string => {
  const params: Record<string, string> = {};
  setParam(params, "service", service);
  setParam(params, "storageId", storageId);
  return createUrl("./recovery-password", params);
};

export const logoutLink = (service: string | null): string => {
  const params: Record<string, string> = {};
  setParam(params, "url", service);
  return createUrl("./logout", params);
};

export const loginLink = ({
  service,
  identifier,
  storageId,
  feedbackCode,
}: {
  service?: string | null;
  identifier?: Identifier | null;
  storageId?: string | null;
  feedbackCode?: string | null;
}): string => {
  const params: Record<string, string> = {};
  setParam(params, "service", service);
  setIdentifierParams(params, identifier);
  setParam(params, "storageId", storageId);
  setParam(params, "feedbackCode", feedbackCode);
  return createUrl("./login", params);
};

const createUrl = (baseUrl: string, params: Record<string, string>) => {
  const paramsString = new URLSearchParams(params).toString();
  return `${baseUrl}${paramsString ? `?${paramsString}` : ""}`;
};

const setParam = (params: Record<string, string>, name: string, value?: string | null) => {
  if (value) {
    params[name] = value;
  }
};

const setIdentifierParams = (params: Record<string, string>, identifier?: Identifier | null): void => {
  if (!identifier) {
    return;
  }
  params.identifierType = identifier.type;
  const currentId = getCurrentId(identifier);
  if (currentId) {
    params.identifierId = currentId;
    if (identifier.type === "SELECTED_ACCOUNT" && identifier.assignment) {
      params.assignmentId = identifier.assignment.id;
    }
  }
};

const getCurrentId = (identifier: Identifier): string | null => {
  switch (identifier.type) {
    case "LOGIN":
      return identifier.login;
    case "EMAIL":
      return identifier.email;
    case "MOBILE":
      return identifier.mobile;
    case "SELECTED_ACCOUNT":
      return identifier.account?.id || null;
    default:
      return null;
  }
};
