import React from "react";
import { DateTimeField } from "../../utils/DateTimeField";
import { LastLogonStatusInfo } from "./utils/types";

export function StatusField({ statusInfo }: Props): JSX.Element {
  return (
    <div className="status">
      {statusInfo.closed ? (
        <>
          <span>Сеанс завершен</span>
          <span>
            <DateTimeField date={statusInfo.date} />
          </span>
        </>
      ) : (
        <>
          <span>Сеанс активен</span>
          <span className="active-status-date">
            {statusInfo.date && `до `}
            <DateTimeField date={statusInfo.date} />
          </span>
        </>
      )}
    </div>
  );
}

type Props = { statusInfo: LastLogonStatusInfo };
