import { AccountApiRequest, AccountApiResponse } from "auth-ui-client-api";
import get from "./common/get";

export async function accountApiRequest(identifierId: string): Promise<AccountApiResponse> {
  const body = { identifierId: identifierId };
  try {
    return await accountRequest(body);
  } catch (err) {
    console.error(`Произошла ошибка при восстановлении пароля`, err);
  }
  return { errorCode: "INTERNAL_ERROR" };
}

function accountRequest(body: AccountApiRequest): Promise<AccountApiResponse> {
  return get<AccountApiResponse>({ url: `./api/public/accounts/${body.identifierId}` });
}
