import { CancellingFetcher } from "../fetching/CancellingFetcher";
import { BuildUrlFunc } from "./urlBuilder/urlBuilder";
import { GetItems, ItemsPage } from "../properties";
import { buildQueryUrl, SupportedQueryParamValue } from "@frte/js-utils";

const getItemsActionCreator = <DomainObject>(buildUrl: BuildUrlFunc): GetItems<DomainObject> => {
  const fetcher = new CancellingFetcher();
  return async (offset: number, criteria?: string) => {
    const url = buildUrl(offset, criteria);
    if (criteria) {
      return fetcher.doCanceledFetch<ItemsPage<DomainObject>>(url);
    } else {
      return fetcher.doFetch<ItemsPage<DomainObject>>(url);
    }
  };
};

export const createSelectAction = <ITEM>(
  url: string,
  queryParams?: Record<string, SupportedQueryParamValue>
): GetItems<ITEM> => {
  return url
    ? getItemsActionCreator((offset: number, criteria?: string): string => {
        return buildQueryUrl(
          url,
          Object.assign(
            {},
            queryParams,
            criteria ? { pageOffset: offset, criteria: encodeURIComponent(criteria) } : { pageOffset: offset }
          )
        );
      })
    : () => Promise.resolve({ error: true });
};
