export type TwoFactorAuthPostApiRequest = {
  twoFactorAuthId: string;
  login: string | null;
  mobile: string | null;
  email: string | null;
  storageId: string;
};

export type TwoFactorAuthPostApiResponse = TwoFactorAuthPostApiSuccess | TwoFactorAuthPostApiError;

export type TwoFactorAuthPostApiSuccess = {
  code?: string;
  message?: string;
};

export type TwoFactorAuthPostApiError = {
  code?: string;
  message?: string;
  errorCode?: "INTERNAL_ERROR";
};

export const isTwoFactorAuthPostApiError = (data: TwoFactorAuthPostApiResponse): data is TwoFactorAuthPostApiError =>
  !!data && "errorCode" in data && data.errorCode !== undefined;
