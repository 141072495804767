import { isLoginFormValid, LoginClientValidationErrorReason, validateLoginForm } from "./validateLoginForm";
import { errorMessage, FeedbackMessageContent, Message, warningMessage } from "../../components/message/Feedback";
import {
  Identifier,
  isHideLoginFormError,
  isLoginApiError,
  isLoginApiNeedsConfirmation,
  isTFAEMailApiError,
  isTwoFactorAuthPostApiError,
  LoginApiError,
  LoginApiResponse,
  ValidationProperties,
} from "auth-ui-client-api";
import { clearCaptcha, setNewCaptcha } from "../common/dispatchCaptcha";
import { LoginFormData, LoginFormValidData } from "./LoginFormData";
import { LoginUrls } from "auth-ui-server";
import React, { Dispatch, SetStateAction } from "react";
import { CaptchaChangeAction } from "../../context/captcha/captchaActions";
import { setSessionConfirmTokenToLocalStorage } from "../../hooks/useSessionConfirmToken";
import { loginLink } from "../../components/clientLinks";
import { clientLogger } from "../../logger";
import { defaultMessages, ErrorMessages } from "../../localization/ErrorMessages";
import { MustChangePassword } from "../../components/message/MustChangePassword";
import { LogoutMessage } from "../../components/message/LogoutMessage";
import { requiredFieldsErrorMessage } from "../../components/message/requiredFieldsErrorMessage";
import { FieldNames } from "../../localization/FieldNames";
import badCredentialsMessage from "../localization/badCredentialsMessage";
import { ValidationInfo } from "../utils/validationUtils";
import { saveSelectedValuesInCookie, setActiveTabCookie } from "../../utils/clientCookies";
import { checkWarnings } from "../../components/message/checkWarnings";
import { tfaEmailApiRequest, twoFactorAuthPostApiRequest, twoFactorAuthPutApiRequest } from "auth-ui-client";

const getTwoFactorAuthToken = () => Math.random().toString(20).slice(5);

export async function onLoginSubmit({
  validation,
  formData,
  isAssignmentsEmpty,
  service,
  setFeedback,
  setSubmitting,
  setSessionConfirmToken,
  dispatchCaptcha,
  rememberSelectedValues,
  loginApiRequest,
  messages = defaultMessages,
  fieldNames,
  setShowLoginForm,
  setShowOTPForm,
  setOTPSuccessCallback,
  setOTPEmail,
  setOTPLength,
  customValidationOptions = null,
  isTFAEnabled = false,
  otpCodeLength,
}: Options): Promise<void> {
  const validationResult = validateLoginForm(validation, formData, isAssignmentsEmpty);
  if (!isLoginFormValid(validationResult)) {
    customValidationOptions
      ? validationResult.map((reason) =>
          customClientValidationError(reason, messages, fieldNames, customValidationOptions, setFeedback),
        )
      : setFeedback(
          validationResult.map((reason) => errorMessage(clientValidationError(reason, messages, fieldNames))),
        );
    return;
  }
  setSubmitting(true);
  const response = await loginApiRequest(validationResult, service, isTFAEnabled);
  setSubmitting(false);

  const { identifier, storage } = validationResult;

  if (!response) {
    setFeedback([
      errorMessage(apiError({ errorCode: "INTERNAL_ERROR" }, identifier, service, storage.id, messages, fieldNames)),
    ]);
    return;
  }

  if (isHideLoginFormError(response)) {
    setShowLoginForm(false);
    setFeedback([errorMessage(apiError(response, identifier, service, storage.id, messages, fieldNames))]);
    return;
  }

  customValidationOptions && customValidationOptions.captchaClearField();

  if (isLoginApiError(response)) {
    setNewCaptcha(dispatchCaptcha, response.captcha);
    setFeedback([errorMessage(apiError(response, identifier, service, storage.id, messages, fieldNames))]);
    return;
  }

  clearCaptcha(dispatchCaptcha);

  setActiveTabCookie(identifier.type, document);
  saveSelectedValuesInCookie(rememberSelectedValues, storage, identifier);

  const redirectForSessionConfirm = (sessionConfirmToken: string) => {
    setSessionConfirmTokenToLocalStorage(global.window, sessionConfirmToken);
    setSessionConfirmToken(sessionConfirmToken);
  };

  const redirectToPage = (pageUrl: string) => {
    setSubmitting(true);
    window.location.href = pageUrl;
  };

  const redirectAction = isLoginApiNeedsConfirmation(response)
    ? () => redirectForSessionConfirm(response.sessionConfirmToken)
    : () => redirectToPage(loginLink({ service })); // пока пользователь будет смотреть на warnings,
  // serviceTicket может протухнуть, поэтому перенаправляем на login-страницу

  const warnings = checkWarnings(
    redirectAction,
    service,
    messages,
    response.warnAccountExpiresInDays,
    response.warnPasswordExpiresInDays,
  );

  if (warnings.length) {
    setShowLoginForm(false);
    setFeedback(warnings.map((warn) => warningMessage(warn)));
    return;
  }

  if (isLoginApiNeedsConfirmation(response)) {
    redirectForSessionConfirm(response.sessionConfirmToken);
    return;
  }

  const redirectToService = () => {
    clientLogger.log(`Переход к сервису ${response.location}`);
    console.log(`Переход к сервису ${response.location}`);
    redirectToPage(response.location); // здесь можем перевести сразу на сервис, т.к. serviceTicket не успеем протухнуть
  };

  if (isTFAEnabled && setShowOTPForm && setOTPSuccessCallback && setOTPLength && setOTPEmail) {
    const login = identifier.login || identifier.account?.login || null;
    const storageId = storage.id;
    const { mobile, email } = identifier;

    const tfaEmailResponse = await tfaEmailApiRequest({ login, mobile, email, storageId });

    if (isTFAEMailApiError(tfaEmailResponse)) {
      setFeedback([errorMessage(tfaEmailResponse.message || "Произошла ошибка")]);
      return;
    }

    const twoFactorAuthId = getTwoFactorAuthToken();
    const tfaResponse = await twoFactorAuthPostApiRequest({
      twoFactorAuthId,
      login,
      mobile,
      email,
      storageId,
    });

    if (isTwoFactorAuthPostApiError(tfaResponse)) {
      setFeedback([errorMessage(tfaResponse?.message || "Произошла ошибка")]);
      return;
    }

    setFeedback([]);
    setOTPEmail(tfaEmailResponse.data || "");
    setOTPLength(otpCodeLength || 0);
    setShowOTPForm(true);
    setOTPSuccessCallback(() => async (twoFactorAuthCode: string) => {
      const tfaSubmitResponse = await twoFactorAuthPutApiRequest({ twoFactorAuthId, twoFactorAuthCode });

      if (!tfaSubmitResponse?.checkResult) {
        setFeedback([errorMessage(tfaResponse?.message || "Произошла ошибка")]);
        return;
      }

      setFeedback([]);

      const response = await loginApiRequest(validationResult, service);

      if (!response) {
        setFeedback([
          errorMessage(
            apiError({ errorCode: "INTERNAL_ERROR" }, identifier, service, storage.id, messages, fieldNames),
          ),
        ]);
        return;
      }

      if (isLoginApiError(response)) {
        setNewCaptcha(dispatchCaptcha, response.captcha);
        setFeedback([errorMessage(apiError(response, identifier, service, storage.id, messages, fieldNames))]);
        return;
      }

      if (isLoginApiNeedsConfirmation(response)) {
        redirectForSessionConfirm(response.sessionConfirmToken);
        return;
      }

      redirectToPage(response.location);
    });
  } else {
    redirectToService();
  }
}

const customClientValidationError = (
  reason: LoginClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames,
  customValidationOptions: CustomValidationOptions,
  setFeedback: Dispatch<SetStateAction<Message[]>>,
) => {
  switch (reason.code) {
    case "UNDEFINED_ENTER_METHOD":
      setFeedback([errorMessage(messages.UNDEFINED_ENTER_METHOD)]);
      return;
    case "EMPTY_FIELDS":
      setFeedback([
        errorMessage(
          requiredFieldsErrorMessage(
            reason.fields.map(fieldNames.fieldName),
            messages.FIELDS_REQUIRED_PREFIX,
            messages.FIELD_REQUIRED_PREFIX,
          ),
        ),
      ]);
      return;
    case "WRONG_MOBILE_FORMAT":
      customValidationOptions.setMobileValidation({
        validateStatus: "error",
        help: (
          <div data-testid="ValidationMessage">
            {reason.description || messages.DEFAULT_MOBILE_INVALID_FORMAT_MESSAGE}
          </div>
        ),
      });
      return;
    case "WRONG_EMAIL_FORMAT":
      customValidationOptions.setEmailValidation({
        validateStatus: "error",
        help: <div data-testid="ValidationMessage">{messages.EMAIL_INVALID_FORMAT_MESSAGE}</div>,
      });
      return;
    default:
      throw new Error(`Ошибка выполнения смены пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const clientValidationError = (
  reason: LoginClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames,
): FeedbackMessageContent => {
  switch (reason.code) {
    case "UNDEFINED_ENTER_METHOD":
      return messages.UNDEFINED_ENTER_METHOD;
    case "EMPTY_FIELDS":
      return requiredFieldsErrorMessage(
        reason.fields.map(fieldNames.fieldName),
        messages.FIELDS_REQUIRED_PREFIX,
        messages.FIELD_REQUIRED_PREFIX,
      );
    case "WRONG_MOBILE_FORMAT":
      return reason.description || messages.DEFAULT_MOBILE_INVALID_FORMAT_MESSAGE;
    case "WRONG_EMAIL_FORMAT":
      return messages.EMAIL_INVALID_FORMAT_MESSAGE;
    default:
      throw new Error(`Ошибка выполнения смены пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const apiError = (
  apiError: LoginApiError,
  identifier: Identifier,
  service: string,
  storageId: string,
  messages: ErrorMessages,
  fieldNames: FieldNames,
): FeedbackMessageContent => {
  let _exhaustedCode: never;
  switch (apiError.errorCode) {
    case "ACCOUNT_MULTIPLE_FOUND":
    case "ACCOUNT_NOT_FOUND":
    case "BAD_CREDENTIALS":
    case "VALIDATION_ERROR":
      return badCredentialsMessage(identifier, messages);
    case "BAD_CAPTCHA":
      return messages.BAD_CAPTCHA_VALUE_MESSAGE;
    case "MUST_CHANGE_PASSWORD":
      return () => (
        <MustChangePassword
          service={service}
          storageId={storageId}
          identifier={identifier}
          title={fieldNames.GO_TO_CHANGE_PASSWORD_LINK}
          mustChangePasswordMessage={messages.MUST_CHANGE_PASSWORD_MESSAGE}
        />
      );
    case "PASSWORD_EXPIRED": {
      return () => (
        <MustChangePassword
          service={service}
          storageId={storageId}
          identifier={identifier}
          title={fieldNames.GO_TO_CHANGE_PASSWORD_LINK}
          mustChangePasswordMessage={messages.PASSWORD_EXPIRED_MESSAGE}
        />
      );
    }
    case "SERVICE_NOT_FOUND":
      return () => (
        <LogoutMessage service={service}>
          <span>{`${messages.SERVICE_NOT_FOUND_MESSAGE} ${service}`}</span>
        </LogoutMessage>
      );
    case "SERVICE_LOCKED":
      return messages.SERVICE_LOCKED_MESSAGE;
    case "SERVICE_SCHEDULE_VIOLATION":
      return messages.SERVICE_SCHEDULE_VIOLATION_MESSAGE;
    case "BLOCKED_BY_WORK_ABSENCE":
      return messages.BLOCKED_BY_WORK_ABSENCE_MESSAGE;
    case "ACCOUNT_LOCKED":
      return messages.ACCOUNT_LOCKED_MESSAGE;
    case "ACCOUNT_DISABLED":
      return messages.ACCOUNT_DISABLED_MESSAGE;
    case "BAD_WORKSTATION":
      return messages.BAD_WORKSTATION_MESSAGE;
    case "BAD_HOURS":
      return messages.BAD_HOURS_MESSAGE;
    case "SEND_EMAIL_ERROR":
      return messages.SEND_EMAIL_ERROR_MESSAGE;
    case "INTERNAL_ERROR":
    case "UNAVAILABLE":
      return messages.GENERAL_ERROR_MESSAGE;
    case "ACCESS_PERIOD_NOT_STARTED":
      return messages.ACCESS_PERIOD_NOT_STARTED_MESSAGE;
    case "ACCESS_PERIOD_ENDED":
      return messages.ACCESS_PERIOD_ENDED_MESSAGE;
    case "NOT_HIRED":
      return messages.NOT_HIRED_MESSAGE;
    case "FIRED":
      return messages.FIRED_MESSAGE;
    default:
      _exhaustedCode = apiError.errorCode;
      throw new Error(`Неизвестный код ошибки: ${_exhaustedCode}`);
  }
};

type Options = {
  validation: ValidationProperties;
  formData: LoginFormData;
  isAssignmentsEmpty: boolean;
  rememberSelectedValues: boolean;
  service: string;
  setFeedback: Dispatch<SetStateAction<Message[]>>;
  setSubmitting: Dispatch<boolean>;
  dispatchCaptcha: Dispatch<CaptchaChangeAction>;
  setSessionConfirmToken: Dispatch<SetStateAction<string | null>>;
  urls: LoginUrls;
  loginApiRequest: (
    formData: LoginFormValidData,
    service: string,
    isTFAEnabled?: boolean,
  ) => Promise<LoginApiResponse | null>;
  messages?: ErrorMessages;
  fieldNames: FieldNames;
  setShowLoginForm: Dispatch<SetStateAction<boolean>>;
  setShowOTPForm?: Dispatch<SetStateAction<boolean>>;
  setOTPSuccessCallback?: Dispatch<SetStateAction<() => void>>;
  setOTPEmail?: Dispatch<SetStateAction<string>>;
  setOTPLength?: Dispatch<SetStateAction<number>>;
  customValidationOptions?: CustomValidationOptions | null;
  isTFAEnabled?: boolean;
  otpCodeLength?: number;
};

type CustomValidationOptions = {
  setMobileValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  setEmailValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  captchaClearField: () => void;
};
