import { addIfEmpty, isEmailValueValid } from "../utils/validationUtils";
import { StartRecoveryFormData, StartRecoveryFormValidData } from "./StartRecoveryFormData";
import { StorageItem } from "auth-ui-client-api";
import { CaptchaWithValue } from "../../context/captcha/types";

export type StartRecoveryClientValidationErrorReason =
  | { code: "UNDEFINED_ENTER_METHOD" }
  | { code: "WRONG_EMAIL_FORMAT" }
  | { code: "EMPTY_FIELDS"; fields: StartRecoveryValidationEmptyField[] };
type RecoveryValidationResult = StartRecoveryFormValidData | StartRecoveryClientValidationErrorReason[];
export const isStartRecoveryFormValid = (data: RecoveryValidationResult): data is StartRecoveryFormValidData => {
  return data && "email" in data && data.email !== undefined;
};

export function validateStartRecoveryForm(formData: StartRecoveryFormData): RecoveryValidationResult {
  const { captcha, storage, email } = formData;

  const errors: StartRecoveryClientValidationErrorReason[] = [];

  const emptyFields = checkStartRecoveryFormEmptiness(captcha, email, storage);
  if (emptyFields && emptyFields.length) {
    errors.push({ code: "EMPTY_FIELDS", fields: emptyFields });
  }

  if (!isEmailValueValid(email)) {
    errors.push({ code: "WRONG_EMAIL_FORMAT" });
  }

  // для компиляции ts
  if (errors.length || !storage || !email) {
    return errors;
  }
  return new StartRecoveryFormValidData(captcha, storage, email);
}

export type StartRecoveryValidationEmptyField = "STORAGE" | "EMAIL" | "CAPTCHA";
export const checkStartRecoveryFormEmptiness = (
  captcha: CaptchaWithValue,
  email: string | null,
  storage: StorageItem | null
) => {
  const emptyFields: StartRecoveryValidationEmptyField[] = [];
  addIfEmpty<StartRecoveryValidationEmptyField>("STORAGE", emptyFields, storage?.id);
  addIfEmpty<StartRecoveryValidationEmptyField>("EMAIL", emptyFields, email);
  if (captcha.captchaImage) {
    addIfEmpty<StartRecoveryValidationEmptyField>("CAPTCHA", emptyFields, captcha.captchaValue);
  }
  return emptyFields;
};
