import { Identifier, ValidationProperties } from "auth-ui-client-api";
import { ReactNode } from "react";

export const isEmptyField = (value?: string | null): boolean => {
  return !value?.trim() || !value.length;
};

export const isEmailValid = (identifier: Identifier): boolean => {
  if (identifier.type !== "EMAIL") {
    return true;
  }
  return isEmailValueValid(identifier.email);
};

export const isEmailValueValid = (email: string | null): boolean => {
  if (!email) {
    return true;
  }
  const emailRegexp = new RegExp(
    /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[a-zA-Zа-яА-Я0-9][a-zA-Zа-яА-Я0-9-]*(\.[a-zA-Zа-яА-Я0-9]+)*(\.[a-zA-Zа-яА-Я]{2,})$/
  );
  return !!email.match(emailRegexp);
};

export const isMobileValid = (identifier: Identifier, validationProperties: ValidationProperties): boolean => {
  if (identifier.type !== "MOBILE") {
    return true;
  }
  const mobile = identifier.mobile;
  const {
    validationMobileMinLength: minLength,
    validationMobileMaxLength: maxLength,
    validationMobileRegexp: regexpRule,
  } = validationProperties;
  const regExp = regexpRule ? new RegExp(regexpRule) : new RegExp(/.*/);
  return !!mobile && mobile.length >= minLength && mobile.length <= maxLength && !!mobile.match(regExp);
};

export function addIfEmptyIdentifier<T extends string>(
  identifier: Identifier,
  emptyFields: T[],
  fieldNames: { login: T; mobile: T; email: T; account: T }
): void {
  if (identifier.type === "LOGIN") {
    addIfEmpty<T>(fieldNames.login, emptyFields, identifier.login);
  } else if (identifier.type === "MOBILE") {
    addIfEmpty<T>(fieldNames.mobile, emptyFields, identifier.mobile);
  } else if (identifier.type === "EMAIL") {
    addIfEmpty<T>(fieldNames.email, emptyFields, identifier.email);
  } else if (identifier.type === "SELECTED_ACCOUNT") {
    addIfEmpty<T>(fieldNames.account, emptyFields, identifier.account?.login);
  }
}

export const addIfEmpty = <T extends string>(field: T, array: string[], value?: string | null): void => {
  if (isEmptyField(value)) {
    array.push(field);
  }
};

export type ValidationInfo = { validateStatus: "success" | "warning" | "error" | "validating"; help: ReactNode };
