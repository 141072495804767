import React from "react";

export function DateTimeField({ date }: Props): JSX.Element {
  return (
    <>
      {date ? (
        <>
          {new Date(date).toLocaleDateString("ru-RU")}{" "}
          {new Date(date).toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" })}
        </>
      ) : (
        <>срок действия не ограничен</>
      )}
    </>
  );
}

type Props = { date: number | null };
