import { wordForm } from "@frte/js-utils";

export const dayForm = (days: number): string => {
  return wordForm(days, "дней", "день", "дня");
};
export const createExpireWarnMessage = (errorLocalization: string, daysLeft: number): string => {
  return errorLocalization + (daysLeft === 0 ? "сегодня" : "через " + daysLeft + " " + dayForm(daysLeft));
};

export const recordWordForm = (objectsCount: number): string => {
  return wordForm(objectsCount, "приложений", "приложение", "приложения");
};

export const sessionsWordForm = (objectsCount: number): string => {
  return wordForm(objectsCount, "активных сеансов", "активный сеанс", "активных сеанса");
};
