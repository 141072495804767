export type SessionConfirmDataApiRequestTypes = {
  sessionConfirmToken: string;
};

export type SessionConfirmDataApiResponse = SessionConfirmDataApiSuccessResponse | SessionConfirmDataApiErrorResponse;

export type SessionConfirmDataApiErrorResponse = {
  errorCode: "INTERNAL_ERROR";
};

export type SessionConfirmDataApiSuccessResponse = {
  lastLogon: AccountNeverLoggedIn | AccountLoggedIn | null;
  activeSessions: ActiveSession[] | null;
  maxActiveSessionsReached: boolean;
};

export type ActiveSession = {
  id: string;
  startedAt: number;
  services: string[];
  client: Client;
  status: ActiveStatus;
  additional: AdditionalData;
};

export type AccountNeverLoggedIn = {
  accountNeverLoggedIn: true;
};

export type AccountLoggedIn = {
  accountNeverLoggedIn: false;
  data: LastLogonData;
};

export type LastLogonData = {
  startedAt: number;
  services: string[];
  client: Client;
  status: LastLogonStatus;
  additional: AdditionalData;
};

export type Client = {
  ipAddress: string;
  os?: string | null;
  browser?: string | null;
};

export type LastLogonStatus = ActiveStatus | NotActiveStatus;

export type ActiveStatus = {
  expireAt?: number | null;
};

export type NotActiveStatus = {
  closedAt: number;
};

export type AdditionalData = {
  identifierType: ByLogin | ByMobile | ByEmail | BySelectedAccount;
};

export type ByLogin = {
  login: string;
};

export type ByMobile = {
  mobile: string;
};

export type ByEmail = {
  email: string;
};

export type BySelectedAccount = {
  name: string;
  department?: string | null;
  organization?: string | null;
  position?: string | null;
};

export const isSessionConfirmDataApiError = (
  data: SessionConfirmDataApiResponse
): data is SessionConfirmDataApiErrorResponse => {
  return data && "errorCode" in data && data.errorCode !== undefined;
};
