export class RecoverFormData {
  newPassword: string;
  repeatPassword: string;
  browser: string | undefined;
  os: string | undefined;

  constructor(newPassword: string, repeatPassword: string, browser: string | undefined, os: string | undefined) {
    this.newPassword = newPassword;
    this.repeatPassword = repeatPassword;
    this.browser = browser;
    this.os = os;
  }
}

export class RecoverFormValidData {
  newPassword: string;
  browser?: string;
  os?: string;

  constructor(newPassword: string, browser?: string, os?: string) {
    this.newPassword = newPassword;
    this.browser = browser;
    this.os = os;
  }
}
