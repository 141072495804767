export type GetAuthCodeUrlApiRequest = {
  storageId: string;
  redirectUrl: string;
  os?: string;
  browser?: string;
};

export type GetAuthCodeUrlApiResponse = GetAuthCodeUrlApiSuccess | GetAuthCodeUrlApiError;

export type GetAuthCodeUrlApiSuccess = {
  authCodeUrl: string;
};

export type GetAuthCodeUrlApiError = {
  errorCode: "INTERNAL_ERROR" | "VALIDATION_ERROR";
};

export const isGetAuthCodeUrlApiError = (data: GetAuthCodeUrlApiResponse): data is GetAuthCodeUrlApiError => {
  return data && "errorCode" in data && data.errorCode !== undefined;
};
