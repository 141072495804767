import { PasswordPolicyViolations } from "../types/PasswordPolicyViolations";

export type SetPasswordApiRequest = {
  setPasswordToken: string;
  password: string;
};
export type SetPasswordApiResponse = SetPasswordApiSuccess | SetPasswordApiError;
type SetPasswordApiSuccess = {
  userId: string;
  storageId: string;
  login: string;
};
export type SetPasswordApiError = {
  errorCode:
    | "INVALID_TOKEN"
    | "TOKEN_NOT_FOUND"
    | "PASSWORD_POLICY_ERROR"
    | "IP_NOT_ALLOWED"
    | "INTERNAL_ERROR"
    | "VALIDATION_ERROR"
    | "UNAVAILABLE"
    | "ACCOUNT_NOT_VALID";
  violations?: PasswordPolicyViolations;
};
export const isSetPasswordApiError = (data: SetPasswordApiResponse): data is SetPasswordApiError =>
  !!data && "errorCode" in data && data.errorCode !== undefined;
