import { SessionConfirmTerminateApiRequestTypes, SessionConfirmTerminateApiResponse } from "auth-ui-client-api";
import post from "./common/post";
import { clientLogger } from "../logger";

export async function sessionConfirmTerminateApiRequest(
  sessionConfirmToken: string,
  sessionId: string,
  onSuccess: () => void,
  onError: () => void
): Promise<void> {
  try {
    const response = await terminateRequest(sessionConfirmToken, sessionId);
    if (response.code === "OK") {
      onSuccess();
      return;
    }
    clientLogger.error(`Произошла ошибка при завершении сессии: sessionId=${sessionId} code=${response.code}`);
  } catch (err) {
    clientLogger.error(`Произошла ошибка при завершении сессии: sessionId=${sessionId}`, err);
  }
  onError();
}

function terminateRequest(sessionConfirmToken: string, sessionId: string): Promise<SessionConfirmTerminateApiResponse> {
  const body = { sessionConfirmToken, sessionId };
  return post<SessionConfirmTerminateApiRequestTypes, SessionConfirmTerminateApiResponse>({
    url: `./api/public/session-confirm/${sessionConfirmToken}/terminate/${sessionId}`,
    body,
  });
}
