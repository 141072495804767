import { GetItemsCanceledResponse, GetItemsResponse } from "../properties";
import { get, GetResult } from "@frte/http-requests";
import { clientLogger } from "../../../logger";

export class CancellingFetcher {
  private readonly requestDelay = 300;
  private abortController: AbortController = new AbortController();

  doCanceledFetch = <T>(url: string): GetItemsCanceledResponse<T> => {
    this.abortController.abort();
    this.abortController = new AbortController();
    const options = { signal: this.abortController.signal };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.doFetch<T>(url, options)
          .then(resolve)
          .catch((err) => {
            // также можно проверять код отмены запроса по сети err.code === 20
            if (err.name === "AbortError") {
              resolve({ canceled: true });
            }
            reject(err);
          });
      }, this.requestDelay);
    });
  };

  doFetch = <T>(url: string, options?: RequestInit): GetItemsResponse<T> => {
    return get<GetResult<T> | null>(url, options).then((response: GetResult<T> | null) => {
      if (!response) {
        return { error: true };
      }
      if (!("data" in response) || response._kind !== "OK") {
        clientLogger.error(`Произошла ошибка при получении данных селекта :`, response);
        return { error: true };
      }
      return response.data;
    });
  };
}
