import React, { Dispatch, SetStateAction } from "react";
import {
  ChangePasswordApiError,
  ChangePasswordApiResponse,
  Identifier,
  isChangePasswordApiError,
  isChangePasswordApiNeedsConfirmation,
  ValidationProperties,
} from "auth-ui-client-api";
import { CaptchaChangeAction } from "../../context/captcha/captchaActions";
import { ChangePasswordFormData, ChangePasswordFormValidData } from "./ChangePasswordFormData";
import { clearCaptcha, setNewCaptcha } from "../common/dispatchCaptcha";
import {
  errorMessage,
  FeedbackMessageContent,
  Message,
  successMessage,
  warningMessage,
} from "../../components/message/Feedback";
import {
  ChangePasswordClientValidationErrorReason,
  isChangePasswordFormValid,
  validateChangePasswordForm,
} from "./validateChangePasswordForm";
import { setSessionConfirmTokenToLocalStorage } from "../../hooks/useSessionConfirmToken";
import { loginLink } from "../../components/clientLinks";
import { defaultMessages, ErrorMessages } from "../../localization/ErrorMessages";
import { MessageWithRedirect } from "../../components/message/MessageWithRedirect";
import { MessageWithAction } from "../../components/message/MessageWithAction";
import { requiredFieldsErrorMessage } from "../../components/message/requiredFieldsErrorMessage";
import { FieldNames } from "../../localization/FieldNames";
import badCredentialsMessage from "../localization/badCredentialsMessage";
import PolicyViolationsError from "../../components/message/PolicyViolationsError";
import { ValidationInfo } from "../utils/validationUtils";
import { saveSelectedValuesInCookie } from "../../utils/clientCookies";
import { checkWarnings } from "../../components/message/checkWarnings";

export async function onChangePasswordSubmit({
  dispatchCaptcha,
  rememberSelectedValues,
  formData,
  setFeedback,
  setSubmitting,
  service,
  validation,
  isAssignmentsEmpty,
  changePasswordApiRequest,
  messages = defaultMessages,
  fieldNames,
  setChangePasswordSuccess,
  customValidationOptions = null,
}: Options): Promise<void> {
  const validationResult = validateChangePasswordForm(validation, formData, isAssignmentsEmpty);
  if (!isChangePasswordFormValid(validationResult)) {
    customValidationOptions
      ? validationResult.map((reason) =>
          customClientValidationError(reason, messages, fieldNames, customValidationOptions, setFeedback)
        )
      : setFeedback(
          validationResult.map((reason) => errorMessage(clientValidationError(reason, messages, fieldNames)))
        );
    return;
  }
  setSubmitting(true);
  const response = await changePasswordApiRequest(validationResult, service);
  setSubmitting(false);

  const { identifier, storage } = validationResult;
  if (!response) {
    setFeedback([
      errorMessage(apiError({ passwordChanged: false, errorCode: "INTERNAL_ERROR" }, identifier, messages)),
    ]);
    return;
  }

  customValidationOptions && customValidationOptions.captchaClearField();

  if (isChangePasswordApiError(response)) {
    setNewCaptcha(dispatchCaptcha, response.captcha);
    if (customValidationOptions) {
      if (response.errorCode === "PASSWORD_POLICY_ERROR") {
        customValidationOptions.setPasswordNewValidation({
          validateStatus: "error",
          help: (
            <div data-testid="ValidationMessage">
              <PolicyViolationsError policyViolations={response.violations} />
            </div>
          ),
        });
      } else
        setFeedback(
          response.passwordChanged
            ? [successChangePwdMessage(messages), errorMessage(apiError(response, identifier, messages))]
            : [errorMessage(apiError(response, identifier, messages))]
        );
    } else
      setFeedback(
        response.passwordChanged
          ? [successChangePwdMessage(messages), errorMessage(apiError(response, identifier, messages))]
          : [errorMessage(apiError(response, identifier, messages))]
      );
    return;
  }
  clearCaptcha(dispatchCaptcha);
  saveSelectedValuesInCookie(rememberSelectedValues, storage, identifier);

  const redirectAction = isChangePasswordApiNeedsConfirmation(response)
    ? () => {
        setSessionConfirmTokenToLocalStorage(global.window, response.sessionConfirmToken);
        window.location.href = loginLink({ service });
      }
    : () => {
        window.location.href = loginLink({ service });
      };

  setChangePasswordSuccess(true);

  const warnings = checkWarnings(
    redirectAction,
    service,
    messages,
    response.warnAccountExpiresInDays,
    response.warnPasswordExpiresInDays
  );
  if (warnings.length) {
    setFeedback([successChangePwdMessage(messages), ...warnings.map((warn) => warningMessage(warn))]);
    return;
  }

  if (isChangePasswordApiNeedsConfirmation(response)) {
    setFeedback([successChangePwdActionMessage(redirectAction, messages)]);
    setSessionConfirmTokenToLocalStorage(global.window, response.sessionConfirmToken);
    return;
  }

  setFeedback([successChangePwdRedirectMessage(service, messages)]);
}

const apiError = (
  apiError: ChangePasswordApiError,
  identifier: Identifier,
  messages: ErrorMessages
): FeedbackMessageContent => {
  let _exhaustedCode: never;
  switch (apiError.errorCode) {
    case "PASSWORD_POLICY_ERROR":
      return () => <PolicyViolationsError policyViolations={apiError.violations} />;
    case "ACCOUNT_MULTIPLE_FOUND":
    case "WRONG_PASSWORD":
    case "ACCOUNT_NOT_FOUND":
    case "BAD_CREDENTIALS":
      return badCredentialsMessage(identifier, messages);
    case "ACCOUNT_LOCKED":
      return messages.ACCOUNT_LOCKED_MESSAGE;
    case "BAD_WORKSTATION":
      return messages.CHANGE_PASSWORD_BAD_WORKSTATION_MESSAGE;
    case "ACCOUNT_DISABLED":
      return messages.ACCOUNT_DISABLED_MESSAGE;
    case "BAD_HOURS":
      return messages.CHANGE_PASSWORD_BAD_HOURS_MESSAGE;
    case "ACCOUNT_EXPIRED":
      return messages.CHANGE_PASSWORD_ACCOUNT_EXPIRED_MESSAGE;
    case "BAD_CAPTCHA":
      return messages.BAD_CAPTCHA_VALUE_MESSAGE;
    case "SEND_EMAIL_ERROR":
      return messages.SEND_EMAIL_ERROR_MESSAGE;
    case "PASSWORD_EXPIRED":
    case "MUST_CHANGE_PASSWORD":
    case "VALIDATION_ERROR":
    case "INTERNAL_ERROR":
    case "UNAVAILABLE":
      return messages.GENERAL_ERROR_MESSAGE;
    case "ACCESS_PERIOD_NOT_STARTED":
      return messages.CHANGE_PASSWORD_ACCESS_PERIOD_NOT_STARTED_MESSAGE;
    case "ACCESS_PERIOD_ENDED":
      return messages.CHANGE_PASSWORD_ACCESS_PERIOD_ENDED_MESSAGE;
    case "NOT_HIRED":
      return messages.NOT_HIRED_MESSAGE;
    case "FIRED":
      return messages.FIRED_MESSAGE;
    case "BLOCKED_BY_WORK_ABSENCE":
      return messages.BLOCKED_BY_WORK_ABSENCE_MESSAGE;
    case "BAD_ACCESS_PERIOD":
      return messages.CHANGE_PASSWORD_BAD_ACCESS_PERIOD_MESSAGE;
    case "BAD_EMPLOYMENT_PERIOD":
      return messages.BAD_EMPLOYMENT_PERIOD_MESSAGE;
    default:
      _exhaustedCode = apiError.errorCode;
      throw new Error(`Неизвестный код ошибки: ${_exhaustedCode}`);
  }
};

const customClientValidationError = (
  reason: ChangePasswordClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames,
  customValidationOptions: CustomValidationOptions,
  setFeedback: Dispatch<SetStateAction<Message[]>>
) => {
  switch (reason.code) {
    case "UNDEFINED_ENTER_METHOD":
      setFeedback([errorMessage(messages.UNDEFINED_ENTER_METHOD)]);
      return;
    case "EMPTY_FIELDS":
      setFeedback([
        errorMessage(
          requiredFieldsErrorMessage(
            reason.fields.map(fieldNames.fieldName),
            messages.FIELDS_REQUIRED_PREFIX,
            messages.FIELD_REQUIRED_PREFIX
          )
        ),
      ]);
      return;
    case "WRONG_MOBILE_FORMAT":
      customValidationOptions.setMobileValidation({
        validateStatus: "error",
        help: (
          <div data-testid="ValidationMessage">
            {reason.description || messages.DEFAULT_MOBILE_INVALID_FORMAT_MESSAGE}
          </div>
        ),
      });
      return;
    case "WRONG_EMAIL_FORMAT":
      customValidationOptions.setEmailValidation({
        validateStatus: "error",
        help: <div data-testid="ValidationMessage">{messages.EMAIL_INVALID_FORMAT_MESSAGE}</div>,
      });
      return;
    case "MISMATCHING_PASSWORDS":
      customValidationOptions.setPasswordNewValidation({
        validateStatus: "error",
        help: "",
      });
      customValidationOptions.setPasswordNewRepeatValidation({
        validateStatus: "error",
        help: <div data-testid="ValidationMessage">{messages.MISMATCHING_PASSWORDS}</div>,
      });
      return;
    default:
      throw new Error(`Ошибка выполнения смены пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const clientValidationError = (
  reason: ChangePasswordClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames
): FeedbackMessageContent => {
  switch (reason.code) {
    case "UNDEFINED_ENTER_METHOD":
      return messages.UNDEFINED_ENTER_METHOD;
    case "EMPTY_FIELDS":
      return requiredFieldsErrorMessage(
        reason.fields.map(fieldNames.fieldName),
        messages.FIELDS_REQUIRED_PREFIX,
        messages.FIELD_REQUIRED_PREFIX
      );
    case "WRONG_MOBILE_FORMAT":
      return reason.description || messages.DEFAULT_MOBILE_INVALID_FORMAT_MESSAGE;
    case "WRONG_EMAIL_FORMAT":
      return messages.EMAIL_INVALID_FORMAT_MESSAGE;
    case "MISMATCHING_PASSWORDS":
      return messages.MISMATCHING_PASSWORDS;
    default:
      throw new Error(`Ошибка выполнения смены пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const successChangePwdMessage = (messages: ErrorMessages): Message => {
  return successMessage(() => <>{messages.PASSWORD_CHANGED_MESSAGE}</>);
};

const successChangePwdRedirectMessage = (service: string, messages: ErrorMessages): Message => {
  return successMessage(() => (
    <MessageWithRedirect url={loginLink({ service })}>{messages.PASSWORD_CHANGED_MESSAGE}</MessageWithRedirect>
  ));
};

const successChangePwdActionMessage = (RedirectAction: () => void, messages: ErrorMessages): Message => {
  return successMessage(() => (
    <MessageWithAction action={RedirectAction}>{messages.PASSWORD_CHANGED_MESSAGE}</MessageWithAction>
  ));
};

type Options = {
  rememberSelectedValues: boolean;
  formData: ChangePasswordFormData;
  messages?: ErrorMessages;
  fieldNames: FieldNames;
  setFeedback: Dispatch<SetStateAction<Message[]>>;
  setSubmitting: Dispatch<boolean>;
  dispatchCaptcha: Dispatch<CaptchaChangeAction>;
  service: string;
  validation: ValidationProperties;
  isAssignmentsEmpty: boolean;
  changePasswordApiRequest: (
    formData: ChangePasswordFormValidData,
    service: string
  ) => Promise<ChangePasswordApiResponse | null>;
  setChangePasswordSuccess: Dispatch<SetStateAction<boolean>>;
  customValidationOptions?: CustomValidationOptions | null;
};

type CustomValidationOptions = {
  setMobileValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  setEmailValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  setPasswordOldValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  setPasswordNewValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  setPasswordNewRepeatValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  captchaClearField: () => void;
};
