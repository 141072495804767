export type AssignmentsApiRequest = {
  userId: string;
};
export type AssignmentsApiResponse = AssignmentsApiSuccess | { errorCode: "INTERNAL_ERROR" };

export type AssignmentsApiSuccess = {
  _kind: "OK";
  data: { items: Assignment[] };
};

export type Assignment = {
  id: string;
  organizationName?: string | null;
  departmentName?: string | null;
  positionName?: string | null;
};

export const isAssignmentsApiSuccess = (response: AssignmentsApiResponse): response is AssignmentsApiSuccess => {
  return !!response && "data" in response && response.data !== undefined;
};
