import { DoubleRightOutlined } from "@ant-design/icons";
import React from "react";
import { Logout } from "./icons/Logout";
import { Button } from "../../components/buttons/Button";
import styles from "./ConfirmationButtonsFooter.module.scss";

export const ConfirmationButtonsFooter = ({ onConfirm, onCancel, enabledContinueBtn }: Props): JSX.Element => {
  return (
    <div className={`${styles.ConfirmationButtonsFooter} continue-buttons-footer`}>
      <hr />
      <div className="buttons-container">
        <Button
          className={`${styles.BtnBlack} cancel`}
          onClick={onCancel}
          size={"large"}
          icon={<Logout />}
          data-testid="CancelButton"
        >
          Отменить
        </Button>
        <Button
          className={`${styles.BtnSuccess} continue`}
          onClick={onConfirm}
          icon={<DoubleRightOutlined />}
          size="large"
          disabled={enabledContinueBtn}
          data-testid="ContinueButton"
        >
          Продолжить
        </Button>
      </div>
    </div>
  );
};

type Props = {
  onConfirm: () => Promise<void>;
  onCancel: () => Promise<void>;
  enabledContinueBtn: boolean;
};
