export class SetPasswordFormData {
  newPassword: string;
  repeatPassword: string;

  constructor(newPassword: string, repeatPassword: string) {
    this.newPassword = newPassword;
    this.repeatPassword = repeatPassword;
  }
}

export class SetPasswordFormValidData {
  newPassword: string;

  constructor(newPassword: string) {
    this.newPassword = newPassword;
  }
}
