import { errorMessage, FeedbackMessageContent, Message, successMessage } from "../../components/message/Feedback";
import {
  isSetPasswordFormValid,
  SetPasswordClientValidationErrorReason,
  validateSetPasswordForm,
} from "./validateSetPasswordForm";
import React, { Dispatch, SetStateAction } from "react";
import { isSetPasswordApiError, SetPasswordApiError, SetPasswordApiResponse } from "auth-ui-client-api";
import { SetPasswordFormData, SetPasswordFormValidData } from "./SetPasswordFormData";
import { defaultMessages, ErrorMessages } from "../../localization/ErrorMessages";
import { requiredFieldsErrorMessage } from "../../components/message/requiredFieldsErrorMessage";
import { FieldNames } from "../../localization/FieldNames";
import PolicyViolationsError from "../../components/message/PolicyViolationsError";
import { ValidationInfo } from "../utils/validationUtils";

export async function onSetPasswordSubmit({
  formData,
  setFeedback,
  setSubmitting,
  setPasswordApiRequest,
  setPasswordToken,
  messages = defaultMessages,
  fieldNames,
  customValidationOptions = null,
}: Options): Promise<void> {
  const validationResult = validateSetPasswordForm(formData);
  if (!isSetPasswordFormValid(validationResult)) {
    customValidationOptions
      ? validationResult.map((reason) =>
          customClientValidationError(reason, messages, fieldNames, customValidationOptions, setFeedback)
        )
      : setFeedback(
          validationResult.map((reason) => errorMessage(clientValidationError(reason, messages, fieldNames)))
        );
    return;
  }

  setSubmitting(true);
  const response = await setPasswordApiRequest(formData, setPasswordToken);
  setSubmitting(false);

  if (!response) {
    setFeedback([errorMessage(apiError({ errorCode: "INTERNAL_ERROR" }, messages))]);
    return;
  }
  if (isSetPasswordApiError(response)) {
    if (customValidationOptions) {
      if (response.errorCode === "PASSWORD_POLICY_ERROR") {
        customValidationOptions.setPasswordNewValidation({
          validateStatus: "error",
          help: (
            <div data-testid="ValidationMessage">
              <PolicyViolationsError policyViolations={response.violations} />
            </div>
          ),
        });
      } else setFeedback([errorMessage(apiError(response, messages))]);
    } else {
      setFeedback([errorMessage(apiError(response, messages))]);
    }
    return;
  }

  setFeedback([successSetPwdMessage(messages)]);
}

const apiError = (apiError: SetPasswordApiError, messages: ErrorMessages): FeedbackMessageContent => {
  let _exhaustedCode: never;
  switch (apiError.errorCode) {
    case "TOKEN_NOT_FOUND":
    case "INVALID_TOKEN":
      return messages.INVALID_SET_PASSWORD_TOKEN_MESSAGE;
    case "PASSWORD_POLICY_ERROR":
      return () => <PolicyViolationsError policyViolations={apiError.violations} />;
    case "IP_NOT_ALLOWED":
      return messages.SET_PASSWORD_FROM_IP_NOT_ALLOWED;
    case "VALIDATION_ERROR":
    case "INTERNAL_ERROR":
    case "UNAVAILABLE":
      return messages.GENERAL_ERROR_MESSAGE;
    case "ACCOUNT_NOT_VALID":
      return messages.SET_PASSWORD_FORBIDDEN;
    default:
      _exhaustedCode = apiError.errorCode;
      throw new Error(`Неизвестный код ошибки: ${_exhaustedCode}`);
  }
};

const customClientValidationError = (
  reason: SetPasswordClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames,
  customValidationOptions: CustomValidationOptions,
  setFeedback: Dispatch<SetStateAction<Message[]>>
) => {
  switch (reason.code) {
    case "EMPTY_FIELDS":
      setFeedback([
        errorMessage(
          requiredFieldsErrorMessage(
            reason.fields.map(fieldNames.fieldName),
            messages.FIELDS_REQUIRED_PREFIX,
            messages.FIELD_REQUIRED_PREFIX
          )
        ),
      ]);
      return;
    case "MISMATCHING_PASSWORDS":
      customValidationOptions.setPasswordNewValidation({
        validateStatus: "error",
        help: "",
      });
      customValidationOptions.setPasswordNewRepeatValidation({
        validateStatus: "error",
        help: <div data-testid="ValidationMessage">{messages.MISMATCHING_PASSWORDS}</div>,
      });
      return;
    default:
      throw new Error(`Ошибка выполнения установки пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const clientValidationError = (
  reason: SetPasswordClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames
): FeedbackMessageContent => {
  switch (reason.code) {
    case "EMPTY_FIELDS":
      return requiredFieldsErrorMessage(
        reason.fields.map(fieldNames.fieldName),
        messages.FIELDS_REQUIRED_PREFIX,
        messages.FIELD_REQUIRED_PREFIX
      );
    case "MISMATCHING_PASSWORDS":
      return messages.MISMATCHING_PASSWORDS;
    default:
      throw new Error(`Ошибка выполнения уста пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const successSetPwdMessage = (messages: ErrorMessages): Message => {
  return successMessage(() => <span>{messages.PASSWORD_SET_MESSAGE}</span>);
};

type Options = {
  formData: SetPasswordFormData;
  setFeedback: Dispatch<SetStateAction<Message[]>>;
  setSubmitting: Dispatch<boolean>;
  setPasswordToken: string;
  setPasswordApiRequest: (
    formData: SetPasswordFormValidData,
    setPasswordToken: string
  ) => Promise<SetPasswordApiResponse | null>;
  messages?: ErrorMessages;
  fieldNames: FieldNames;
  customValidationOptions?: CustomValidationOptions | null;
};

type CustomValidationOptions = {
  setPasswordNewValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  setPasswordNewRepeatValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
};
