import React, { ReactElement, ReactNode, useState } from "react";
import { useTimerAction } from "../../hooks/useTimerAction";

export const MessageWithAction = ({
  children,
  action,
  messageTitle = "Переход в приложение",
  linkTitle = "Перейти к приложению",
}: Props): ReactElement => {
  const redirectTimeoutSec = +(process.env.redirectTimeoutSec ?? 3);

  const [isActive, setActive] = useState(true);

  const [seconds] = useTimerAction(isActive, redirectTimeoutSec || 15, action);

  return seconds < 1 ? (
    <div>{messageTitle}...</div>
  ) : (
    <div>
      {children}
      <div className="alert-row alert-links">
        <div className="redirect-to-app">
          {isActive ? (
            <>
              <span>
                {messageTitle} через <span role="timer">{seconds}</span> сек. (
                <a className="link-action" onClick={() => setActive(false)} data-testid="CancelActionLink">
                  отменить
                </a>
                )
              </span>
            </>
          ) : null}
          <a
            onClick={(e) => {
              e.preventDefault();
              action();
            }}
            data-testid="ActionLink"
          >
            {linkTitle}
          </a>
        </div>
      </div>
    </div>
  );
};

type Props = {
  children: ReactNode;
  action: () => void;
  messageTitle?: string;
  linkTitle?: string;
};
