import React, { ReactElement } from "react";
import { StorageItem } from "auth-ui-client-api";
import { SelectNext } from "../select/SelectNext";
import { createStaticSelectAction } from "../select/actions/selectNextStaticActionCreators";
import { HighlightText } from "../label/HighlightText";

export function StorageSelectField({
  id,
  storage,
  setter,
  storages,
  placeholder,
}: Props): React.FunctionComponentElement<Props> {
  const onChange = (value?: StorageItem) => {
    setter(value ?? null);
  };

  return (
    <div id="storage-select" className="select3-container">
      <SelectNext<StorageItem>
        id={id}
        key={JSON.stringify(storage)}
        defaultValue={storage ?? undefined}
        onChange={onChange}
        getItems={createStaticSelectAction(storages, "title")}
        render={render}
        getId="id"
        placeholder={placeholder}
        parentSelector="#storage-select"
        notFoundContent={
          <div data-testid="AccountNotFoundContent">
            <span>Данные не найдены</span>
          </div>
        }
        data-testid="StorageSelect"
        dropdownAlign={{ offset: [0, -5] }}
      />
    </div>
  );
}

const render = (item: StorageItem, searchParam?: string): ReactElement => {
  return (
    <div title={item.title} data-testid={"StorageLabel"}>
      <HighlightText className="option-text" text={item.title} criteria={searchParam} />
    </div>
  );
};

type Props = {
  id: string;
  storage?: StorageItem | null;
  setter: (val: StorageItem | null) => void;
  storages: StorageItem[];
  placeholder?: string;
};
