import get from "./common/get";
import { CaptchaCheckApiResponse } from "auth-ui-client-api";

/**
 * Запрос со страницы, ошибка в виде сообщения для пользователя или компоненты для отображения в фидбэке
 * @param captchaId идентификатор капчи
 */
export function captchaApiRequest(captchaId: string): Promise<CaptchaCheckApiResponse> {
  return get<CaptchaCheckApiResponse>({ url: `./api/public/captcha?captchaId=${captchaId}` });
}
