import { Identifier } from "auth-ui-client-api";
import { FieldNames } from "./FieldNames";

export const labelForIdentifier = (identifier: Identifier, fieldNames: FieldNames): string => {
  return identifier.type === "LOGIN"
    ? fieldNames.LOGIN_LABEL
    : identifier.type === "MOBILE"
    ? fieldNames.MOBILE_LABEL
    : identifier.type === "EMAIL"
    ? fieldNames.EMAIL_LABEL
    : identifier.type === "SELECTED_ACCOUNT"
    ? fieldNames.ACCOUNT_LABEL
    : "";
};
