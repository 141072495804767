import { StartRecoveryApiRequest, StartRecoveryApiResponse } from "auth-ui-client-api";
import post from "./common/post";
import { StartRecoveryFormValidData } from "../index";
import { clientLogger } from "../logger";

export async function startRecoveryApiRequest(
  formData: StartRecoveryFormValidData,
  service: string
): Promise<StartRecoveryApiResponse | null> {
  const body = {
    email: formData.email,
    storageId: formData.storage.id,
    service,
    captcha: formData.captcha.captchaValue || null,
  };
  try {
    return await recoveryRequest(body);
  } catch (err) {
    clientLogger.error(`Произошла ошибка при смене пароля`, err);
  }
  return null;
}

function recoveryRequest(body: StartRecoveryApiRequest): Promise<StartRecoveryApiResponse> {
  return post<StartRecoveryApiRequest, StartRecoveryApiResponse>({ url: `./api/public/start-recovery`, body });
}
