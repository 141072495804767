import React from "react";
import { DateTimeField } from "../../utils/DateTimeField";

export function StartedAtField({ date }: Props): JSX.Element {
  return (
    <>
      <div className="started">
        <DateTimeField date={date} />
      </div>
    </>
  );
}

type Props = { date: number };
