import { useEffect, useState } from "react";

export function useTimerAction(isActive: boolean, timeout: number, action: () => void): [number] {
  const [seconds, setSeconds] = useState(timeout);

  useEffect(() => {
    let interval: number | undefined;
    if (isActive) {
      interval = window.setInterval(() => {
        setSeconds((seconds) => seconds - 1);
        if (seconds === 0) {
          action();
        }
      }, 1000);
    } else if (!isActive && seconds !== 0 && interval) {
      window.clearInterval(interval);
    }
    return () => window.clearInterval(interval);
  }, [isActive, seconds, action]);

  return [seconds];
}
