import { ErrorMessages } from "../../localization/ErrorMessages";
import { ComponentCreator } from "./Feedback";
import { MessageWithAction } from "./MessageWithAction";
import { createExpireWarnMessage } from "../../utils/wordForms";
import React from "react";

export const checkWarnings = (
  redirectAction: () => void,
  service: string,
  messages: ErrorMessages,
  warnAccountExpiresInDays?: number | null,
  warnPasswordExpiresInDays?: number | null
): ComponentCreator[] => {
  const warnings: ComponentCreator[] = [];
  if (isExpires(warnAccountExpiresInDays)) {
    warnings.push(() => (
      <MessageWithAction action={redirectAction}>
        {createExpireWarnMessage(messages.ACCOUNT_SOON_EXPIRED, warnAccountExpiresInDays)}
      </MessageWithAction>
    ));
  }
  if (isExpires(warnPasswordExpiresInDays)) {
    warnings.push(() => (
      <MessageWithAction action={redirectAction}>
        <div>{createExpireWarnMessage(messages.PASSWORD_SOON_EXPIRED, warnPasswordExpiresInDays)}</div>
        <div className="alert-row alert-links">
          <a href={`./change-password?service=${service}`} data-testid="ChangePasswordLink">
            Перейти к смене пароля
          </a>
        </div>
      </MessageWithAction>
    ));
  }
  return warnings;
};

const isExpires = (warnExpiresInDays: number | null | undefined): warnExpiresInDays is number => {
  return typeof warnExpiresInDays !== "undefined" && warnExpiresInDays !== null && warnExpiresInDays >= 0;
};
