import put from "../common/put";
import { TwoFactorAuthPutApiRequest, TwoFactorAuthPutApiResponse } from "auth-ui-client-api";

export async function twoFactorAuthPutApiRequest({
  twoFactorAuthId,
  twoFactorAuthCode,
}: {
  twoFactorAuthId: string;
  twoFactorAuthCode: string;
}): Promise<TwoFactorAuthPutApiResponse> {
  const body = {
    twoFactorAuthId,
    twoFactorAuthCode,
  };

  return await twoFactorAuthPutRequest(body);
}

function twoFactorAuthPutRequest(body: TwoFactorAuthPutApiRequest): Promise<TwoFactorAuthPutApiResponse> {
  return put<TwoFactorAuthPutApiRequest, TwoFactorAuthPutApiResponse>({
    url: `./api/public/two-factor-auth`,
    body,
  });
}
