import { RefObject, useEffect } from "react";
import { InputRef } from "antd";

/**
 * Хук для обработки значений, которые могут автоматически заполняться браузером.
 * Поскольку нет возможности понять, в какой момент браузер заполнит поля,
 * делается опрос значения поля до получения первого значения(это может быть значение из автозаполнения или ввод с клавиатуры)
 * @param field
 * @param setter
 */

export function useAutofillEffect(field: RefObject<InputRef>, setter: (val: string) => void): void {
  useEffect(() => {
    const interval = setInterval(() => {
      if (field.current !== null) {
        const current = field.current.input;
        if (current) {
          setter(current.value || current.defaultValue);
          clearInterval(interval);
        }
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
