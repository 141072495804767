export default function del<REQ, RES>({ url, body }: { url: string; body: REQ }): Promise<RES> {
  return fetch(url, {
    method: "DELETE",
    cache: "no-cache",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify(body),
  }).then((res) => res.json());
}

export function delWithoutBody<RES>({ url }: { url: string }): Promise<RES> {
  return fetch(url, {
    method: "DELETE",
    cache: "no-cache",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  }).then((res) => res.json());
}
