import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from "react";

export const ProcessContext = createContext<Process>([false, () => {}]);

export const ProcessProvider = ({ children }: ProcessProviderProps): JSX.Element => (
  <ProcessContext.Provider
    value={useReducer((state: boolean, action: boolean): boolean => {
      state = action;
      return state;
    }, false)}
  >
    {children}
  </ProcessContext.Provider>
);

export const useProcessContext = (): Process => {
  return useContext<Process>(ProcessContext);
};

export type Process = [boolean, Dispatch<boolean>];

type ProcessProviderProps = {
  children: ReactNode;
};
