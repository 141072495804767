import React, { Dispatch, SetStateAction, useState } from "react";
import { recordWordForm } from "auth-ui-client";

export function ServicesField({ services }: FieldProps): JSX.Element {
  return (
    <>
      <div className="services">
        {services && services.length > 0 ? (
          <span>
            <Services services={services} />
          </span>
        ) : (
          <span className="no-services">Вход в приложения не выполнялся</span>
        )}
      </div>
    </>
  );
}

const Services = ({ services }: Props): JSX.Element => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const hiddenObjectsCount = services.length - 1;
  const [firstService, ...restServices] = services;
  return (
    <>
      <div>{firstService}</div>
      {showAll &&
        restServices.map((service) => {
          return <div key={service}>{service}</div>;
        })}
      <MoreServices hiddenObjectsCount={hiddenObjectsCount} showAll={showAll} setShowAll={setShowAll} />
    </>
  );
};

const MoreServices = ({ hiddenObjectsCount, setShowAll, showAll }: MoreServicesProps): JSX.Element | null => {
  if (hiddenObjectsCount > 0) {
    return (
      <a className="hidden-objects-count" onClick={() => setShowAll(!showAll)} data-testid="MoreServicesLink">
        {!showAll ? `еще ${hiddenObjectsCount} ${recordWordForm(hiddenObjectsCount)}` : `скрыть`}
      </a>
    );
  } else return null;
};

type MoreServicesProps = {
  hiddenObjectsCount: number;
  showAll: boolean;
  setShowAll: Dispatch<SetStateAction<boolean>>;
};

type Props = { services: string[] };

type FieldProps = { services?: string[] };
