import React, { ReactNode } from "react";

export const EsiaPanel = ({
  showDelimiterTitle = true,
  children,
}: {
  showDelimiterTitle?: boolean;
  children: ReactNode;
}): JSX.Element => {
  return (
    <div className="esia-panel">
      <div className="esia-delimiter">
        <div className="line" />
        {showDelimiterTitle && <div className="esia-title">или</div>}
        <div className="line" />
      </div>
      {children}
    </div>
  );
};
