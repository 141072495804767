import { ChangePasswordApiRequest, ChangePasswordApiResponse } from "auth-ui-client-api";
import post from "./common/post";
import { ChangePasswordFormValidData } from "../submit/changePassword/ChangePasswordFormData";

export async function changePasswordApiRequest(
  formData: ChangePasswordFormValidData,
  service: string
): Promise<ChangePasswordApiResponse | null> {
  const body = {
    identifier: formData.identifier,
    oldPassword: formData.oldPassword,
    newPassword: formData.newPassword,
    storageId: formData.storage.id,
    captcha: formData.captcha.captchaValue || null,
    os: formData.os,
    browser: formData.browser,
    service: service,
  };
  try {
    return await changePasswordRequest(body);
  } catch (err) {
    console.error(`Произошла ошибка при смене пароля`, err);
  }
  return null;
}

const changePasswordRequest = (body: ChangePasswordApiRequest): Promise<ChangePasswordApiResponse> => {
  return post<ChangePasswordApiRequest, ChangePasswordApiResponse>({ url: "./api/public/change-password", body });
};
