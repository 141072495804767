export type SessionConfirmApiRequestTypes = {
  sessionConfirmToken: string;
  service: string;
};

export type SessionConfirmApiResponse = SessionConfirmApiSuccessResponse | SessionConfirmApiErrorResponse;

export type SessionConfirmApiSuccessResponse = {
  location: string;
};

export type SessionConfirmApiErrorResponse = {
  errorCode:
    | "INTERNAL_ERROR"
    | "VALIDATION_ERROR"
    | "UNAVAILABLE"
    | "SERVICE_NOT_FOUND"
    | "SERVICE_SCHEDULE_VIOLATION"
    | "BAD_WORKSTATION"
    | "SERVICE_LOCKED";
};
export const isSessionConfirmApiError = (data: SessionConfirmApiResponse): data is SessionConfirmApiErrorResponse => {
  return data && "errorCode" in data && data.errorCode !== undefined;
};

export type HideLoginFormSessionConfirmError = {
  errorCode: "SERVICE_NOT_FOUND";
};
export const isHideLoginFormSessionConfirmError = (
  data: SessionConfirmApiResponse
): data is HideLoginFormSessionConfirmError => {
  return data && "errorCode" in data && data.errorCode === "SERVICE_NOT_FOUND";
};
