import { useEffect } from "react";
import { Assignment, AssignmentsApiResponse, Identifier, isAssignmentsApiSuccess } from "auth-ui-client-api";
import get from "../../requests/common/get";
import { clientLogger } from "../../logger";

export const useAssignments = (identifier: Identifier, setAssignments: (val: Assignment[]) => void): void => {
  const currentUserId = identifier.account ? identifier.account.userId : null;

  useEffect(() => {
    (async () => {
      if (currentUserId) {
        try {
          const resp = await get<AssignmentsApiResponse>({ url: `./api/public/assignments/users/${currentUserId}` });
          if (isAssignmentsApiSuccess(resp)) {
            const newAssignments = resp.data.items;
            setAssignments(newAssignments);
          } else {
            clientLogger.error(`Ошибка при получении назначений пользователя`, resp);
          }
        } catch (er) {
          clientLogger.error(`Ошибка при получении назначений пользователя`, er);
        }
      } else {
        setAssignments([]);
      }
    })();
  }, [currentUserId, setAssignments]);
};
