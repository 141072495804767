import { Identifier } from "../types/identifier";

export type LoginApiRequest = {
  identifier: Identifier;
  password: string;
  rememberMe: boolean;
  storageId: string;
  service: string;
  captcha: string | null;
  browser?: string;
  os?: string;
};

export type LoginApiResponse = LoginApiSuccess | LoginApiNeedsConfirmation | LoginApiError;

export type LoginApiSuccess = {
  location: string;
  warnAccountExpiresInDays?: number | null;
  warnPasswordExpiresInDays?: number | null;
};

export type LoginApiNeedsConfirmation = {
  sessionConfirmToken: string;
  warnAccountExpiresInDays?: number | null;
  warnPasswordExpiresInDays?: number | null;
};

export const isLoginApiNeedsConfirmation = (data: LoginApiResponse): data is LoginApiNeedsConfirmation => {
  return data && "sessionConfirmToken" in data && data.sessionConfirmToken !== undefined;
};

export type LoginApiError = {
  captcha?: string | null;
  errorCode:
    | "BAD_CAPTCHA"
    | "INTERNAL_ERROR"
    | "ACCOUNT_NOT_FOUND"
    | "ACCOUNT_LOCKED"
    | "PASSWORD_EXPIRED"
    | "MUST_CHANGE_PASSWORD"
    | "ACCOUNT_DISABLED"
    | "BAD_CREDENTIALS"
    | "BAD_WORKSTATION"
    | "BAD_HOURS"
    | "VALIDATION_ERROR"
    | "SERVICE_NOT_FOUND"
    | "SERVICE_LOCKED"
    | "SERVICE_SCHEDULE_VIOLATION"
    | "ACCOUNT_MULTIPLE_FOUND"
    | "BLOCKED_BY_WORK_ABSENCE"
    | "UNAVAILABLE"
    | "SEND_EMAIL_ERROR"
    | "ACCESS_PERIOD_NOT_STARTED"
    | "ACCESS_PERIOD_ENDED"
    | "NOT_HIRED"
    | "FIRED";
};

export const isLoginApiError = (data: LoginApiResponse): data is LoginApiError => {
  return data && "errorCode" in data && data.errorCode !== undefined;
};

export type HideLoginFormError = {
  errorCode: "MUST_CHANGE_PASSWORD" | "PASSWORD_EXPIRED" | "SERVICE_NOT_FOUND";
};

export const isHideLoginFormError = (data: LoginApiResponse): data is HideLoginFormError => {
  return (
    data &&
    "errorCode" in data &&
    (data.errorCode === "MUST_CHANGE_PASSWORD" ||
      data.errorCode === "PASSWORD_EXPIRED" ||
      data.errorCode === "SERVICE_NOT_FOUND")
  );
};
