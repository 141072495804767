import { IdentifierTypes, IdentifierType } from "auth-ui-client-api";
import { clientLogger } from "../../logger";

export const supportedIdentifierTypes = (identifiers: string[]): IdentifierType[] => {
  const supportedIdentifierTypes: IdentifierType[] = [];
  const supportedIdentifiers: string[] = [];
  for (const type in IdentifierTypes) {
    supportedIdentifierTypes.push(type as IdentifierType);
    supportedIdentifiers.push(type);
  }

  const filteredTypes = supportedIdentifierTypes.filter((type) => {
    return identifiers.indexOf(type) > -1;
  });

  const unknownTypes = identifiers.filter((i) => {
    return supportedIdentifiers.indexOf(i) < 0;
  });

  if (unknownTypes.length) {
    clientLogger.error(`Неподдерживаемые способы входа: ${JSON.stringify(unknownTypes)}`);
  }

  return filteredTypes;
};
