import React from "react";
import { BySelectedAccount } from "auth-ui-client-api";

export const AdditionalInfoAccount = ({ value }: Props): JSX.Element => {
  return (
    <>
      <div className={"AdditionalInfoAccount"}>
        <span>{value.name}</span>
        {value.position && <span className="weak">{value.position}</span>}
        {value.organization && <span className="weak">{value.organization}</span>}
        {value.department && <span className="weak">{value.department}</span>}
      </div>
    </>
  );
};

type Props = { value: BySelectedAccount };
