export type PasswordPolicyViolations = {
  code: PasswordPolicyErrorCode;
  defaultLocalization: string;
  details?: { [key: string]: unknown };
}[];

export const PasswordPolicyErrorCodes = {
  USED_BEFORE: "USED_BEFORE",
  CONTAINS_LOGIN: "CONTAINS_LOGIN",
  FORBIDDEN_PASSWORD: "FORBIDDEN_PASSWORD",
  TOO_SHORT: "TOO_SHORT",
  SIMILAR_PASSWORDS: "SIMILAR_PASSWORDS",
  INVALID_CHARS: "INVALID_CHARS",
  EXCLUDED_CHARS: "EXCLUDED_CHARS",
  FORBIDDEN_CHARS: "FORBIDDEN_CHARS",
  NOT_ENOUGH_CHAR: "NOT_ENOUGH_CHAR",
  CHANGE_TOO_EARLY: "CHANGE_TOO_EARLY",
  NOT_ENOUGH_CHARS: "NOT_ENOUGH_CHARS",
} as const;
export type PasswordPolicyErrorCode = keyof typeof PasswordPolicyErrorCodes;
