import {
  defaultMessages,
  errorMessage,
  ErrorMessages,
  FeedbackMessageContent,
  LogoutMessage,
  Message,
  redirectFromConfirmationPage,
} from "../../src";
import {
  isHideLoginFormSessionConfirmError,
  isSessionConfirmApiError,
  SessionConfirmApiErrorResponse,
  SessionConfirmApiResponse,
} from "auth-ui-client-api";
import React, { Dispatch, SetStateAction } from "react";
import { clientLogger } from "../logger";

export async function handleConfirmClick({
  sessionConfirmToken,
  setSubmitting,
  onError,
  sessionConfirmApiRequest,
  service,
  setShowLoginForm,
  messages = defaultMessages,
}: Options): Promise<void> {
  const sessionConfirmWithSubmitTimeout = (): Promise<SessionConfirmApiResponse | null> => {
    return new Promise((resolve) => {
      const submitTimeout = setTimeout(() => {
        setSubmitting(true);
      }, 1000);
      // http://jira.frte.ru/browse/VISOR-5960, добавлена задержка в 1 секунду перед показом прогресса, чтобы он не мелькал в случае быстрой обработки запроса
      sessionConfirmApiRequest(sessionConfirmToken, service)
        .then(resolve)
        .finally(() => {
          clearTimeout(submitTimeout);
          setSubmitting(false);
        });
    });
  };

  const response = await sessionConfirmWithSubmitTimeout();

  if (!response) {
    onError([errorMessage(apiError("UNAVAILABLE", service, messages))]);
    return;
  }

  if (isHideLoginFormSessionConfirmError(response)) {
    setShowLoginForm(false);
  }

  if (isSessionConfirmApiError(response)) {
    onError([errorMessage(apiError(response.errorCode, service, messages))]);
    return;
  }

  clientLogger.info(`Переход к сервису ${response.location}`);
  redirectFromConfirmationPage(response.location);
}

const apiError = (
  errorCode: SessionConfirmApiErrorResponse["errorCode"],
  service: string,
  messages: ErrorMessages
): FeedbackMessageContent => {
  switch (errorCode) {
    case "SERVICE_NOT_FOUND":
      return () => (
        <LogoutMessage service={service}>
          <span>{`${messages.SERVICE_NOT_FOUND_MESSAGE} ${service}`}</span>
        </LogoutMessage>
      );
    case "SERVICE_LOCKED":
      return messages.SERVICE_LOCKED_MESSAGE;
    case "SERVICE_SCHEDULE_VIOLATION":
      return messages.SERVICE_SCHEDULE_VIOLATION_MESSAGE;
    case "VALIDATION_ERROR":
    case "INTERNAL_ERROR":
    case "UNAVAILABLE":
      return messages.GENERAL_ERROR_MESSAGE;
    default: {
      throw new Error(`Ошибка выполнения подтверждения входа на локализована: ${JSON.stringify(errorCode)}`);
    }
  }
};

type Options = {
  sessionConfirmToken: string;
  setSubmitting: (value: boolean) => void;
  onError: (errors: Message[]) => void;
  sessionConfirmApiRequest: (sessionConfirmToken: string, service: string) => Promise<SessionConfirmApiResponse | null>;
  messages?: ErrorMessages;
  service: string;
  setShowLoginForm: Dispatch<SetStateAction<boolean>>;
};
