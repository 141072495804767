import React, { ReactElement } from "react";
import { HighlightText } from "./HighlightText";

export const UserAccountLabel = ({ name, login, criteria }: Props): ReactElement => {
  const title = `${name} – ${login}`;
  return (
    <div className="UserAccountLabel" title={title} data-testid={"UserAccountLabel"}>
      <HighlightText className="user-name" text={name} criteria={criteria} />
      <HighlightText className="user-login" text={login} criteria={criteria} />
    </div>
  );
};

type Props = {
  login: string;
  name: string;
  criteria?: string;
};
