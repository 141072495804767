export const SET_CAPTCHA_IMAGE = "SET_CAPTCHA_IMAGE";
export const SET_SHOW_CAPTCHA = "SET_SHOW_CAPTCHA";
export const SET_CAPTCHA_VALUE = "SET_CAPTCHA_VALUE";

export const setCaptchaImage = (captchaImage: string | null): CaptchaChangeAction => {
  return {
    type: SET_CAPTCHA_IMAGE,
    payload: captchaImage,
  };
};

export const setShowCaptcha = (showCaptcha: boolean): CaptchaChangeAction => {
  return {
    type: SET_SHOW_CAPTCHA,
    payload: showCaptcha,
  };
};

export const setCaptchaValue = (value: string): CaptchaChangeAction => {
  return {
    type: SET_CAPTCHA_VALUE,
    payload: value,
  };
};

export type CaptchaChangeAction =
  | { type: "SET_CAPTCHA_IMAGE"; payload: string | null }
  | { type: "SET_SHOW_CAPTCHA"; payload: boolean }
  | { type: "SET_CAPTCHA_VALUE"; payload: string };
