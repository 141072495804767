import React, { ReactElement, ReactNode, useState } from "react";
import { useWindowRedirect } from "../../hooks/useWindowRedirect";

export const MessageWithRedirect = ({
  children,
  url,
  messageTitle = "Переход в приложение",
  linkTitle = "Перейти к приложению",
}: Props): ReactElement => {
  const redirectTimeoutSec = +(process.env.redirectTimeoutSec ?? 3);

  const [isActive, setActive] = useState(true);

  const [seconds] = useWindowRedirect(isActive, redirectTimeoutSec || 15, url);

  return seconds < 1 ? (
    <div>{messageTitle}...</div>
  ) : (
    <div>
      {children}
      <div className="alert-row alert-links">
        <div className="redirect-to-app">
          {isActive ? (
            <>
              <span>
                {messageTitle} через <span role="timer">{seconds}</span> сек. (
                <a className="link-action" onClick={() => setActive(false)} data-testid="CancelRedirectLink">
                  отменить
                </a>
                )
              </span>
            </>
          ) : null}
          <a href={url} data-testid="RedirectLink">
            {linkTitle}
          </a>
        </div>
      </div>
    </div>
  );
};

type Props = {
  children: ReactNode;
  url: string;
  messageTitle?: string;
  linkTitle?: string;
};
