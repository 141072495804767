import React, { ReactElement } from "react";
import { IdentifierType } from "auth-ui-client-api";
import { calcIdentifierTabName, FieldNames, testIdForIdentifier } from "auth-ui-client";

export const Tabs = ({ filteredTypes, currentType, onTabClick, fieldNames }: Props): ReactElement => {
  return filteredTypes.length ? (
    <div className="method-tabs" role="tablist">
      {filteredTypes.map((item) => (
        <a
          key={item}
          className={`method-tab tab-link ${currentType === item ? "active" : ""} ${item}`}
          onClick={() => onTabClick(item)}
          role="tab"
          data-testid={testIdForIdentifier(item, fieldNames)}
          tabIndex={0}
        >
          {calcIdentifierTabName(item, fieldNames)}
        </a>
      ))}
    </div>
  ) : (
    <></>
  );
};

type Props = {
  filteredTypes: IdentifierType[];
  currentType: IdentifierType | null;
  onTabClick: (type: IdentifierType) => void;
  fieldNames: FieldNames;
};
