import React, { ReactElement } from "react";
import { MaskedInput } from "antd-mask-input";
import { Identifier } from "auth-ui-client-api";

export function MobileInput({
  identifier,
  setIdentifier,
  placeholder,
  className,
  id,
  name,
  onChangeAction,
}: Props): ReactElement {
  const deleteAllWhitespaces = (value?: string): string => {
    if (!value) {
      return "";
    }
    return value.replaceAll(/[^+\d]/g, "");
  };

  const initValue = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === "+7 9__ ___ __ __") {
      const q = e.target;
      setTimeout(() => q.setSelectionRange(5, 5), 0);
      setIdentifier("+79");
    }
  };

  const clearInitialValue = (value: string) => {
    if (deleteAllWhitespaces(value) === "+79") {
      setIdentifier("");
    }
  };

  const normalizeAndSaveInput = (value: string) => {
    setIdentifier(deleteAllWhitespaces(value));
  };

  return (
    <MaskedInput
      id={id}
      title={placeholder}
      name={name}
      className={className}
      maxLength={20}
      placeholder={placeholder}
      mask={"+7 900 000 00 00"}
      value={identifier.mobile || ""}
      onChange={(e) => {
        normalizeAndSaveInput(e.target.value);
        onChangeAction && onChangeAction();
      }}
      onFocus={(e) => initValue(e)}
      onBlur={(e) => clearInitialValue(e.target.value)}
      data-testid="MobileInput"
    />
  );
}

type Props = {
  id?: string;
  name?: string;
  identifier: Identifier;
  setIdentifier: (value: string) => void;
  placeholder?: string;
  className?: string;
  onChangeAction?: () => void;
};
