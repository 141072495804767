import { addIfEmpty } from "../utils/validationUtils";
import { SetPasswordFormData, SetPasswordFormValidData } from "./SetPasswordFormData";

type SetPasswordValidationResult = SetPasswordFormValidData | SetPasswordClientValidationErrorReason[];
export const isSetPasswordFormValid = (data: SetPasswordValidationResult): data is SetPasswordFormValidData => {
  return data && "newPassword" in data && data.newPassword !== undefined;
};

export type SetPasswordValidationEmptyField = "NEW_PASSWORD" | "REPEAT_PASSWORD";
export type SetPasswordClientValidationErrorReason =
  | { code: "MISMATCHING_PASSWORDS" }
  | { code: "EMPTY_FIELDS"; fields: SetPasswordValidationEmptyField[] };

export function validateSetPasswordForm(formData: SetPasswordFormData): SetPasswordValidationResult {
  const { newPassword, repeatPassword } = formData;

  const errors: SetPasswordClientValidationErrorReason[] = [];

  const emptyFields = checkSetPasswordFormEmptiness(newPassword, repeatPassword);
  if (emptyFields && emptyFields.length) {
    errors.push({ code: "EMPTY_FIELDS", fields: emptyFields });
  }

  if (!areNewPasswordsEqual(newPassword, repeatPassword)) {
    errors.push({ code: "MISMATCHING_PASSWORDS" });
  }

  // для компиляции ts
  if (errors.length || !repeatPassword) {
    return errors;
  }
  return new SetPasswordFormValidData(repeatPassword);
}

const areNewPasswordsEqual = (newPassword: string, confirmNewPassword: string): boolean => {
  if (!newPassword && !confirmNewPassword) {
    return true;
  }
  return newPassword === confirmNewPassword;
};

export const checkSetPasswordFormEmptiness = (newPassword?: string | null, repeatPassword?: string | null) => {
  const emptyFields: SetPasswordValidationEmptyField[] = [];
  addIfEmpty("NEW_PASSWORD", emptyFields, newPassword);
  addIfEmpty("REPEAT_PASSWORD", emptyFields, repeatPassword);

  return emptyFields;
};
