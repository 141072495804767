import React from "react";

export function TimeDateField({ date }: Props): JSX.Element {
  return (
    <>
      {date ? (
        <>
          {new Date(date).toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" })}{" "}
          {new Date(date).toLocaleDateString("ru-RU")}
        </>
      ) : (
        <>срок не задан</>
      )}
    </>
  );
}

type Props = { date: number | null };
