import { Dispatch, SetStateAction, useEffect } from "react";
import { Assignment, Identifier } from "auth-ui-client-api";
import { clientLogger } from "../../logger";
import { getId } from "../../utils/clientCookies";

export const useAssignment = (
  assignmentIdFromQuery: string | null,
  rememberSelectedValues: boolean,
  assignments: Assignment[],
  setIdentifier: Dispatch<SetStateAction<Identifier>>
): void => {
  const setAssignment = (val: Assignment | null) => {
    setIdentifier((prevState) => {
      return {
        ...prevState,
        assignment: val,
      };
    });
  };

  useEffect(() => {
    if (!assignments || assignments.length === 0) {
      setAssignment(null);
      return;
    }
    // приоритетно обрабатываем назначение из адресной строки, если его нет - проставляем null
    if (assignmentIdFromQuery) {
      const assignmentFromQuery = assignments.find((i) => i.id === assignmentIdFromQuery);
      setAssignment(assignmentFromQuery || null);
      return;
    }

    if (assignments.length === 1) {
      setAssignment(assignments[0]);
      return;
    }

    if (!rememberSelectedValues) {
      setAssignment(null);
      return;
    }

    const assignmentIdFromCookie = getId(rememberSelectedValues, document, "selectedAssignment");
    if (!assignmentIdFromCookie) {
      setAssignment(null);
      return;
    }

    const assignmentFromCookie = assignments.find((a) => a.id === assignmentIdFromCookie);
    setAssignment(assignmentFromCookie || null);
    clientLogger.debug(
      "Проставление assignment из cookies ",
      assignmentFromCookie,
      assignments,
      assignmentIdFromCookie
    );
  }, [assignments]);
};
