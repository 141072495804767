import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Select, Tag } from "antd";
import {
  GetStringProp,
  isCanceled,
  isError,
  RenderFunction,
  SelectLabelProps,
  SelectNextProps,
  SelectSingleValue,
} from "./properties";
import { getDomainObject, getValue, toSelectSingleValue } from "./convertionUtils";
import { DefaultOptionType } from "rc-select/lib/Select";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import StatusBar from "./StatusBar";
import { clientLogger } from "../../logger";
import { classNameWithId, dropdownLoading, dropdownWithId } from "./commonStyles";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";

export function SelectNext<DOMAIN_OBJECT>(props: SelectNextProps<DOMAIN_OBJECT>): ReactElement {
  const {
    getId,
    render,
    getItems,
    defaultValue,
    value,
    visible = true,
    disabledIds = [],
    nowrap = false,
    parentSelector = "body",
    minInputForSearch = 0,
    maxInputForSearch = 256,
    className = "",
    showSearch = true,
    itemsToOptions = defaultItemsToOptions,
    ...propagationProps
  } = props;
  const { id, notFoundContent: defaultNotFoundContent } = propagationProps;

  const [internalValue, setInternalValue] = useState<SelectSingleValue<DOMAIN_OBJECT> | undefined>(
    getValue<DOMAIN_OBJECT>(defaultValue, render, getId, disabledIds)
  );

  const controlled = "value" in props;
  useEffect(() => {
    if (controlled) {
      setInternalValue(getValue<DOMAIN_OBJECT>(value, render, getId, disabledIds));
    }
    // Чтобы не оборачивать все в useMemo/callback
    // eslint-disable-next-line
  }, [value]);

  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const [items, setItems] = useState<DOMAIN_OBJECT[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const offsetRef = useRef(0);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [notFoundContent, setNotFoundContent] = useState<React.ReactNode>(
    minInputForSearch > 0 ? <></> : defaultNotFoundContent
  );

  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const onChangeHandler = (
    newValue?: SelectSingleValue<DOMAIN_OBJECT>,
    option?: DefaultOptionType | DefaultOptionType[]
  ) => {
    if (!controlled) {
      setInternalValue(newValue);
    }
    const domainObject = getDomainObject<DOMAIN_OBJECT>(newValue);
    clientLogger.debug(
      `#${id}: значения внутри`,
      newValue,
      `значение снаружи `,
      domainObject,
      `выбраны options `,
      option
    );
    if (props.onChange) {
      props.onChange(domainObject);
    }
  };

  /**
   * Подгружаем элементы при первом открытии
   * @param open
   */
  const onDropdownVisibleChange = async (open: boolean) => {
    setOpened(open);
    const closing = !open;
    const hasInputSearchMinimum = minInputForSearch > 0;

    if (closing && searchCriteria.length) {
      // см. аналогичное поведение у tree select
      setSearchCriteria("");
      return;
    }

    if (closing || hasInputSearchMinimum) {
      return;
    }
    offsetRef.current = 0;
    await loadItems(0, "", true);
  };

  const onSearch = async (currentSearch: string): Promise<void> => {
    setSearchCriteria(currentSearch.slice(0, maxInputForSearch + 1));
    setHasMore(false);
    setError(false);
    const searchValueLength = currentSearch ? currentSearch.length : 0;
    if (searchValueLength < minInputForSearch || searchValueLength > maxInputForSearch) {
      setNotFoundContent(<></>);
      setHasMore(false);
      setItems([]);
      return;
    }
    setNotFoundContent(defaultNotFoundContent);
    offsetRef.current = 0;
    await loadItems(0, currentSearch, true);
  };

  const onPopupScroll = async (e: { persist: () => void; target: any }) => {
    e.persist();
    const target = e.target;

    // При изменении масштаба браузера значение target.scrollTop может принимать дробные значения, чуть меньшие, чем при 100%
    // Напр., не 740 а 739,3 - округление до следующего целого решает эту проблему
    // +1 также для масштаба 67%
    const scrollToBottom = Math.ceil(target.scrollTop) + 1 + target.offsetHeight >= target.scrollHeight;
    /*    logger.debug(
      "target.scrollTop",
      target.scrollTop,
      Math.ceil(target.scrollTop) + target.offsetHeight,
      target.scrollHeight
    ); */
    if (!hasMore || !scrollToBottom) {
      return;
    }
    const newPageOffset = items.length;

    if (newPageOffset === offsetRef.current) {
      // при достижении конца списка метод onPopupScroll вызывается дважды,
      // поэтому мы запоминаем значение offset для которого сейчас уже выполняется запрос,
      // и не оправляем запрос повторно для этого же offset
      return;
    }
    offsetRef.current = newPageOffset;
    await loadItems(newPageOffset, searchCriteria, false);
  };

  const loadItems = async (offset: number, currentSearch: string, resetPreviousItems: boolean) => {
    setLoading(true);
    setError(false);
    try {
      const data = await getItems(offset, currentSearch);
      if (isCanceled(data)) {
        return;
      }
      if (isError(data)) {
        setError(true);
        return;
      }
      setHasMore(data.hasMore);
      resetPreviousItems ? setItems(data.items) : setItems((prevItems) => [...prevItems, ...data.items]);
      clientLogger.debug(`#${id}: получены данные`, data);
    } catch (err) {
      clientLogger.error(`#${id}: ошибка при получении данных`, err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getPopupContainer = (): HTMLElement => {
    const parent = document.querySelector<HTMLElement>(parentSelector);
    if (!parent) {
      clientLogger.error(`Не найден родительский элемент selector=${parentSelector} для select id=${id}`);
      return document.getElementsByTagName("body")[0];
    }
    return parent;
  };

  const dropdownRender = (menu: ReactElement): ReactElement => {
    return (
      <>
        {menu}
        <StatusBar
          loading={loading}
          error={error}
          minInputForSearch={minInputForSearch}
          maxInputForSearch={maxInputForSearch}
          searchValueLength={searchCriteria?.length}
        />
      </>
    );
  };

  const tagRender = (props: CustomTagProps): React.ReactElement => {
    const { label, ...ownProps } = props;
    const selectLabel = label as ReactElement<SelectLabelProps<DOMAIN_OBJECT>>;
    return <Tag {...ownProps}>{render(selectLabel.props.domainObject)}</Tag>;
  };

  const dropdownClassName =
    `frte-select-dropdown ` +
    `${className}` +
    `${nowrap ? " nowrap" : ""}` +
    dropdownWithId(id) +
    dropdownLoading(loading);

  const resultClassName = `frte-select ${className}` + classNameWithId(id);

  return visible ? (
    <Select<SelectSingleValue<DOMAIN_OBJECT>>
      {...propagationProps}
      open={opened}
      value={internalValue}
      showSearch={showSearch}
      labelInValue
      optionLabelProp="label" // иначе при выборе элемента в логах появляется warning  `label` of `value` is not same as `label` in Select options.
      searchValue={searchCriteria}
      loading={loading}
      filterOption={false}
      style={{ width: "100%" }}
      className={resultClassName}
      dropdownClassName={dropdownClassName}
      onChange={onChangeHandler}
      getPopupContainer={getPopupContainer}
      dropdownRender={dropdownRender}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onSearch={showSearch ? onSearch : undefined}
      onPopupScroll={onPopupScroll}
      options={itemsToOptions({ items, render, getId, searchCriteria, disabledIds })}
      tagRender={tagRender}
      notFoundContent={loading || error || minInputForSearch > 0 ? " " : notFoundContent}
      // фикс баги анта с кастомной suffixIcon, по клику на которую не открывается дропдаун селекта
      // https://github.com/ant-design/ant-design/issues/23263#issuecomment-1079985930
      suffixIcon={
        opened ? (
          <CaretUpFilled className={isActive ? "ant-select-suffix" : ""} onClick={toggleClass} />
        ) : (
          <CaretDownFilled className={isActive ? "ant-select-suffix" : ""} onClick={toggleClass} />
        )
      }
    />
  ) : (
    <></>
  );
}

const defaultItemsToOptions = <DomainObject,>({
  items,
  render,
  getId,
  searchCriteria,
  disabledIds,
}: {
  items: DomainObject[];
  render: RenderFunction<DomainObject>;
  getId: GetStringProp<DomainObject>;
  searchCriteria: string;
  disabledIds?: string[];
}): SelectSingleValue<DomainObject>[] => {
  return items.map((it: DomainObject) => {
    return toSelectSingleValue<DomainObject>(it, render, getId, searchCriteria, disabledIds);
  });
};
