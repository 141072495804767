import { LoginApiRequest, LoginApiResponse } from "auth-ui-client-api";
import post from "./common/post";
import { LoginFormValidData } from "../submit/login/LoginFormData";
import { clientLogger } from "../logger";

export async function loginApiRequest(
  formData: LoginFormValidData,
  service: string,
  isTFAEnabled = false,
): Promise<LoginApiResponse | null> {
  const body = {
    identifier: formData.identifier,
    password: formData.password,
    rememberMe: formData.rememberMe,
    storageId: formData.storage.id,
    service: service,
    captcha: formData.captcha.captchaValue || null,
    os: formData.os,
    browser: formData.browser,
    isTFAEnabled,
  };
  try {
    return await loginRequest(body);
  } catch (err) {
    clientLogger.error(`Произошла ошибка при логине`, err);
  }
  return null;
}

function loginRequest(body: LoginApiRequest): Promise<LoginApiResponse> {
  return post<LoginApiRequest, LoginApiResponse>({ url: `./api/public/login`, body });
}
