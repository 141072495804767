import React, { ReactElement, useState } from "react";
import { List } from "antd";
import { ActiveSession } from "auth-ui-client-api";
import { SessionListItem } from "./SessionListItem";
import { TerminateBtn } from "./TerminateBtn";
import { defaultMessages, errorMessage, ErrorMessages, Feedback, Message, sessionsWordForm } from "../../../src";
import { FieldNames } from "../../localization/FieldNames";

export const ActiveSessionsPanel = ({
  activeSessionsData,
  sessionConfirmToken,
  maxActiveSessionsReached,
  updateSessionConfirmData,
  isLoginIdentifierType,
  messages = defaultMessages,
  fieldNames,
  terminateIcon,
  userIcon,
  arrowUpIcon,
  arrowDownIcon,
}: Props): JSX.Element => {
  const [feedback, setFeedback] = useState<Message[]>(
    maxActiveSessionsReached ? [maxActiveSessionsReachedMessage] : []
  );
  const hasData = (data: Props["activeSessionsData"]): data is ActiveSession[] =>
    Array.isArray(data) && data.length > 0;

  return (
    <div className={`ActiveSessionsPanel${hasData(activeSessionsData) ? "" : " no-sessions"}`}>
      {!hasData(activeSessionsData) ? (
        <h3>Нет активных сеансов доступа</h3>
      ) : (
        <>
          <Feedback feedbackMessages={feedback} setFeedbackMessages={setFeedback} />
          <div className="active-sessions-header">
            <h2>
              {activeSessionsData.length} {sessionsWordForm(activeSessionsData.length)} доступа
            </h2>
            <TerminateBtn
              sessionConfirmToken={sessionConfirmToken}
              onSuccess={() => {
                setFeedback([]);
                updateSessionConfirmData();
              }}
              onError={() => {
                setFeedback([errorMessage(messages.SESSION_TERMINATE_ALL_ERROR_MESSAGE)]);
              }}
            />
          </div>
          <div>
            <List
              itemLayout="vertical"
              size="small"
              dataSource={activeSessionsData}
              renderItem={(item: ActiveSession) => (
                <List.Item>
                  <SessionListItem
                    item={item}
                    isLoginIdentifierType={isLoginIdentifierType}
                    fieldNames={fieldNames}
                    userIcon={userIcon}
                    arrowUpIcon={arrowUpIcon}
                    arrowDownIcon={arrowDownIcon}
                  >
                    <TerminateBtn
                      sessionConfirmToken={sessionConfirmToken}
                      sessionId={item.id}
                      onSuccess={() => {
                        setFeedback([]);
                        updateSessionConfirmData();
                      }}
                      onError={() => {
                        setFeedback([errorMessage(messages.SESSION_TERMINATE_ERROR_MESSAGE)]);
                      }}
                      terminateIcon={terminateIcon}
                    />
                  </SessionListItem>
                </List.Item>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

const maxActiveSessionsReachedMessage = errorMessage(() => {
  return (
    <>
      <div>
        <p>Превышено количество активных сеансов доступа.</p>
        <p>Для продолжения завершите один из активных сеансов доступа</p>
      </div>
    </>
  );
});

type Props = {
  activeSessionsData: ActiveSession[];
  sessionConfirmToken: string;
  maxActiveSessionsReached: boolean;
  updateSessionConfirmData: () => void;
  isLoginIdentifierType: boolean;
  messages?: ErrorMessages;
  fieldNames: FieldNames;
  terminateIcon?: ReactElement;
  userIcon?: ReactElement;
  arrowUpIcon?: ReactElement;
  arrowDownIcon?: ReactElement;
};
