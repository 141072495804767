export function requiredFieldsErrorMessage(
  fieldNames: string[],
  fieldsRequiredPrefix: string,
  fieldRequiredPrefix: string
): string {
  if (fieldNames.length > 1) {
    return `${fieldsRequiredPrefix} ${fieldNames.join(", ")}`;
  } else {
    return `${fieldRequiredPrefix} ${fieldNames}`;
  }
}
