import { LastLogonStatus, NotActiveStatus } from "auth-ui-client-api";
import { LastLogonStatusInfo } from "./types";

export const logonStatusInfo = (data: LastLogonStatus): LastLogonStatusInfo => {
  return {
    closed: isClosed(data),
    date: isClosed(data) ? data.closedAt : data.expireAt || null,
  };
};

const isClosed = (data: LastLogonStatus): data is NotActiveStatus => {
  return "closedAt" in data;
};
