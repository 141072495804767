import React, { Dispatch, SetStateAction } from "react";
import { defaultMessages, ErrorMessages } from "../../localization/ErrorMessages";
import { errorMessage, FeedbackMessageContent, Message } from "../message/Feedback";
import { esiaAuthUrlApiRequest } from "../../requests/oauth/esiaAuthUrlApiRequest";
import { GetAuthCodeUrlApiError, isGetAuthCodeUrlApiError } from "auth-ui-client-api";
import Image from "next/image";

export const EsiaButton = ({
  src,
  buttonTitle,
  url,
  esiaParams,
  setFeedback,
  messages = defaultMessages,
}: {
  src: string;
  buttonTitle?: string;
  url: string;
  setFeedback: Dispatch<SetStateAction<Message[]>>;
  messages?: ErrorMessages;
  esiaParams: {
    storageId: string;
    redirectUrl: string;
    popupAuth: boolean;
    clientInfo: { os?: string; browser?: string };
  };
}): JSX.Element => {
  const getUrlHandler = async () => {
    const result = await esiaAuthUrlApiRequest(url, esiaParams);
    if (isGetAuthCodeUrlApiError(result)) {
      setFeedback([errorMessage(apiError(result, messages))]);
      return;
    }
    if (esiaParams.popupAuth) {
      const width = 800;
      const height = 600;
      const top = (screen.height - height) / 2;
      const left = (screen.width - width) / 2;
      window.open(
        result.authCodeUrl,
        "Esia auth popup",
        `width=${width},height=${height},top=${top},left=${left},location=1,status=0,menubar=0,resizable=0`
      );
    } else {
      window.location.href = result.authCodeUrl;
    }
  };

  return (
    <button type="button" className="esia-btn" onClick={getUrlHandler} data-testid="EsiaButton">
      <Image src={src} className="esia-logo" alt="" />
      {buttonTitle}
    </button>
  );
};

const apiError = (apiError: GetAuthCodeUrlApiError, messages: ErrorMessages): FeedbackMessageContent => {
  switch (apiError.errorCode) {
    case "INTERNAL_ERROR":
    case "VALIDATION_ERROR":
      return messages.GENERAL_ERROR_MESSAGE;
  }
};
