import { Account } from "../../types/common";

export type AccountApiRequest = {
  identifierId: string;
};

export type AccountApiResponse = AccountApiSuccess | { errorCode: "INTERNAL_ERROR" };

export type AccountApiSuccess = {
  _kind: "OK";
  data: Account;
};

export const isAccountApiSuccess = (response: AccountApiResponse): response is AccountApiSuccess => {
  return !!response && "data" in response && response.data !== undefined;
};
