import React, { ReactElement } from "react";
import { GetStringProp, RenderFunction, SelectSingleValue } from "./properties";
import { SelectLabel } from "./SelectLabel";

const { isArray } = Array;

export function getValue<DomainObject>(
  initialItem: DomainObject | undefined,
  render: (item: DomainObject) => ReactElement,
  getId: GetStringProp<DomainObject>,
  disabledIds?: string[]
): SelectSingleValue<DomainObject> | undefined {
  if (!initialItem) {
    return undefined; // ant проверяет именно на undefined наличие value
  }
  // @ts-ignore
  return toSelectSingleValue(initialItem, render, getId, "", disabledIds);
}

export function getDomainObject<DomainObject>(
  value: SelectSingleValue<DomainObject> | undefined
): DomainObject | undefined {
  if (!value) {
    return undefined;
  }
  const result = isArray(value) ? value.map(getSingleDomainObject) : getSingleDomainObject(value);
  return result as DomainObject;
}

export const getSingleDomainObject = <DomainObject extends any>(value: SelectSingleValue<DomainObject>): DomainObject =>
  value.label.props.domainObject;

export function toSelectSingleValue<DomainObject>(
  item: DomainObject,
  render: RenderFunction<DomainObject>,
  getId: GetStringProp<DomainObject>,
  searchCriteria?: string,
  disabledIds?: string[],
  className?: string
): SelectSingleValue<DomainObject> {
  const id = getPropertyValue(item, getId);
  return {
    key: id,
    value: id,
    label: (
      <SelectLabel<DomainObject>
        domainObject={item}
        searchParam={searchCriteria}
        render={render}
        className={className}
      />
    ),
    disabled: disabledIds ? disabledIds.includes(id) : false,
  };
}

export function getPropertyValue<T>(item: T, getId: GetStringProp<T>): string {
  if (typeof getId === "function") {
    return getId(item);
  }
  const value = item[getId];
  if (value === undefined) {
    console.error(`Ошибка при получении значения поля "${getId}" у объекта`, item);
    return "";
  }
  return "" + item[getId];
}
