import React, { ReactElement } from "react";

export const Footer = ({
  techSupportContacts,
  techSupportLocalization,
  className,
  linkClassName,
}: Props): ReactElement => {
  const { techSupportUrl, techSupportEmail, techSupportPhone } = techSupportContacts;
  const { techSupportPrefix, techSupportName } = techSupportLocalization;
  const hasTechSupportInfo =
    techSupportContacts.techSupportUrl || techSupportContacts.techSupportEmail || techSupportContacts.techSupportPhone;
  return hasTechSupportInfo ? (
    <footer className={className}>
      <div className="support">
        <div className="support-text">
          <span>{techSupportPrefix} </span>
          {techSupportUrl ? (
            <span>
              <a
                target="_blank"
                rel="noreferrer"
                href={techSupportUrl}
                className={`url ${techSupportEmail || techSupportPhone ? "separated" : ""} ${linkClassName || ""}`}
                data-testid="TechSupportUrlLink"
              >
                {techSupportName}
              </a>
            </span>
          ) : (
            <span>
              {techSupportName}
              {techSupportName && (techSupportEmail || techSupportPhone) ? ": " : ""}
            </span>
          )}
          {techSupportPhone ? (
            <span>
              <a
                href={`tel:+${techSupportPhone.replace(/\D/gi, "")}`}
                className={`phone ${techSupportEmail && techSupportPhone ? "separated" : ""} ${linkClassName || ""}`}
                data-testid="TechSupportPhoneLink"
              >
                {techSupportPhone}
              </a>
            </span>
          ) : null}
          {techSupportEmail ? (
            <span>
              <a
                href={`mailto:${techSupportEmail}`}
                className={`email ${linkClassName || ""}`}
                data-testid="TechSupportEmailLink"
              >
                {techSupportEmail}
              </a>
            </span>
          ) : null}
        </div>
      </div>
    </footer>
  ) : (
    <div />
  );
};

type Props = {
  techSupportContacts: {
    techSupportUrl: string | null;
    techSupportPhone: string | null;
    techSupportEmail: string | null;
  };
  techSupportLocalization: {
    techSupportPrefix: string | null;
    techSupportName: string | null;
  };
  className?: string;
  linkClassName?: string;
};
