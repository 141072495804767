import { RecoverApiRequest, RecoverApiResponse } from "auth-ui-client-api";
import post from "./common/post";
import { RecoverFormValidData } from "../submit/recover/RecoverFormData";

export async function recoverApiRequest(
  formData: RecoverFormValidData,
  recoveryToken: string,
  service: string
): Promise<RecoverApiResponse | null> {
  const body = { password: formData.newPassword, recoveryToken, os: formData.os, browser: formData.browser, service };
  try {
    return await recoverRequest(body);
  } catch (err) {
    console.error(`Произошла ошибка при восстановлении пароля`, err);
  }
  return null;
}

function recoverRequest(body: RecoverApiRequest): Promise<RecoverApiResponse> {
  return post<RecoverApiRequest, RecoverApiResponse>({ url: `./api/public/recover`, body });
}
