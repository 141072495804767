import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { DownOutlined, UpOutlined, UserOutlined } from "@ant-design/icons";

export function ShowAdditionalInfoBtn({
  isOpenedUserInfo,
  setIsOpenedUserInfo,
  showIcon,
  userIcon,
  arrowUpIcon,
  arrowDownIcon,
}: Props): JSX.Element {
  return (
    <>
      <div className="user">
        {showIcon && (
          <>
            <a
              className="weak"
              title="Информация об учетной записи"
              onClick={() => setIsOpenedUserInfo(!isOpenedUserInfo)}
              data-testid="ShowAdditionalInfoLink"
            >
              <span className="icons">
                {userIcon || <UserOutlined />}
                {isOpenedUserInfo ? arrowUpIcon || <UpOutlined /> : arrowDownIcon || <DownOutlined />}
              </span>
            </a>
          </>
        )}
      </div>
    </>
  );
}

type Props = {
  isOpenedUserInfo: boolean;
  setIsOpenedUserInfo: Dispatch<SetStateAction<boolean>>;
  showIcon: boolean;
  userIcon?: ReactElement;
  arrowUpIcon?: ReactElement;
  arrowDownIcon?: ReactElement;
};
