import React, { ReactNode } from "react";
import { wordForm } from "@frte/js-utils";
import { Spin } from "antd";
import { ERROR_MESSAGE_CLASSNAME } from "./commonStyles";

export default function StatusBar({
  loading,
  error,
  minInputForSearch,
  maxInputForSearch,
  searchValueLength,
  parentSearchValueLength,
}: Props): JSX.Element {
  const resultStatus = status(error, searchValueLength, parentSearchValueLength, minInputForSearch, maxInputForSearch);
  return resultStatus.text || loading ? (
    <div className="status-bar">
      {loading && <Spin size="small" />}
      {!loading && (
        <div
          className={resultStatus.className || ""}
          style={{ display: resultStatus.text ? "block" : "none", minHeight: "1em" }}
        >
          {resultStatus.text}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

function status(
  error: boolean,
  searchValueLength: number,
  parentSearchValueLength: number | undefined,
  minInputForSearch: number | undefined,
  maxInputForSearch: number | undefined
): StatusNode {
  if (maxInputForSearch && searchValueLength > maxInputForSearch) {
    return {
      text: `Критерий для поиска элементов не может быть больше ${maxInputForSearch} символов`,
      className: null,
    };
  }

  if (maxInputForSearch && parentSearchValueLength && parentSearchValueLength > maxInputForSearch) {
    return {
      text: `Критерий для поиска элементов по родительскому элементу не может быть больше ${maxInputForSearch} символов`,
      className: null,
    };
  }

  const moreSymbolsForSearch = minInputForSearch ? minInputForSearch - searchValueLength : 0;
  if (moreSymbolsForSearch > 0) {
    return {
      text: `Пожалуйста, введите еще ${moreSymbolsForSearch} ${wordFormForSymbols(moreSymbolsForSearch)}`,
      className: null,
    };
  }
  if (error) {
    return { text: "При получении данных произошла ошибка", className: ERROR_MESSAGE_CLASSNAME };
  }
  return { text: null, className: null };
}

function wordFormForSymbols(num: number) {
  return wordForm(num, "символов", "символ", "символа");
}

type StatusNode = {
  text: ReactNode | null;
  className: string | null;
};

type Props = {
  loading: boolean;
  searchValueLength: number;
  error: boolean;
  minInputForSearch?: number;
  maxInputForSearch?: number;
  parentSearchValueLength?: number;
};
