import { ByEmail, ByLogin, ByMobile, BySelectedAccount } from "auth-ui-client-api";
import { AdditionalInfo, AdditionalInfoValue } from "./types";
import { clientLogger } from "../../../logger";

// при необходимости переиспользовать и вынести в общее место
export type IdentifierTypeLocalization = { login: string; email: string; mobile: string; account: string };

export const additionalInfo = (data: AdditionalInfoValue, localization: IdentifierTypeLocalization): AdditionalInfo => {
  return {
    label: additionalInfoLabel(data, localization),
    value: data,
  };
};

const additionalInfoLabel = (data: AdditionalInfoValue, localization: IdentifierTypeLocalization): string => {
  if (isLogin(data)) {
    return localization.login;
  } else if (isEmail(data)) {
    return localization.email;
  } else if (isMobile(data)) {
    return localization.mobile;
  } else if (isAccount(data)) {
    return localization.account;
  }
  const _exhaustiveData: never = data;
  clientLogger.warn("не обработан метод входа при вычислении лейбла с типом", _exhaustiveData);
  return _exhaustiveData;
};

const isLogin = (data: AdditionalInfoValue): data is ByLogin => {
  return "login" in data;
};

const isEmail = (data: AdditionalInfoValue): data is ByEmail => {
  return "email" in data;
};

const isMobile = (data: AdditionalInfoValue): data is ByMobile => {
  return "mobile" in data;
};

export const isAccount = (data: AdditionalInfoValue): data is BySelectedAccount => {
  return "name" in data;
};

export const getAdditionalInfoValue = (
  additionalInfoValue: Exclude<AdditionalInfo["value"], BySelectedAccount>
): string => {
  if (isLogin(additionalInfoValue)) {
    return additionalInfoValue.login;
  } else if (isEmail(additionalInfoValue)) {
    return additionalInfoValue.email;
  } else if (isMobile(additionalInfoValue)) {
    return additionalInfoValue.mobile;
  }
  const _exhaustiveData: never = additionalInfoValue;
  clientLogger.warn("не обработан метод входа при вычислении значения", _exhaustiveData);
  return _exhaustiveData;
};
