import { useEffect } from "react";
import { SESSIONCONFIRM_KEY } from "./useSessionConfirmToken";

export function useServiceLogout(
  props: { loginPageUrl: string } | { esiaLogoutUrl: string } | { sudirLogoutUrl: string }
): void {
  useEffect(() => {
    window.localStorage.removeItem(SESSIONCONFIRM_KEY);
    if ("esiaLogoutUrl" in props) {
      global.window.location.href = props.esiaLogoutUrl;
    } else if ("sudirLogoutUrl" in props) {
      global.window.location.href = props.sudirLogoutUrl;
    } else {
      global.window.location.href = props.loginPageUrl;
    }
  }, [props]);
}
