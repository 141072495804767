import { GetAuthCodeUrlApiRequest, GetAuthCodeUrlApiResponse } from "auth-ui-client-api";
import post from "../common/post";
import { clientLogger } from "../../logger";

export async function esiaAuthUrlApiRequest(
  url: string,
  esiaParams: {
    storageId: string;
    redirectUrl: string;
    os?: string;
    browser?: string;
  }
): Promise<GetAuthCodeUrlApiResponse> {
  try {
    return await esiaAuthUrlRequest(url, esiaParams);
  } catch (err) {
    clientLogger.error(`Произошла ошибка при получении url авторизации через ЕСИА`, err);
  }
  return { errorCode: "INTERNAL_ERROR" };
}

function esiaAuthUrlRequest(url: string, body: GetAuthCodeUrlApiRequest): Promise<GetAuthCodeUrlApiResponse> {
  return post<GetAuthCodeUrlApiRequest, GetAuthCodeUrlApiResponse>({ url, body });
}
