export type SessionCancelApiRequestTypes = {
  sessionConfirmToken: string;
};

export type SessionCancelApiResponse = Response | ErrorResponse;

type Response = {
  code: "OK";
};

type ErrorResponse = {
  code: ErrorResponseCode;
  message?: string;
};

type ErrorResponseCode = "GENERAL_ERROR" | "BAD_REQUEST" | "NOT_FOUND";

export type SessionCancelApiErrorResponse = {
  code: "GENERAL_ERROR" | "BAD_REQUEST";
  message?: string;
};

export const isSessionCancelApiError = (data: SessionCancelApiResponse): data is SessionCancelApiErrorResponse => {
  return data && "code" in data && data.code !== "OK" && data.code !== "NOT_FOUND";
};
