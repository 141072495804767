export const MOSCOW_TZ_OFFSET = -180;
export const NOVOSIBIRSK_TZ_OFFSET = -420;

export const buildTimeMessage = (utcTimestamp?: number | null): string => {
  if (!utcTimestamp) {
    return "";
  }
  const date = new Date(utcTimestamp);

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());

  return `${day}.${month}.${year} ${hours}:${minutes}${getTimezoneDiffStr(utcTimestamp)}`;
};

export const getTimezoneDiffStr = (utcTimestamp: number) => {
  const date = new Date(utcTimestamp);
  const moscowDiffHours = (MOSCOW_TZ_OFFSET - date.getTimezoneOffset()) / 60;
  if (moscowDiffHours === 0) {
    return "";
  }
  if (moscowDiffHours > 0) {
    return ` (МСК+${moscowDiffHours})`;
  } else {
    return ` (МСК${moscowDiffHours})`;
  }
};

/**
 * добавляет в начало строки 0 пока общая длина строки не станет равной 2 символам
 * @param value
 */
const padZero = (value: number) => value.toString().padStart(2, "0");
