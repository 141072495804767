import { PasswordPolicyViolations, PasswordPolicyErrorCodes, PasswordPolicyErrorCode } from "auth-ui-client-api";
import React, { ReactElement } from "react";
import { buildTimeMessage, codeIsKnown, defaultMessages } from "auth-ui-client";
import { uniqueKey } from "@frte/js-utils";
import { clientLogger } from "../../logger";

export default function PolicyViolationsError({ policyViolations }: Props): ReactElement {
  return policyViolations ? (
    <div className="PolicyViolationsError">
      <h5>{getPasswordPolicyErrorHeader(policyViolations)}</h5>
      <ul>
        {policyViolations.map((violation) => (
          <li key={uniqueKey()}>{getPasswordPolicyErrorBody(violation, policyViolations.length === 1)}</li>
        ))}
      </ul>
    </div>
  ) : (
    <span>{defaultMessages.PASSWORD_POLICY_ERROR_MESSAGE}</span>
  );
}

const getPasswordPolicyErrorHeader = (violations: PasswordPolicyViolations): string => {
  return onlyChangeTooEarlyViolation(violations)
    ? defaultMessages.PASSWORD_POLICY_CHANGE_TOO_EARLY_ERROR_MESSAGE
    : defaultMessages.PASSWORD_POLICY_ERROR_MESSAGE;
};

const getPasswordPolicyErrorBody = (
  violation: {
    code: PasswordPolicyErrorCode;
    defaultLocalization: string;
    details?: { [key: string]: unknown };
  },
  singleViolation: boolean
): string => {
  let nextChange = "";
  if (violation.details && "nextPasswordChange" in violation.details) {
    nextChange = buildTimeMessage(parseToNumber(violation.details.nextPasswordChange));
  }
  return `${getLocalization(violation, singleViolation)} ${nextChange}`;
};

const parseToNumber = (value: unknown): number | null => {
  if (value === null || value === undefined) {
    return null;
  }
  if (typeof value === "string") {
    try {
      return parseInt(value.toString());
    } catch (e) {
      clientLogger.error(`Невозможно преобразовать значение к числу ${value}`);
      return null;
    }
  }
  if (Number(value)) {
    return Number(value);
  }
  clientLogger.error(`Невозможно преобразовать значение к числу ${value}`);
  return null;
};

const onlyChangeTooEarlyViolation = (violations: PasswordPolicyViolations): boolean => {
  return violations.length === 1 && violations[0].code === "CHANGE_TOO_EARLY";
};

const getLocalization = (
  violation: { code: string; defaultLocalization: string },
  singleViolation: boolean
): string => {
  if (!codeIsKnown(violation.code, PasswordPolicyErrorCodes)) {
    return violation.defaultLocalization;
  }
  if (violation.code === "CHANGE_TOO_EARLY") {
    return singleViolation
      ? "следующая смена пароля возможна после"
      : "слишком частая смена пароля, следующая смена пароля возможна после";
  }
  return violation.defaultLocalization;
};

type Props = {
  policyViolations?: PasswordPolicyViolations;
};
