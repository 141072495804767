import { PasswordPolicyViolations } from "../types/PasswordPolicyViolations";

export type RecoverApiRequest = {
  recoveryToken: string;
  password: string;
  browser?: string;
  os?: string;
  service: string;
};
export type RecoverApiResponse = RecoverApiSuccess | RecoverApiNeedsConfirmation | RecoverApiError;

export type RecoverApiSuccess = {
  userId: string;
  storageId: string;
  login: string;
  serviceUrl?: string | undefined;
};

export type RecoverApiNeedsConfirmation = {
  sessionConfirmToken: string;
};

export type RecoverApiError = {
  errorCode:
    | "VALIDATION_ERROR"
    | "IP_NOT_ALLOWED"
    | "INVALID_TOKEN"
    | "INTERNAL_ERROR"
    | "UNAVAILABLE"
    | "PASSWORD_POLICY_ERROR"
    | "ACCOUNT_NOT_VALID";
  violations?: PasswordPolicyViolations;
};
export const isRecoverApiError = (data: RecoverApiResponse): data is RecoverApiError =>
  data && "errorCode" in data && data.errorCode !== undefined;

export const isRecoverApiNeedsConfirmation = (data: RecoverApiResponse): data is RecoverApiNeedsConfirmation => {
  return data && "sessionConfirmToken" in data && data.sessionConfirmToken !== undefined;
};
