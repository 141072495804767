import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Account, Identifier, IdentifierType, isAccountApiSuccess } from "auth-ui-client-api";
import { clientLogger } from "../../logger";
import { accountApiRequest } from "../../requests/accountApiRequest";
import { getId } from "../../utils/clientCookies";

export const useAccount = (
  identifierFromQuery: Account | string | null,
  rememberSelectedValues: boolean,
  identifierTypeTab: IdentifierType | null,
  setIdentifier: Dispatch<SetStateAction<Identifier>>
): void => {
  const setAccount = useCallback(
    (val: Account | null) => {
      setIdentifier((prevState) => {
        return {
          ...prevState,
          account: val,
        };
      });
    },
    [setIdentifier]
  );

  useEffect(() => {
    if (identifierTypeTab !== "SELECTED_ACCOUNT") {
      return;
    }
    if (identifierFromQuery) {
      if (typeof identifierFromQuery !== "string") {
        clientLogger.debug("Проставление account из query ", identifierFromQuery);
        setAccount(identifierFromQuery);
      }
      return;
    }
    if (!rememberSelectedValues) {
      return;
    }
    (async () => {
      const accountIdFromCookie = getId(rememberSelectedValues, document, "selectedAccount");
      if (!accountIdFromCookie) {
        return;
      }
      try {
        const accountResult = await accountApiRequest(accountIdFromCookie);
        if (!accountResult || !isAccountApiSuccess(accountResult)) {
          return;
        }
        clientLogger.debug("Проставление account из cookies ", accountResult.data);
        setAccount(accountResult.data);
      } catch (err) {
        clientLogger.error("Ошибка при получении данных о сотруднике", err);
      }
    })();
  }, [identifierFromQuery, identifierTypeTab, rememberSelectedValues, setAccount]);
};
