import { ObjectWithStringValueOfK, Property, StringProperty } from "../../properties";

export const filterStaticItems = <T extends ObjectWithStringValueOfK<K>, K extends Property<T>>(
  allItems: T[],
  filterProp: StringProperty<T, K>,
  criteria?: string
): T[] => {
  return allItems.filter((it: T) => {
    const title = it[filterProp];
    return !criteria || title.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
  });
};

export type FilterFunction<T> = (item: T, criteria: string) => boolean;

export const filterStaticItemsByFunction = <T>(allItems: T[], filter: FilterFunction<T>, criteria?: string): T[] => {
  return allItems.filter((it: T) => (criteria ? filter(it, criteria) : it));
};
