import React, { ReactElement } from "react";
import Highlighter from "react-highlight-words";

export const HighlightText = ({ text, criteria, className }: Props): ReactElement => {
  return (
    <Highlighter
      className={className}
      highlightClassName="filter-match"
      searchWords={criteria ? [criteria] : []}
      autoEscape={true}
      textToHighlight={text}
    />
  );
};

type Props = {
  text: string;
  criteria?: string;
  className: string;
};
