import React, { ReactElement } from "react";
import { Assignment } from "auth-ui-client-api";
import { UserAssignmentLabel } from "../label/UserAssignmentLabel";
import { CaretDownFilled, CloseOutlined } from "@ant-design/icons";
import { SelectNext } from "../select/SelectNext";
import { createStaticSelectAction } from "../select/actions/selectNextStaticActionCreators";

export function AssignmentSelectField({
  id,
  setter,
  value,
  values,
  placeholder,
}: Props): React.FunctionComponentElement<Props> {
  const onChange = (value?: Assignment) => {
    setter(value ?? null);
  };

  return (
    <SelectNext<Assignment>
      id={id}
      key={JSON.stringify(value)}
      allowClear
      defaultValue={value ?? undefined}
      onChange={onChange}
      getItems={createStaticSelectAction(values, "id")}
      render={render}
      getId="id"
      showSearch={false}
      className="frte-select-auto-height"
      placeholder={placeholder}
      parentSelector="#assignment-select"
      data-testid="AssignmentSelect"
      suffixIcon={<CaretDownFilled />}
      clearIcon={<CloseOutlined />}
      dropdownAlign={{ offset: [0, -5] }} // должен соответствовать border-radius-у
    />
  );
}

const render = (item: Assignment): ReactElement => {
  return (
    <UserAssignmentLabel
      organization={item.organizationName || undefined}
      department={item.departmentName || undefined}
      position={item.positionName || undefined}
    />
  );
};

type Props = {
  id?: string;
  placeholder?: string;
  setter: (val: Assignment | null) => void;
  value?: Assignment | null;
  values: Assignment[];
};
