import { SetPasswordApiRequest, SetPasswordApiResponse } from "auth-ui-client-api";
import post from "./common/post";
import { SetPasswordFormValidData } from "../index";

export async function setPasswordApiRequest(
  formData: SetPasswordFormValidData,
  setPasswordToken: string
): Promise<SetPasswordApiResponse> {
  const body = { password: formData.newPassword, setPasswordToken };
  try {
    return await setPasswordRequest(body);
  } catch (err) {
    console.error(`Произошла ошибка при установке пароля`, err);
  }
  return { errorCode: "INTERNAL_ERROR" };
}

function setPasswordRequest(body: SetPasswordApiRequest): Promise<SetPasswordApiResponse> {
  return post<SetPasswordApiRequest, SetPasswordApiResponse>({ url: `./api/public/set-password`, body });
}
