import React, { ReactElement } from "react";
import styles from "./CloseBtn.module.scss";

export const CloseAlertButton = ({ onClick, dataTestId }: Props): ReactElement => {
  return (
    <>
      <button className={`${styles.CloseBtn} close`} onClick={onClick} data-testid={dataTestId}>
        ×
      </button>
    </>
  );
};

type Props = {
  onClick: () => void;
  dataTestId: string;
};
