import { CaptchaChangeAction } from "./captchaActions";
import { CaptchaWithValue } from "./types";

export const CaptchaReducer = (state: CaptchaWithValue, action: CaptchaChangeAction): CaptchaWithValue => {
  switch (action.type) {
    default:
      return { ...state };
    case "SET_CAPTCHA_IMAGE":
      return { ...state, captchaImage: action.payload };
    case "SET_CAPTCHA_VALUE":
      return { ...state, captchaValue: action.payload };
  }
};
