import React, { ReactElement, ReactNode } from "react";
import { logoutLink } from "../clientLinks";
import { redirectFromConfirmationPage } from "../../utils/redirectFromConfirmationPage";

export const LogoutMessage = ({ children, service, linkTitle = "Выйти" }: Props): ReactElement => {
  return (
    <div>
      {children}
      <div className="alert-row alert-links">
        <div className="redirect-to-app">
          <a
            onClick={(e) => {
              e.preventDefault();
              redirectFromConfirmationPage(logoutLink(service));
            }}
            data-testid="LogoutLink"
          >
            {linkTitle}
          </a>
        </div>
      </div>
    </div>
  );
};

type Props = {
  children?: ReactNode;
  service: string | null;
  linkTitle?: string;
};
