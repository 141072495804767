import { useEffect, useState } from "react";

export function useWindowRedirect(isActive: boolean, timeout: number, url: string): [number] {
  const [seconds, setSeconds] = useState(timeout);

  useEffect(() => {
    let interval: number | undefined;
    if (isActive) {
      interval = window.setInterval(() => {
        setSeconds((seconds) => seconds - 1);
        if (seconds === 0) {
          window.location.href = url;
        }
      }, 1000);
    } else if (!isActive && seconds !== 0 && interval) {
      window.clearInterval(interval);
    }
    return () => window.clearInterval(interval);
  }, [isActive, seconds, url]);

  return [seconds];
}
