import React, { ReactElement } from "react";
import { BankOutlined, UserOutlined } from "@ant-design/icons";

export const UserAssignmentLabel = ({ organization, department, position, showIcons = true }: Props): ReactElement => {
  const items = [position, organization, department].filter((it) => !!it);
  const title = items.join("\n\n");
  const multiLineClassName = items.length > 1 ? "multiline" : "";

  return (
    <div className={`UserAssignmentLabel ${multiLineClassName}`} title={title} data-testid={"UserAssignmentLabel"}>
      {position && (
        <div className="position">
          {showIcons && (
            <span className="icon">
              <UserOutlined />
            </span>
          )}
          <span className="name">{position}</span>
        </div>
      )}
      {(organization || department) && (
        <div className="organization">
          {showIcons && (
            <span className="icon">
              <BankOutlined />
            </span>
          )}
          <span className="name">
            {organization && <span>{organization}</span>}
            {department && <span>{department}</span>}
          </span>
        </div>
      )}
    </div>
  );
};

type Props = {
  organization?: string;
  department?: string;
  position?: string;
  showIcons?: boolean;
};
