import React, { ReactElement } from "react";

export const contactsErrorMessage = (
  errorCode: "EMAIL_NOT_CONFIRMED" | "EMAIL_NOT_DEFINED" | "MOBILE_NOT_CONFIRMED" | "MOBILE_NOT_DEFINED"
): ReactElement => {
  switch (errorCode) {
    case "EMAIL_NOT_CONFIRMED":
      return (
        <span className="contact-check-error">
          Доступ с использованием указанной учетной записи временно заблокирован в связи с неподтвержденностью{" "}
          <strong>адреса электронной почты</strong>.
          <br />
        </span>
      );
    case "EMAIL_NOT_DEFINED":
      return (
        <span className="contact-check-error">
          Доступ с использованием указанной учетной записи временно заблокирован в связи с незаполненным{" "}
          <strong>адресом электронной почты</strong>.
        </span>
      );
    case "MOBILE_NOT_CONFIRMED":
      return (
        <span className="contact-check-error">
          Доступ с использованием указанной учетной записи временно заблокирован в связи с неподтвержденностью{" "}
          <strong>номера мобильного телефона</strong>.
        </span>
      );
    case "MOBILE_NOT_DEFINED":
      return (
        <span className="contact-check-error">
          Доступ с использованием указанной учетной записи временно заблокирован в связи с незаполненным{" "}
          <strong>номером мобильного телефона</strong>.
        </span>
      );
    default: {
      throw new Error(`Ошибка проверки контактов на локализована: ${JSON.stringify(errorCode)}`);
    }
  }
};
