import React from "react";
import { getAdditionalInfoValue, isAccount } from "./utils/additionalInfo";
import { AdditionalInfo } from "./utils/types";
import { AdditionalInfoAccount } from "./AdditionalInfoAccount";

export function AdditionalInfoField({ data }: Props): JSX.Element {
  return (
    <>
      <div className="AdditionalInfoField">
        <label>{data.label}</label>
        {isAccount(data.value) ? (
          <AdditionalInfoAccount value={data.value} />
        ) : (
          <span>{getAdditionalInfoValue(data.value)}</span>
        )}
      </div>
    </>
  );
}

type Props = { data: AdditionalInfo };
