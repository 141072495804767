import { LastLogonPanel } from "./LastLogonPanel";
import { ActiveSessionsPanel } from "./ActiveSessionsPanel";
import React, { ReactElement } from "react";
import { SessionConfirmDataApiSuccessResponse } from "auth-ui-client-api";
import { FieldNames } from "../../localization/FieldNames";

export const ConfirmationPanel = ({
  sessionConfirmData,
  isLoginIdentifierType,
  sessionConfirmToken,
  updateSessionConfirmData,
  fieldNames,
  terminateIcon,
  userIcon,
  arrowUpIcon,
  arrowDownIcon,
}: Props): ReactElement => {
  return sessionConfirmData ? (
    <>
      {sessionConfirmData.lastLogon && (
        <LastLogonPanel
          lastLogonData={sessionConfirmData.lastLogon}
          isLoginIdentifierType={isLoginIdentifierType}
          fieldNames={fieldNames}
          userIcon={userIcon}
          arrowUpIcon={arrowUpIcon}
          arrowDownIcon={arrowDownIcon}
        />
      )}
      {sessionConfirmData.activeSessions && !sessionConfirmData.lastLogon?.accountNeverLoggedIn && (
        <ActiveSessionsPanel
          activeSessionsData={sessionConfirmData.activeSessions}
          sessionConfirmToken={sessionConfirmToken}
          maxActiveSessionsReached={sessionConfirmData.maxActiveSessionsReached}
          updateSessionConfirmData={updateSessionConfirmData}
          isLoginIdentifierType={isLoginIdentifierType}
          fieldNames={fieldNames}
          terminateIcon={terminateIcon}
          userIcon={userIcon}
          arrowUpIcon={arrowUpIcon}
          arrowDownIcon={arrowDownIcon}
        />
      )}
    </>
  ) : (
    <></>
  );
};
type Props = {
  sessionConfirmData: SessionConfirmDataApiSuccessResponse | undefined;
  isLoginIdentifierType: boolean;
  sessionConfirmToken: string;
  updateSessionConfirmData: () => void;
  fieldNames: FieldNames;
  terminateIcon?: ReactElement;
  userIcon?: ReactElement;
  arrowUpIcon?: ReactElement;
  arrowDownIcon?: ReactElement;
};
