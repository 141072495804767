import { useEffect } from "react";
import { IdentifierType } from "auth-ui-client-api";
import { getActiveTabCookie } from "../../utils/clientCookies";

export function useIdentifierTypeTab(
  identifierTypeFromQuery: IdentifierType | null,
  identifierTypeFromService: IdentifierType | null,
  supportedIdentifierTypes: string[],
  setTab: (tab: IdentifierType | null) => void
): void {
  useEffect(() => {
    if (identifierTypeFromQuery) {
      setTab(identifierTypeFromQuery);
      return;
    }

    const identifierTypeFromCookie = getActiveTabCookie();
    if (identifierTypeFromCookie && supportedIdentifierTypes.includes(identifierTypeFromCookie)) {
      setTab(identifierTypeFromCookie);
      return;
    }

    setTab(identifierTypeFromService || null);
  }, []);
}
