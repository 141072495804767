import { postWithoutBody } from "./common/post";
import { GeneratePasswordApiResponse, isGeneratePasswordApiError } from "auth-ui-client-api";
import { clientLogger } from "../logger";

export async function passwordGenerationApiRequest(
  storageId: string | null | undefined,
  setPassword: (password: string) => void,
  setPasswordRepeat: (password: string) => void
): Promise<void> {
  if (!storageId) {
    clientLogger.error(`Невозможно сгенерировать пароль, поскольку неизвестен storageId`);
    return;
  }
  const response = await passwordGenerationRequest(storageId);
  if (!response) {
    clientLogger.error(`Ошибка при генерации пароля`);
    return;
  }
  if (isGeneratePasswordApiError(response)) {
    clientLogger.error(`Ошибка при генерации пароля код=${response.errorCode}`);
    return;
  }
  const password = response.password;
  if (!password) {
    clientLogger.info(`Получен пустой пароль при генерации`);
    setPassword("");
    setPasswordRepeat("");
  } else {
    setPassword(password);
    setPasswordRepeat(password);
  }
}

/**
 * Запрос со страницы, ошибка в лог и пустой ответ
 * @param storageId
 */
async function passwordGenerationRequest(storageId: string): Promise<GeneratePasswordApiResponse | null> {
  return postWithoutBody<GeneratePasswordApiResponse>({
    url: `./api/public/password-generation?storageId=${storageId}`,
  }).catch((err) => {
    console.error(`Ошибка при получении сгенерированного пароля для хранилища ${storageId}: ${err}`);
    return null;
  });
}
