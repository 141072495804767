import { Identifier, StorageItem } from "auth-ui-client-api";
import { CaptchaWithValue } from "../../context/captcha/types";

export class LoginFormData {
  captcha: CaptchaWithValue;
  storage: StorageItem | null;
  identifier: Identifier;
  password: string;
  rememberMe: boolean;
  browser?: string;
  os?: string;

  constructor(
    captcha: CaptchaWithValue,
    rememberMe: boolean,
    storage: StorageItem | null,
    identifier: Identifier,
    password: string,
    browser?: string,
    os?: string
  ) {
    this.captcha = captcha;
    this.storage = storage;
    this.password = password;
    this.rememberMe = rememberMe;
    this.browser = browser;
    this.os = os;

    switch (identifier.type) {
      case "LOGIN":
        this.identifier = {
          type: identifier.type,
          login: identifier.login,
          email: null,
          mobile: null,
          account: null,
          assignment: null,
        };
        break;
      case "EMAIL":
        this.identifier = {
          type: identifier.type,
          login: null,
          email: identifier.email,
          mobile: null,
          account: null,
          assignment: null,
        };
        break;
      case "MOBILE":
        this.identifier = {
          type: identifier.type,
          login: null,
          email: null,
          mobile: identifier.mobile,
          account: null,
          assignment: null,
        };
        break;
      case "SELECTED_ACCOUNT":
        this.identifier = {
          type: identifier.type,
          login: null,
          email: null,
          mobile: null,
          account: identifier.account,
          assignment: identifier.assignment,
        };
        break;
    }
  }
}

export class LoginFormValidData {
  captcha: CaptchaWithValue;
  storage: StorageItem;
  identifier: Identifier;
  password: string;
  rememberMe: boolean;
  browser?: string;
  os?: string;

  constructor(
    captcha: CaptchaWithValue,
    storage: StorageItem,
    identifier: Identifier,
    password: string,
    rememberMe: boolean,
    browser?: string,
    os?: string
  ) {
    this.captcha = captcha;
    this.storage = storage;
    this.identifier = identifier;
    this.password = password;
    this.rememberMe = rememberMe;
    this.browser = browser;
    this.os = os;
  }
}
