import { Identifier } from "auth-ui-client-api";
import { ErrorMessages } from "../../localization/ErrorMessages";

export default function badCredentialsMessage(identifier: Identifier, messages: ErrorMessages): string {
  if (identifier.type === "LOGIN") {
    return messages.BAD_CREDENTIALS_LOGIN_MESSAGE;
  }
  if (identifier.type === "EMAIL") {
    return messages.BAD_CREDENTIALS_EMAIL_MESSAGE;
  }
  if (identifier.type === "MOBILE") {
    return messages.BAD_CREDENTIALS_MOBILE_MESSAGE;
  }
  return messages.BAD_CREDENTIALS_ACCOUNT_MESSAGE;
}
