import { Popconfirm } from "antd";
import React, { ReactElement, useState } from "react";
import { sessionConfirmTerminateApiRequest } from "../../requests/sessionConfirmTerminateApiRequest";
import { sessionConfirmTerminateAllApiRequest } from "../../requests/sessionConfirmTerminateAllApiRequest";
import { Button } from "../../components/buttons/Button";
import { Logout } from "./icons/Logout";

export const TerminateBtn = ({
  sessionConfirmToken,
  sessionId,
  onSuccess,
  onError,
  terminateIcon,
}: Props): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleSessionTerminateClick = async () => {
    if (sessionId) {
      await sessionConfirmTerminateApiRequest(sessionConfirmToken, sessionId, onSuccess, onError);
    } else {
      await sessionConfirmTerminateAllApiRequest(sessionConfirmToken, onSuccess, onError);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showPopupConfirm = () => {
    setVisible(true);
  };

  return (
    <div className="TerminateBtn">
      <Popconfirm
        title={sessionId ? "Подтвердите завершение сеанса" : "Подтвердите завершение сеансов"}
        visible={visible}
        onConfirm={handleSessionTerminateClick}
        onCancel={handleCancel}
        okText="Завершить"
        cancelText="Отменить"
        icon={""}
        className="PopupConfirm"
      >
        <Button
          title={sessionId ? "Завершение сеанса" : "Завершение сеансов"}
          className={sessionId ? "terminate-btn" : "terminate-all-btn"}
          onClick={showPopupConfirm}
          size={"middle"}
          icon={terminateIcon || <Logout />}
          data-testid={sessionId ? "TerminateButton" : "TerminateAllButton"}
        >
          {!sessionId && `Завершить все`}
        </Button>
      </Popconfirm>
    </div>
  );
};

type Props = {
  sessionConfirmToken: string;
  onSuccess: () => void;
  onError: () => void;
  sessionId?: string;
  terminateIcon?: ReactElement;
};
