import { LoginFormData, LoginFormValidData } from "./LoginFormData";
import { addIfEmpty, addIfEmptyIdentifier, isEmailValid, isMobileValid } from "../utils/validationUtils";
import { Identifier, StorageItem, ValidationProperties } from "auth-ui-client-api";
import { CaptchaWithValue } from "../../context/captcha/types";

export type LoginValidationEmptyField =
  | "STORAGE"
  | "LOGIN"
  | "EMAIL"
  | "MOBILE"
  | "ACCOUNT"
  | "PASSWORD"
  | "CAPTCHA"
  | "ASSIGNMENT";

export type LoginClientValidationErrorReason =
  | { code: "UNDEFINED_ENTER_METHOD" }
  | { code: "EMPTY_FIELDS"; fields: LoginValidationEmptyField[] }
  | { code: "WRONG_MOBILE_FORMAT"; description: string | null }
  | { code: "WRONG_EMAIL_FORMAT" };

type LoginValidationResult = LoginFormValidData | LoginClientValidationErrorReason[];
export const isLoginFormValid = (data: LoginValidationResult): data is LoginFormValidData => {
  return data && "identifier" in data && data.identifier !== undefined;
};

export function validateLoginForm(
  validation: ValidationProperties,
  formData: LoginFormData,
  assignmentsNotEmpty: boolean
): LoginValidationResult {
  const { captcha, storage, identifier, password, rememberMe, os, browser } = formData;

  if (!identifier) {
    return [{ code: "UNDEFINED_ENTER_METHOD" }];
  }
  const errors: LoginClientValidationErrorReason[] = [];

  const emptyFields = checkLoginFormEmptiness(identifier, captcha, password, storage, assignmentsNotEmpty);
  if (emptyFields && emptyFields.length) {
    errors.push({ code: "EMPTY_FIELDS", fields: emptyFields });
  }

  if (!isMobileValid(identifier, validation)) {
    errors.push({ code: "WRONG_MOBILE_FORMAT", description: validation.validationMobileRegexpExplanation });
  }

  if (!isEmailValid(identifier)) {
    errors.push({ code: "WRONG_EMAIL_FORMAT" });
  }

  if (errors.length || !storage || !password) {
    return errors;
  }
  return new LoginFormValidData(captcha, storage, identifier, password, rememberMe, os, browser);
}

export const checkLoginFormEmptiness = (
  identifier: Identifier,
  captcha: CaptchaWithValue,
  password: string | null,
  storage: StorageItem | null,
  assignmentsNotEmpty: boolean
) => {
  const emptyFields: LoginValidationEmptyField[] = [];
  addIfEmpty<LoginValidationEmptyField>("STORAGE", emptyFields, storage?.id);
  addIfEmptyIdentifier<LoginValidationEmptyField>(identifier, emptyFields, {
    login: "LOGIN",
    email: "EMAIL",
    mobile: "MOBILE",
    account: "ACCOUNT",
  });
  if (identifier.type === "SELECTED_ACCOUNT" && assignmentsNotEmpty) {
    addIfEmpty<LoginValidationEmptyField>("ASSIGNMENT", emptyFields, identifier.assignment?.id);
  }
  addIfEmpty<LoginValidationEmptyField>("PASSWORD", emptyFields, password);
  if (captcha.captchaImage) {
    addIfEmpty<LoginValidationEmptyField>("CAPTCHA", emptyFields, captcha.captchaValue);
  }
  return emptyFields;
};
