import { isRecoverFormValid, RecoverClientValidationErrorReason, validateRecoverForm } from "./validateRecoverForm";
import { RecoverFormData, RecoverFormValidData } from "./RecoverFormData";
import React, { Dispatch, SetStateAction } from "react";
import { errorMessage, FeedbackMessageContent, Message } from "../../components/message/Feedback";
import { isRecoverApiError, RecoverApiError, RecoverApiResponse } from "auth-ui-client-api";
import { defaultMessages, ErrorMessages } from "../../localization/ErrorMessages";
import { MessageWithRedirect } from "../../components/message/MessageWithRedirect";
import { loginLink, recoveryPasswordLink } from "../../components/clientLinks";
import { requiredFieldsErrorMessage } from "../../components/message/requiredFieldsErrorMessage";
import { FieldNames } from "../../localization/FieldNames";
import PolicyViolationsError from "../../components/message/PolicyViolationsError";
import { ValidationInfo } from "../utils/validationUtils";

export async function onRecoverSubmit({
  formData,
  setFeedback,
  setSubmitting,
  recoverApiRequest,
  recoveryToken,
  service,
  messages = defaultMessages,
  fieldNames,
  customValidationOptions = null,
}: Options): Promise<void> {
  const validationResult = validateRecoverForm(formData);
  if (!isRecoverFormValid(validationResult)) {
    customValidationOptions
      ? validationResult.map((reason) =>
          customClientValidationError(reason, messages, fieldNames, customValidationOptions, setFeedback)
        )
      : setFeedback(
          validationResult.map((reason) => errorMessage(clientValidationError(reason, messages, fieldNames)))
        );
    return;
  }

  setSubmitting(true);
  const response = await recoverApiRequest(formData, recoveryToken, service);
  setSubmitting(false);

  if (!response) {
    setFeedback([errorMessage(apiError({ errorCode: "INTERNAL_ERROR" }, service, messages))]);
    return;
  }

  if (isRecoverApiError(response)) {
    if (customValidationOptions) {
      if (response.errorCode === "PASSWORD_POLICY_ERROR") {
        customValidationOptions.setPasswordNewValidation({
          validateStatus: "error",
          help: "",
        });
        customValidationOptions.setPasswordNewRepeatValidation({
          validateStatus: "error",
          help: (
            <div data-testid="ValidationMessage">
              <PolicyViolationsError policyViolations={response.violations} />
            </div>
          ),
        });
      } else setFeedback([errorMessage(apiError(response, service, messages))]);
    } else setFeedback([errorMessage(apiError(response, service, messages))]);
    return;
  }

  const feedbackCode = "PASSWORD_CHANGED_SUCCESS";
  global.window.location.href = `${loginLink({ service, feedbackCode })}`;
}

const apiError = (apiError: RecoverApiError, service: string, messages: ErrorMessages): FeedbackMessageContent => {
  let _exhaustedCode: never;
  switch (apiError.errorCode) {
    case "INVALID_TOKEN":
      return () => (
        <MessageWithRedirect
          url={recoveryPasswordLink({ service })}
          messageTitle="Переход на начало восстановления пароля"
          linkTitle="Перейти к восстановлению пароля"
        >
          {messages.INVALID_TOKEN_MESSAGE}
        </MessageWithRedirect>
      );
    case "PASSWORD_POLICY_ERROR":
      return () => <PolicyViolationsError policyViolations={apiError.violations} />;
    case "IP_NOT_ALLOWED":
      return messages.RECOVERY_FROM_IP_NOT_ALLOWED;
    case "ACCOUNT_NOT_VALID":
      return messages.RECOVERY_FORBIDDEN;
    case "VALIDATION_ERROR":
    case "INTERNAL_ERROR":
    case "UNAVAILABLE":
      return messages.GENERAL_ERROR_MESSAGE;
    default:
      _exhaustedCode = apiError.errorCode;
      throw new Error(`Неизвестный код ошибки: ${_exhaustedCode}`);
  }
};

const customClientValidationError = (
  reason: RecoverClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames,
  customValidationOptions: CustomValidationOptions,
  setFeedback: Dispatch<SetStateAction<Message[]>>
) => {
  switch (reason.code) {
    case "EMPTY_FIELDS":
      setFeedback([
        errorMessage(
          requiredFieldsErrorMessage(
            reason.fields.map(fieldNames.fieldName),
            messages.FIELDS_REQUIRED_PREFIX,
            messages.FIELD_REQUIRED_PREFIX
          )
        ),
      ]);
      return;
    case "MISMATCHING_PASSWORDS":
      customValidationOptions.setPasswordNewValidation({
        validateStatus: "error",
        help: "",
      });
      customValidationOptions.setPasswordNewRepeatValidation({
        validateStatus: "error",
        help: <div data-testid="ValidationMessage">{messages.MISMATCHING_PASSWORDS}</div>,
      });
      return;
    default:
      throw new Error(`Ошибка выполнения восстановления пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const clientValidationError = (
  reason: RecoverClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames
): FeedbackMessageContent => {
  switch (reason.code) {
    case "EMPTY_FIELDS":
      return requiredFieldsErrorMessage(
        reason.fields.map(fieldNames.fieldName),
        messages.FIELDS_REQUIRED_PREFIX,
        messages.FIELD_REQUIRED_PREFIX
      );
    case "MISMATCHING_PASSWORDS":
      return messages.MISMATCHING_PASSWORDS;
    default:
      throw new Error(`Ошибка выполнения восстановления пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

type Options = {
  formData: RecoverFormData;
  setFeedback: Dispatch<SetStateAction<Message[]>>;
  setSubmitting: Dispatch<boolean>;
  recoveryToken: string;
  service: string;
  recoverApiRequest: (
    formData: RecoverFormValidData,
    recoveryToken: string,
    service: string
  ) => Promise<RecoverApiResponse | null>;
  messages?: ErrorMessages;
  fieldNames: FieldNames;
  customValidationOptions?: CustomValidationOptions | null;
};

type CustomValidationOptions = {
  setPasswordNewValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  setPasswordNewRepeatValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
};
