import { Identifier } from "../types/identifier";
import { PasswordPolicyViolations } from "../types/PasswordPolicyViolations";

export type ChangePasswordApiRequest = {
  identifier: Identifier;
  oldPassword: string;
  newPassword: string;
  storageId: string;
  captcha: string | null;
  browser?: string;
  os?: string;
  service: string;
};

export type ChangePasswordApiResponse =
  | ChangePasswordApiSuccess
  | ChangePasswordApiNeedsConfirmation
  | ChangePasswordApiError;

export type ChangePasswordApiSuccess = {
  success: true;
  warnAccountExpiresInDays?: number | null;
  warnPasswordExpiresInDays?: number | null;
};

export type ChangePasswordApiNeedsConfirmation = {
  sessionConfirmToken: string;
  warnAccountExpiresInDays?: number | null;
  warnPasswordExpiresInDays?: number | null;
};

export type ChangePasswordApiError = {
  passwordChanged: boolean;
  captcha?: string | null;
  errorCode:
    | "BAD_CAPTCHA"
    | "PASSWORD_POLICY_ERROR"
    | "WRONG_PASSWORD"
    | "VALIDATION_ERROR"
    | "ACCOUNT_NOT_FOUND"
    | "ACCOUNT_LOCKED"
    | "PASSWORD_EXPIRED"
    | "MUST_CHANGE_PASSWORD"
    | "ACCOUNT_DISABLED"
    | "ACCOUNT_EXPIRED"
    | "BAD_CREDENTIALS"
    | "BAD_WORKSTATION"
    | "INTERNAL_ERROR"
    | "ACCOUNT_MULTIPLE_FOUND"
    | "UNAVAILABLE"
    | "BAD_HOURS"
    | "SEND_EMAIL_ERROR"
    | "ACCESS_PERIOD_NOT_STARTED"
    | "ACCESS_PERIOD_ENDED"
    | "NOT_HIRED"
    | "FIRED"
    | "BLOCKED_BY_WORK_ABSENCE"
    | "BAD_ACCESS_PERIOD"
    | "BAD_EMPLOYMENT_PERIOD";
  violations?: PasswordPolicyViolations;
};
export const isChangePasswordApiError = (data: ChangePasswordApiResponse): data is ChangePasswordApiError =>
  !!data && "errorCode" in data && data.errorCode !== undefined;

export const isChangePasswordApiNeedsConfirmation = (
  data: ChangePasswordApiResponse
): data is ChangePasswordApiNeedsConfirmation => {
  return data && "sessionConfirmToken" in data && data.sessionConfirmToken !== undefined;
};
