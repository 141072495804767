import { addIfEmpty } from "../utils/validationUtils";
import { RecoverFormData, RecoverFormValidData } from "./RecoverFormData";

type RecoverValidationResult = RecoverFormValidData | RecoverClientValidationErrorReason[];
export const isRecoverFormValid = (data: RecoverValidationResult): data is RecoverFormValidData => {
  return data && "newPassword" in data && data.newPassword !== undefined;
};

export function validateRecoverForm(formData: RecoverFormData): RecoverValidationResult {
  const { newPassword, repeatPassword, os, browser } = formData;

  const errors: RecoverClientValidationErrorReason[] = [];

  const emptyFields = checkRecoverFormEmptiness(newPassword, repeatPassword);
  if (emptyFields && emptyFields.length) {
    errors.push({ code: "EMPTY_FIELDS", fields: emptyFields });
  }

  if (!areNewPasswordsEqual(newPassword, repeatPassword)) {
    errors.push({ code: "MISMATCHING_PASSWORDS" });
  }

  // для компиляции ts
  if (errors.length || !repeatPassword) {
    return errors;
  }
  return new RecoverFormValidData(repeatPassword, os, browser);
}

const areNewPasswordsEqual = (newPassword: string, repeatPassword: string): boolean => {
  if (!newPassword && !repeatPassword) {
    return true;
  }
  return newPassword === repeatPassword;
};

export type RecoverClientValidationErrorReason =
  | { code: "MISMATCHING_PASSWORDS" }
  | { code: "EMPTY_FIELDS"; fields: RecoverValidationEmptyField[] };

export type RecoverValidationEmptyField = "NEW_PASSWORD" | "REPEAT_PASSWORD";
export const checkRecoverFormEmptiness = (newPassword?: string | null, repeatPassword?: string | null) => {
  const emptyFields: RecoverValidationEmptyField[] = [];
  addIfEmpty("NEW_PASSWORD", emptyFields, newPassword);
  addIfEmpty("REPEAT_PASSWORD", emptyFields, repeatPassword);
  return emptyFields;
};
