import { useEffect, useRef } from "react";

/**
 * Хук для обработки значений, которые могут автоматически заполняться браузером.
 * Поскольку нет возможности понять, в какой момент браузер заполнит поля,
 * делается опрос значения поля до получения первого значения(это может быть значение из автозаполнения или ввод с клавиатуры)
 * @param setType
 * @param browser
 */

export function useFirefoxAutofillEffect(setType: (val: string) => void, browser?: string): void {
  const autofillPasswordTimer = useRef<number>();

  useEffect(() => {
    const customAutoComplete = browser?.includes("Firefox");
    if (customAutoComplete) {
      setType("hidden");
      autofillPasswordTimer.current = window.setTimeout(() => {
        setType("password");
      }, 0);
    }
    return () => window.clearTimeout(autofillPasswordTimer.current);
  }, [setType, browser]);
}
