export type StartRecoveryApiRequest = {
  email: string;
  storageId: string;
  service: string;
  captcha: string | null;
};

export type StartRecoveryApiResponse = RecoveryApiSuccess | StartRecoveryApiError;

type RecoveryApiSuccess = {
  maskedEmail: string;
};
export type StartRecoveryApiError = {
  captcha?: string | null;
  errorCode:
    | "BAD_CAPTCHA"
    | "VALIDATION_ERROR"
    | "USER_NOT_FOUND"
    | "ACCOUNT_NOT_FOUND"
    | "ACCOUNT_NOT_VALID"
    | "USER_HAS_NO_EMAIL"
    | "EMAIL_NOT_VALID"
    | "USER_NOT_VALID"
    | "IP_NOT_ALLOWED"
    | "BLOCKED_EMAIL"
    | "ACCOUNT_MULTIPLE_FOUND"
    | "INTERNAL_ERROR"
    | "UNAVAILABLE";
  data?: { nextAttemptDate: number } | null;
};

export const isStartRecoveryApiError = (data: StartRecoveryApiResponse): data is StartRecoveryApiError =>
  !!data && "errorCode" in data && data.errorCode !== undefined;
