import { IdentifierType } from "auth-ui-client-api";
import { FieldNames } from "./FieldNames";

export function calcIdentifierTabName(identifierType: IdentifierType, fieldNames: FieldNames): string {
  switch (identifierType) {
    case "LOGIN":
      return fieldNames.LOGIN_TAB_NAME;
    case "MOBILE":
      return fieldNames.PHONE_TAB_NAME;
    case "EMAIL":
      return fieldNames.EMAIL_TAB_NAME;
    case "SELECTED_ACCOUNT":
      return fieldNames.ACCOUNT_TAB_NAME;
    default:
      return "";
  }
  throw new Error(`Неизвестный метод входа: ${identifierType}`);
}

export const placeholderForIdentifier = (identifierType: IdentifierType, fieldNames: FieldNames): string => {
  switch (identifierType) {
    case "LOGIN":
      return fieldNames.LOGIN_PLACEHOLDER;
    case "MOBILE":
      return fieldNames.MOBILE_PLACEHOLDER;
    case "EMAIL":
      return fieldNames.EMAIL_PLACEHOLDER;
    default:
      return "";
  }
};

export const testIdForIdentifier = (identifierType: IdentifierType, fieldNames: FieldNames): string => {
  switch (identifierType) {
    case "LOGIN":
      return fieldNames.LOGIN_TAB_TEST_ID;
    case "MOBILE":
      return fieldNames.PHONE_TAB_TEST_ID;
    case "EMAIL":
      return fieldNames.EMAIL_TAB_TEST_ID;
    case "SELECTED_ACCOUNT":
      return fieldNames.ACCOUNT_TAB_TEST_ID;
    default:
      return "";
  }
};
