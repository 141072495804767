import {
  defaultMessages,
  errorMessage,
  ErrorMessages,
  FeedbackMessageContent,
  loginLink,
  Message,
  redirectFromConfirmationPage,
} from "../../src";
import { isSessionCancelApiError, SessionCancelApiErrorResponse, SessionCancelApiResponse } from "auth-ui-client-api";
import { Dispatch, SetStateAction } from "react";
import { clientLogger } from "../logger";
import { sessionCancelApiRequest } from "../requests/sessionCancelApiRequest";

export async function handleCancelClick({
  sessionConfirmToken,
  setSubmitting,
  onError,
  service,
  messages = defaultMessages,
  setShowLoginForm,
}: Options): Promise<void> {
  const sessionCancelWithSubmitTimeout = (): Promise<SessionCancelApiResponse | null> => {
    return new Promise((resolve) => {
      const submitTimeout = setTimeout(() => {
        setSubmitting(true);
      }, 1000);
      // http://jira.frte.ru/browse/VISOR-5960, добавлена задержка в 1 секунду перед показом прогресса, чтобы он не мелькал в случае быстрой обработки запроса
      sessionCancelApiRequest(sessionConfirmToken)
        .then(resolve)
        .finally(() => {
          clearTimeout(submitTimeout);
          setSubmitting(false);
        });
    });
  };

  const response = await sessionCancelWithSubmitTimeout();

  setShowLoginForm(true);

  if (!response) {
    onError([errorMessage(apiError("GENERAL_ERROR", messages))]);
    return;
  }

  if (isSessionCancelApiError(response)) {
    onError([errorMessage(apiError(response.code, messages))]);
    return;
  }

  clientLogger.info(`Переход на страницу входа`);
  redirectFromConfirmationPage(loginLink({ service }));
}

const apiError = (
  errorCode: SessionCancelApiErrorResponse["code"],
  messages: ErrorMessages
): FeedbackMessageContent => {
  switch (errorCode) {
    case "GENERAL_ERROR":
    case "BAD_REQUEST":
      return messages.GENERAL_ERROR_MESSAGE;
    default: {
      throw new Error(`Ошибка выполнения подтверждения входа на локализована: ${JSON.stringify(errorCode)}`);
    }
  }
};

type Options = {
  sessionConfirmToken: string;
  setSubmitting: (value: boolean) => void;
  onError: (errors: Message[]) => void;
  sessionCancelApiRequest: (sessionConfirmToken: string) => Promise<SessionCancelApiResponse | null>;
  messages?: ErrorMessages;
  service: string;
  setShowLoginForm: Dispatch<SetStateAction<boolean>>;
};
