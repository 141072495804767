import { SessionConfirmDataApiResponse } from "auth-ui-client-api";
import get from "./common/get";
import { clientLogger } from "../logger";

export async function sessionConfirmDataApiRequest(
  sessionConfirmToken: string
): Promise<SessionConfirmDataApiResponse | null> {
  try {
    return await confirmRequest(sessionConfirmToken);
  } catch (err) {
    clientLogger.error(`Произошла ошибка при подтверждении входа`, err);
  }
  return null;
}

function confirmRequest(sessionConfirmToken: string): Promise<SessionConfirmDataApiResponse> {
  return get<SessionConfirmDataApiResponse>({ url: `./api/public/session-confirm/${sessionConfirmToken}` });
}
