import { inputIdForIdentifier } from "./identifierFieldUtils";
import { labelForIdentifier } from "../../localization/labelForIdentifier";
import { TextInput } from "./TextInput";
import { clientLogger } from "../../logger";
import { placeholderForIdentifier } from "../../localization/calcIdentifierTabName";
import { MobileInput } from "./MobileInput";
import { AccountSelectField } from "./AccountSelectField";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { Account, Assignment, Identifier, IdentifierType, StorageItem } from "auth-ui-client-api";
import { FieldNames } from "../../localization/FieldNames";
import { AssignmentSelectField } from "./AssignmentSelectField";

export const IdentifierInput = ({
  identifierType,
  storage,
  setIdentifier,
  fieldNames,
  identifier,
  assignments,
  showLabel,
  showPlaceholder,
  identifierLabel,
}: Props): ReactElement => {
  const setAccount = (val: Account | null) => {
    setIdentifier((prevState) => {
      return {
        ...prevState,
        account: val,
      };
    });
  };

  const setAssignment = (val: Assignment | null) => {
    setIdentifier((prevState) => {
      return {
        ...prevState,
        assignment: val,
      };
    });
  };

  return (
    <>
      <div className="form-group">
        {showLabel && (
          <label htmlFor={inputIdForIdentifier(identifier)}>
            {identifierLabel || labelForIdentifier(identifier, fieldNames)}
          </label>
        )}
        {identifierType !== "SELECTED_ACCOUNT" && identifierType !== "MOBILE" && (
          <TextInput
            identifierType={identifierType}
            identifier={identifier}
            setIdentifier={(val) => {
              switch (identifierType) {
                case "LOGIN":
                  setIdentifier((prevState) => {
                    return {
                      ...prevState,
                      login: val,
                      type: identifierType,
                    };
                  });
                  return;
                case "EMAIL":
                  setIdentifier((prevState) => {
                    return {
                      ...prevState,
                      email: val,
                      type: identifierType,
                    };
                  });
                  return;
                default:
                  clientLogger.error(`Невозможно проставить текстовое значение для вкладки ${identifierType}`);
              }
            }}
            placeholder={showPlaceholder ? placeholderForIdentifier(identifierType, fieldNames) : undefined}
          />
        )}
        {identifierType === "MOBILE" && (
          <MobileInput
            id={identifierType}
            identifier={identifier}
            setIdentifier={(val) =>
              setIdentifier((prevState) => {
                return {
                  ...prevState,
                  mobile: val,
                  type: identifierType,
                };
              })
            }
            className="form-control"
            placeholder={showPlaceholder ? placeholderForIdentifier(identifierType, fieldNames) : undefined}
          />
        )}
        {identifierType === "SELECTED_ACCOUNT" && (
          <AccountSelectField
            id={identifierType}
            value={identifier.account}
            setter={setAccount}
            storageId={storage?.id || null}
            placeholder={showPlaceholder ? fieldNames.ACCOUNT_PLACEHOLDER : undefined}
          />
        )}
      </div>
      {identifierType === "SELECTED_ACCOUNT" && assignments && assignments.length > 0 && (
        <div className="form-group" id="assignment-select">
          {showLabel && <label htmlFor="assignment-select">{fieldNames.ASSIGNMENT_LABEL}</label>}
          <AssignmentSelectField
            key={identifier.assignment?.id}
            value={identifier.assignment}
            setter={setAssignment}
            values={assignments}
            placeholder={showPlaceholder ? fieldNames.ASSIGNMENT_PLACEHOLDER : undefined}
          />
        </div>
      )}
    </>
  );
};

type Props = {
  identifierType: IdentifierType;
  storage: StorageItem | null;
  assignments?: Assignment[];
  identifier: Identifier;
  setIdentifier: Dispatch<SetStateAction<Identifier>>;
  fieldNames: FieldNames;
  showLabel: boolean;
  showPlaceholder: boolean;
  identifierLabel?: string;
};
