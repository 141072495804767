import React, { ReactElement, useEffect } from "react";

export function Redirect(): ReactElement {
  useEffect(() => {
    if (window.parent.location) {
      window.opener.location.reload();
      window.close();
    }
  }, []);

  return <div />;
}
