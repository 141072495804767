import { StorageItem } from "auth-ui-client-api";
import React, { ReactElement } from "react";
import { changePasswordLink, recoveryPasswordLink } from "./clientLinks";
import { FieldNames } from "../localization/FieldNames";

export const NavigationLinks = ({
  service,
  passwordChange = true,
  passwordRecovery,
  storage,
  fieldNames,
}: {
  service: string;
  passwordChange?: boolean;
  passwordRecovery: boolean;
  storage: StorageItem | null;
  fieldNames: FieldNames;
}): ReactElement => {
  return (
    <>
      {passwordChange && (
        <a className="navigation-link" href={changePasswordLink({ service })} data-testid="ChangePasswordLink">
          {fieldNames.CHANGE_PASSWORD_LINK}
        </a>
      )}
      {passwordRecovery && (
        <a
          href={recoveryPasswordLink({ service, storageId: storage?.id })}
          className="navigation-link recovery"
          data-testid="RecoveryLink"
        >
          {fieldNames.RECOVERY_LINK}
        </a>
      )}
    </>
  );
};
