export interface ErrorMessages {
  STORAGES_NOT_FOUND: string;
  UNDEFINED_ENTER_METHOD: string;

  FIELDS_REQUIRED_PREFIX: string;
  FIELD_REQUIRED_PREFIX: string;

  MISMATCHING_PASSWORDS: string;

  SERVICE_REQUIRED_MESSAGE: string;
  CAPTCHA_IMAGE_ERROR: string;

  GENERAL_ERROR_MESSAGE: string;
  BAD_CREDENTIALS_LOGIN_MESSAGE: string;
  BAD_CREDENTIALS_ACCOUNT_MESSAGE: string;
  BAD_CREDENTIALS_EMAIL_MESSAGE: string;
  BAD_CREDENTIALS_MOBILE_MESSAGE: string;
  BAD_CAPTCHA_VALUE_MESSAGE: string;
  BLOCKED_EMAIL_MESSAGE: string;
  NEXT_ATTEMPT_MESSAGE: string;
  SERVICE_NOT_FOUND_MESSAGE: string;
  NOT_ENOUGH_RIGHTS_MESSAGE: string;
  SERVICE_SCHEDULE_VIOLATION_MESSAGE: string;
  SERVICE_LOCKED_MESSAGE: string;
  BLOCKED_BY_WORK_ABSENCE_MESSAGE: string;
  BAD_ACCESS_PERIOD_MESSAGE: string;
  CHANGE_PASSWORD_BAD_ACCESS_PERIOD_MESSAGE: string;
  BAD_EMPLOYMENT_PERIOD_MESSAGE: string;
  PASSWORD_EXPIRED_MESSAGE: string;
  MUST_CHANGE_PASSWORD_MESSAGE: string;
  ACCOUNT_DISABLED_MESSAGE: string;
  ACCOUNT_LOCKED_MESSAGE: string;
  ACCOUNT_EXPIRED_MESSAGE: string;
  CHANGE_PASSWORD_ACCOUNT_EXPIRED_MESSAGE: string;
  BAD_WORKSTATION_MESSAGE: string;
  CHANGE_PASSWORD_BAD_WORKSTATION_MESSAGE: string;
  BAD_HOURS_MESSAGE: string;
  CHANGE_PASSWORD_BAD_HOURS_MESSAGE: string;
  ASSIGNMENT_NOT_FOUND_MESSAGE: string;
  SEND_EMAIL_ERROR_MESSAGE: string;
  USER_HAS_NO_EMAIL_MESSAGE: string;
  EMAIL_NOT_VALID_MESSAGE: string;
  USER_ARCHIVED_MESSAGE: string;
  ASSIGNMENT_ARCHIVED_MESSAGE: string;
  ACCOUNT_ARCHIVED_MESSAGE: string;

  RECOVERY_FROM_IP_NOT_ALLOWED: string;
  RECOVERY_FORBIDDEN: string;

  CONFIRM_EMAIL_BAD_WORKSTATION_MESSAGE: string;
  CONFIRM_EMAIL_USER_NOT_VALID_MESSAGE: string;

  SET_PASSWORD_FORBIDDEN: string;

  SET_PASSWORD_FROM_IP_NOT_ALLOWED: string;

  EMAIL_INVALID_FORMAT_MESSAGE: string;
  DEFAULT_MOBILE_INVALID_FORMAT_MESSAGE: string;

  PASSWORD_SOON_EXPIRED: string;
  ACCOUNT_SOON_EXPIRED: string;

  PASSWORD_CHANGED_MESSAGE: string;
  PASSWORD_SET_MESSAGE: string;

  LOGOUT_SUCCESS_MESSAGE: string;
  LOGOUT_ERROR_MESSAGE: string;

  PASSWORD_POLICY_ERROR_MESSAGE: string;
  PASSWORD_POLICY_CHANGE_TOO_EARLY_ERROR_MESSAGE: string;
  INVALID_TOKEN_MESSAGE: string;

  SET_PASSWORD_TOKEN_REQUIRED_MESSAGE: string;
  RECOVER_PASSWORD_TOKEN_REQUIRED_MESSAGE: string;
  INVALID_SET_PASSWORD_TOKEN_MESSAGE: string;

  TOKEN_CREATED_MESSAGE: string;

  SESSION_CONFIRM_DATA_ERROR_MESSAGE: string;
  SESSION_TERMINATE_ERROR_MESSAGE: string;
  SESSION_TERMINATE_ALL_ERROR_MESSAGE: string;

  CODE_REQUIRED_MESSAGE: string;

  CODE_INVALID_OR_EXPIRED_MESSAGE: string;
  DEFAULT_ERROR_MESSAGE: string;

  ACCESS_PERIOD_NOT_STARTED_MESSAGE: string;
  CHANGE_PASSWORD_ACCESS_PERIOD_NOT_STARTED_MESSAGE: string;
  ACCESS_PERIOD_ENDED_MESSAGE: string;
  CHANGE_PASSWORD_ACCESS_PERIOD_ENDED_MESSAGE: string;
  NOT_HIRED_MESSAGE: string;
  FIRED_MESSAGE: string;
}

export class DefaultErrorMessages implements ErrorMessages {
  readonly STORAGES_NOT_FOUND = "Хранилища не найдены";
  readonly UNDEFINED_ENTER_METHOD = "Способ входа не определен или не известен";

  readonly FIELDS_REQUIRED_PREFIX = "Требуется заполнить поля:";
  readonly FIELD_REQUIRED_PREFIX = "Требуется заполнить поле:";

  readonly MISMATCHING_PASSWORDS = "Подтверждающее значение пароля отличается от значения пароля";

  readonly SERVICE_REQUIRED_MESSAGE = "Не указан адрес целевого сервиса";
  readonly CAPTCHA_IMAGE_ERROR =
    "Ошибка при попытке обновить картинку капчи, попробуйте повторить запрос позже или обратитесь к администратору";

  readonly GENERAL_ERROR_MESSAGE =
    "Произошла ошибка авторизации. Попробуйте ещё раз или обратитесь в службу технической поддержки.";

  readonly BAD_CREDENTIALS_LOGIN_MESSAGE = "Введены неверные Логин или Пароль";
  readonly BAD_CREDENTIALS_ACCOUNT_MESSAGE = "Введены неверные Сотрудник или Пароль";
  readonly BAD_CREDENTIALS_EMAIL_MESSAGE = "Введены неверные Почта или Пароль";
  readonly BAD_CREDENTIALS_MOBILE_MESSAGE = "Введены неверные Телефон или Пароль";
  readonly BAD_CAPTCHA_VALUE_MESSAGE = "Введенное значение не совпадает с текстом на картинке";
  readonly BLOCKED_EMAIL_MESSAGE = "Достигнут предел количества попыток восстановления пароля";
  readonly NEXT_ATTEMPT_MESSAGE = "Следующая попытка доступна с ";
  readonly SERVICE_NOT_FOUND_MESSAGE = "Не найден сервис:";
  readonly NOT_ENOUGH_RIGHTS_MESSAGE = "Недостаточно прав для доступа к модулю";
  readonly SERVICE_SCHEDULE_VIOLATION_MESSAGE = "Приложение недоступно";
  readonly SERVICE_LOCKED_MESSAGE = "Приложение недоступно";
  readonly BLOCKED_BY_WORK_ABSENCE_MESSAGE = "Доступ заблокирован на период неисполнения должностных обязанностей";
  readonly PASSWORD_EXPIRED_MESSAGE = "Срок действия пароля истёк";
  readonly MUST_CHANGE_PASSWORD_MESSAGE = "Необходимо сменить пароль";
  readonly ACCOUNT_DISABLED_MESSAGE = "Пользователь не найден";
  readonly ACCOUNT_LOCKED_MESSAGE = "Учетная запись заблокирована";
  readonly ACCOUNT_EXPIRED_MESSAGE = "Попытка входа вне назначенного периода доступа";
  readonly CHANGE_PASSWORD_ACCOUNT_EXPIRED_MESSAGE = "Попытка изменения пароля вне назначенного периода доступа";
  readonly BAD_WORKSTATION_MESSAGE = "Вход в систему с этой рабочей станции недоступен";
  readonly CHANGE_PASSWORD_BAD_WORKSTATION_MESSAGE = "Изменение пароля с этой рабочей станции недоступно";
  readonly BAD_HOURS_MESSAGE = "В данный момент вход в систему указанному пользователю недоступен";
  readonly CHANGE_PASSWORD_BAD_HOURS_MESSAGE = "В данный момент изменение пароля указанному пользователю недоступно";
  readonly ASSIGNMENT_NOT_FOUND_MESSAGE = "Кадровое назначение не найдено";
  readonly SEND_EMAIL_ERROR_MESSAGE = "Ошибка при отправке уведомления на указанный адрес электронной почты";
  readonly USER_HAS_NO_EMAIL_MESSAGE = "Адрес электронной почты не указан";
  readonly EMAIL_NOT_VALID_MESSAGE =
    "Адрес электронной почты для указанного пользователя не прошел проверку. Пожалуйста, обратитесь к администратору информационной безопасности";

  readonly USER_ARCHIVED_MESSAGE = "Пользователь переведен в архив";
  readonly ASSIGNMENT_ARCHIVED_MESSAGE = "Кадровое назначение переведено в архив";
  readonly ACCOUNT_ARCHIVED_MESSAGE = "Учетная запись переведена в архив";

  readonly RECOVERY_FROM_IP_NOT_ALLOWED =
    "Восстановление пароля для данной учетной записи с этой рабочей станции недоступно";

  readonly RECOVERY_FORBIDDEN = "Восстановление пароля для данной учетной записи недоступно";

  readonly CONFIRM_EMAIL_BAD_WORKSTATION_MESSAGE =
    "Подтверждение адреса электронной почты для данного пользователя с этой рабочей станции недоступно";

  readonly CONFIRM_EMAIL_USER_NOT_VALID_MESSAGE =
    "Подтверждение адреса электронной почты для данного пользователя недоступно";

  readonly SET_PASSWORD_FORBIDDEN = "Установка пароля для данной учетной записи недоступна";

  readonly SET_PASSWORD_FROM_IP_NOT_ALLOWED =
    "Установка пароля для данной учетной записи с этой рабочей станции недоступна";

  readonly EMAIL_INVALID_FORMAT_MESSAGE = "Адрес электронной почты имеет неверный формат";
  readonly DEFAULT_MOBILE_INVALID_FORMAT_MESSAGE = "Телефон имеет неверный формат";

  readonly PASSWORD_SOON_EXPIRED = "Срок действия пароля истекает ";
  readonly ACCOUNT_SOON_EXPIRED = "Назначенный период доступа истекает ";

  readonly PASSWORD_CHANGED_MESSAGE = "Пароль успешно изменен";
  readonly PASSWORD_SET_MESSAGE = "Пароль успешно установлен, Вы можете закрыть окно";

  readonly LOGOUT_SUCCESS_MESSAGE = "Выход из системы успешно выполнен";
  readonly LOGOUT_ERROR_MESSAGE = "Внимание! При выходе из системы произошла ошибка";

  readonly PASSWORD_POLICY_ERROR_MESSAGE = "Пароль не соответствует требованиям";
  readonly PASSWORD_POLICY_CHANGE_TOO_EARLY_ERROR_MESSAGE = "Слишком частая смена пароля";
  readonly INVALID_TOKEN_MESSAGE = "Ключ восстановления пароля неверен или устарел";

  readonly SET_PASSWORD_TOKEN_REQUIRED_MESSAGE = "Не указан код установки пароля";
  readonly RECOVER_PASSWORD_TOKEN_REQUIRED_MESSAGE = "Не указан код восстановления пароля";
  readonly INVALID_SET_PASSWORD_TOKEN_MESSAGE = "Ключ установки пароля неверен или устарел";

  readonly TOKEN_CREATED_MESSAGE = "Инструкция по дальнейшим действиям отправлена на ваш адрес электронной почты";

  readonly SESSION_CONFIRM_DATA_ERROR_MESSAGE = "При запросе данных об активных сеансах произошла ошибка";
  readonly SESSION_TERMINATE_ERROR_MESSAGE = "При завершении активного сеанса произошла ошибка";
  readonly SESSION_TERMINATE_ALL_ERROR_MESSAGE = "При завершении активных сеансов произошла ошибка";

  readonly CODE_REQUIRED_MESSAGE = "Не указан код подтверждения адреса электронной почты";

  readonly CODE_INVALID_OR_EXPIRED_MESSAGE = "Код подтверждения указан неверно или устарел, начните операцию заново";
  readonly DEFAULT_ERROR_MESSAGE =
    "Ошибка при отправке запроса, попробуйте повторить операцию позже или обратитесь в службу технической поддержки";

  readonly ACCESS_PERIOD_NOT_STARTED_MESSAGE = "Попытка входа вне назначенного периода доступа";
  readonly CHANGE_PASSWORD_ACCESS_PERIOD_NOT_STARTED_MESSAGE =
    "Попытка изменения пароля вне назначенного периода доступа";

  readonly ACCESS_PERIOD_ENDED_MESSAGE = "Попытка входа вне назначенного периода доступа";
  readonly CHANGE_PASSWORD_ACCESS_PERIOD_ENDED_MESSAGE = "Попытка изменения пароля вне назначенного периода доступа";
  readonly BAD_ACCESS_PERIOD_MESSAGE = "Попытка входа вне назначенного периода доступа";
  readonly CHANGE_PASSWORD_BAD_ACCESS_PERIOD_MESSAGE = "Попытка изменения пароля вне назначенного периода доступа";

  readonly NOT_HIRED_MESSAGE = "Нарушен период трудоустройства";
  readonly FIRED_MESSAGE = "Нарушен период трудоустройства";
  readonly BAD_EMPLOYMENT_PERIOD_MESSAGE = "Нарушен период трудоустройства";

  private static instance: DefaultErrorMessages;

  static get(): DefaultErrorMessages {
    if (!DefaultErrorMessages.instance) {
      DefaultErrorMessages.instance = new DefaultErrorMessages();
    }
    return DefaultErrorMessages.instance;
  }
}

export const defaultMessages = DefaultErrorMessages.get();

export const ERROR_PAGE_MESSAGE =
  "В процессе аутентификации произошла непредвиденная ошибка. Попробуйте повторно войти в систему. Если ошибка повторится, обратитесь к администратору информационной системы.";
