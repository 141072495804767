import post from "../common/post";
import { TwoFactorAuthPostApiRequest, TwoFactorAuthPostApiResponse } from "auth-ui-client-api";

export async function twoFactorAuthPostApiRequest({
  twoFactorAuthId,
  login,
  mobile,
  email,
  storageId,
}: {
  twoFactorAuthId: string;
  login: string | null;
  mobile: string | null;
  email: string | null;
  storageId: string;
}): Promise<TwoFactorAuthPostApiResponse> {
  const body = {
    twoFactorAuthId,
    login,
    mobile,
    email,
    storageId,
  };

  return await twoFactorAuthPostRequest(body);
}

function twoFactorAuthPostRequest(body: TwoFactorAuthPostApiRequest): Promise<TwoFactorAuthPostApiResponse> {
  return post<TwoFactorAuthPostApiRequest, TwoFactorAuthPostApiResponse>({
    url: `./api/public/two-factor-auth`,
    body,
  });
}
