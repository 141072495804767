import { isStartRecoveryApiError, StartRecoveryApiError, StartRecoveryApiResponse } from "auth-ui-client-api";
import React, { Dispatch, SetStateAction } from "react";
import {
  ComponentCreator,
  errorMessage,
  FeedbackMessageContent,
  Message,
  successMessage,
} from "../../components/message/Feedback";
import { CaptchaChangeAction } from "../../context/captcha/captchaActions";
import {
  isStartRecoveryFormValid,
  StartRecoveryClientValidationErrorReason,
  validateStartRecoveryForm,
} from "./validateStartRecoveryForm";
import { StartRecoveryFormData, StartRecoveryFormValidData } from "./StartRecoveryFormData";
import { clearCaptcha, setNewCaptcha } from "../common/dispatchCaptcha";
import { defaultMessages, ErrorMessages } from "../../localization/ErrorMessages";
import { FieldNames } from "../../localization/FieldNames";
import { requiredFieldsErrorMessage } from "../../components/message/requiredFieldsErrorMessage";
import { ValidationInfo } from "../utils/validationUtils";
import { TimeDateField } from "../../utils/TimeDateField";

export async function onStartRecoverySubmit({
  formData,
  setFeedback,
  setSubmitting,
  startRecoveryApiRequest,
  service,
  dispatchCaptcha,
  messages = defaultMessages,
  fieldNames,
  customValidationOptions,
}: Options): Promise<void> {
  const validationResult = validateStartRecoveryForm(formData);
  if (!isStartRecoveryFormValid(validationResult)) {
    customValidationOptions
      ? validationResult.map((reason) =>
          customClientValidationError(reason, messages, fieldNames, customValidationOptions, setFeedback)
        )
      : setFeedback(
          validationResult.map((reason) => errorMessage(clientValidationError(reason, messages, fieldNames)))
        );
    return;
  }
  setSubmitting(true);
  const response = await startRecoveryApiRequest(validationResult, service);
  setSubmitting(false);

  if (!response) {
    setFeedback([errorMessage(apiError({ errorCode: "INTERNAL_ERROR" }, messages))]);
    return;
  }

  customValidationOptions && customValidationOptions.captchaClearField();

  if (isStartRecoveryApiError(response)) {
    setNewCaptcha(dispatchCaptcha, response.captcha);
    setFeedback([errorMessage(apiError(response, messages))]);
    return;
  }
  clearCaptcha(dispatchCaptcha);
  setFeedback([successStartRecoveryMessage(response.maskedEmail!, messages)]); // почему-то сборщик не понимает, что response здесь всегда содержит maskedEmail, поэтому если не поставить ! build упадёт
}

const successStartRecoveryMessage = (maskedEmail: string, messages: ErrorMessages): Message => {
  return successMessage(() => <span>{`${messages.TOKEN_CREATED_MESSAGE} ${maskedEmail || ""}`}</span>);
};

const blockedEmailMessageCreator = (
  nextAttemptDate: number | null | undefined,
  messages: ErrorMessages
): ComponentCreator => {
  return () => (
    <div>
      <div>{messages.BLOCKED_EMAIL_MESSAGE}</div>
      {nextAttemptDate && (
        <div className="alert-row">
          {messages.NEXT_ATTEMPT_MESSAGE} <TimeDateField date={nextAttemptDate} />
        </div>
      )}
    </div>
  );
};

const customClientValidationError = (
  reason: StartRecoveryClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames,
  customValidationOptions: CustomValidationOptions,
  setFeedback: Dispatch<SetStateAction<Message[]>>
) => {
  switch (reason.code) {
    case "UNDEFINED_ENTER_METHOD":
      setFeedback([errorMessage(messages.UNDEFINED_ENTER_METHOD)]);
      return;
    case "EMPTY_FIELDS":
      setFeedback([
        errorMessage(
          requiredFieldsErrorMessage(
            reason.fields.map(fieldNames.fieldName),
            messages.FIELDS_REQUIRED_PREFIX,
            messages.FIELD_REQUIRED_PREFIX
          )
        ),
      ]);
      return;
    case "WRONG_EMAIL_FORMAT":
      customValidationOptions.setEmailValidation({
        validateStatus: "error",
        help: <div data-testid="ValidationMessage">{messages.EMAIL_INVALID_FORMAT_MESSAGE}</div>,
      });
      return;
    default:
      throw new Error(`Ошибка выполнения смены пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const clientValidationError = (
  reason: StartRecoveryClientValidationErrorReason,
  messages: ErrorMessages,
  fieldNames: FieldNames
): FeedbackMessageContent => {
  switch (reason.code) {
    case "UNDEFINED_ENTER_METHOD":
      return messages.UNDEFINED_ENTER_METHOD;
    case "EMPTY_FIELDS":
      return requiredFieldsErrorMessage(
        reason.fields.map(fieldNames.fieldName),
        messages.FIELDS_REQUIRED_PREFIX,
        messages.FIELD_REQUIRED_PREFIX
      );
    case "WRONG_EMAIL_FORMAT":
      return messages.EMAIL_INVALID_FORMAT_MESSAGE;
    default:
      throw new Error(`Ошибка выполнения смены пароля на локализована: ${JSON.stringify(reason)}`);
  }
};

const apiError = (apiError: StartRecoveryApiError, messages: ErrorMessages): FeedbackMessageContent => {
  let _exhaustedCode: never;
  switch (apiError.errorCode) {
    case "ACCOUNT_MULTIPLE_FOUND":
    case "ACCOUNT_NOT_FOUND":
    case "ACCOUNT_NOT_VALID":
    case "USER_NOT_FOUND":
    case "USER_NOT_VALID":
      return messages.RECOVERY_FORBIDDEN;
    case "IP_NOT_ALLOWED":
      return messages.RECOVERY_FROM_IP_NOT_ALLOWED;
    case "EMAIL_NOT_VALID":
      return messages.EMAIL_NOT_VALID_MESSAGE;
    case "USER_HAS_NO_EMAIL":
      return messages.USER_HAS_NO_EMAIL_MESSAGE;
    case "BAD_CAPTCHA":
      return messages.BAD_CAPTCHA_VALUE_MESSAGE;
    case "BLOCKED_EMAIL":
      return blockedEmailMessageCreator(apiError.data?.nextAttemptDate, messages);
    case "VALIDATION_ERROR":
    case "INTERNAL_ERROR":
    case "UNAVAILABLE":
      return messages.GENERAL_ERROR_MESSAGE;
    default:
      _exhaustedCode = apiError.errorCode;
      throw new Error(`Неизвестный код ошибки: ${_exhaustedCode}`);
  }
};

type Options = {
  formData: StartRecoveryFormData;
  service: string;
  setFeedback: Dispatch<SetStateAction<Message[]>>;
  setSubmitting: Dispatch<boolean>;
  dispatchCaptcha: Dispatch<CaptchaChangeAction>;
  startRecoveryApiRequest: (
    formData: StartRecoveryFormValidData,
    service: string
  ) => Promise<StartRecoveryApiResponse | null>;
  messages?: ErrorMessages;
  fieldNames: FieldNames;
  customValidationOptions?: CustomValidationOptions | null;
};

type CustomValidationOptions = {
  setEmailValidation: Dispatch<SetStateAction<ValidationInfo | undefined>>;
  captchaClearField: () => void;
};
