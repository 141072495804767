import { SessionConfirmApiRequestTypes, SessionConfirmApiResponse } from "auth-ui-client-api";
import post from "./common/post";

export async function sessionConfirmApiRequest(
  sessionConfirmToken: string,
  service: string
): Promise<SessionConfirmApiResponse | null> {
  const body = {
    sessionConfirmToken,
    service,
  };
  try {
    return await confirmRequest(body);
  } catch (err) {
    console.error(`Произошла ошибка при подтверждении входа`, err);
  }
  return null;
}

function confirmRequest(body: SessionConfirmApiRequestTypes): Promise<SessionConfirmApiResponse> {
  return post<SessionConfirmApiRequestTypes, SessionConfirmApiResponse>({ url: `./api/public/session-confirm`, body });
}
