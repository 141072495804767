import React from "react";
import { Client } from "auth-ui-client-api";

export function ClientField({ client }: Props): JSX.Element {
  return (
    <>
      <div className="client">
        <span>{client.ipAddress}</span>
        <span className="weak">{client.browser}</span>
        <span className="weak">{client.os}</span>
      </div>
    </>
  );
}

type Props = { client: Client };
