import React from "react";

export function RememberMeCheckbox({
  value,
  setter,
  label,
  canRememberMe,
}: Props): React.FunctionComponentElement<Props> | null {
  const id = "rememberMeCheck";
  return canRememberMe ? (
    <div className="checkbox">
      <input
        className="custom-checkbox"
        type="checkbox"
        id={id}
        checked={value}
        onChange={() => setter(!value)}
        data-testid="RememberMeCheckbox"
      />
      <label htmlFor={id} className="checkbox-label">
        {label}
      </label>
    </div>
  ) : (
    <div />
  );
}

type Props = {
  value: boolean;
  setter: (val: boolean) => void;
  label: string;
  canRememberMe: boolean;
};
