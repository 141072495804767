import { CaptchaTypeCode } from "../../types/CaptchaTypeCodes";

export type CaptchaCheckApiRequest = { captchaId: string | undefined };
export type CaptchaCheckApiResponse = CaptchaCheckApiSuccess | { errorCode: "INTERNAL_ERROR" };
export type CaptchaCheckApiSuccess = {
  type: CaptchaTypeCode;
  captchaImageBase64: string;
};
export const isCaptchaCheckApiSuccess = (data: CaptchaCheckApiResponse): data is CaptchaCheckApiSuccess =>
  !!data && "type" in data && data.type !== undefined;
