import { useEffect } from "react";
import { StorageItem } from "auth-ui-client-api";
import { getId } from "../../utils/clientCookies";

export const useStorage = (
  storageFromQuery: StorageItem | null,
  storageFromService: StorageItem | null,
  rememberSelectedValues: boolean,
  allStoragesFromService: StorageItem[],
  setStorage: (value: StorageItem | null) => void
): void => {
  useEffect(() => {
    if (storageFromQuery) {
      setStorage(storageFromQuery);
      return;
    }

    if (!rememberSelectedValues) {
      setStorage(storageFromService);
      return;
    }

    const storageIdFromCookie = getId(rememberSelectedValues, document, "selectedStorage");
    const storageFromCookie = getStorageFromOfflineValues(storageIdFromCookie, allStoragesFromService);

    setStorage(storageFromCookie || storageFromService);
  }, []);
};

const getStorageFromOfflineValues = (id: string | null, values: StorageItem[]): StorageItem | null => {
  if (id) {
    const storageIds = values.map((item) => item.id);
    const check = id && storageIds.includes(id);
    const storageCookieValue = values.find((item) => {
      return item.id === id;
    });
    return check && storageCookieValue ? storageCookieValue : null;
  }
  return null;
};
