export type TFAEmailApiRequest = {
  login: string | null;
  storageId: string;
  email: string | null;
  mobile: string | null;
};

export type TFAEmailApiResponse = TFAEmailApiSuccess | TFAEmailApiError;

export type TFAEmailApiSuccess = {
  code?: string;
  data?: string;
};

export type TFAEmailApiNeedsConfirmation = {
  sessionConfirmToken: string;
  warnAccountExpiresInDays?: number | null;
  warnPasswordExpiresInDays?: number | null;
};

export type TFAEmailApiError = {
  code?: string;
  message?: string;
  errorCode?: "INTERNAL_ERROR";
};

export const isTFAEMailApiError = (data: TFAEmailApiResponse): data is TFAEmailApiError =>
  !!data && "errorCode" in data && data.errorCode !== undefined;
