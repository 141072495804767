import React, { ReactElement } from "react";
import { changePasswordLink, MessageWithRedirect } from "auth-ui-client";
import { Identifier } from "auth-ui-client-api";

export const MustChangePassword = ({
  service,
  storageId,
  title,
  mustChangePasswordMessage,
  identifier,
}: Props): ReactElement => {
  return (
    <MessageWithRedirect
      url={changePasswordLink({ service, identifier, storageId })}
      messageTitle="Переход к смене пароля"
      linkTitle={title}
    >
      {mustChangePasswordMessage}
    </MessageWithRedirect>
  );
};

type Props = {
  service: string;
  storageId: string;
  title: string;
  mustChangePasswordMessage: string;
  identifier: Identifier;
};
