import { Identifier, RememberMeOption } from "auth-ui-client-api";
import { clientLogger } from "../../logger";

export function showRememberMeForIdentifier(identifier: Identifier, globalRememberMe: RememberMeOption): boolean {
  if (globalRememberMe === "ENABLED_FOR_ALL") {
    return true;
  }

  if (globalRememberMe === "DISABLED_FOR_ALL") {
    return false;
  }

  if (globalRememberMe === "DECIDE_BY_USER") {
    if (identifier.type === "SELECTED_ACCOUNT") {
      return identifier.account ? identifier.account.canRememberMe : false;
    } else {
      return false;
    }
  }

  clientLogger.error(`Неизвестное значении опции "Запомнить меня": ${globalRememberMe}`);
  return false;
}
