import { Account } from "./common";
import { Assignment } from "../api/get/assignmentsApiRequestTypes";

export const IdentifierTypes = {
  LOGIN: "LOGIN",
  MOBILE: "MOBILE",
  EMAIL: "EMAIL",
  SELECTED_ACCOUNT: "SELECTED_ACCOUNT",
} as const;
export type IdentifierType = keyof typeof IdentifierTypes;

export type Identifier = {
  login: string | null;
  email: string | null;
  mobile: string | null;
  account: Account | null;
  assignment: Assignment | null;
  type: IdentifierType;
};
