import React, { Dispatch, MouseEvent, ReactElement, SetStateAction } from "react";
import {
  captchaApiRequest,
  errorMessage,
  Message,
  setCaptchaImage,
  setCaptchaValue,
  useCaptchaContext,
} from "auth-ui-client";
import Image from "next/image";
import { isCaptchaCheckApiSuccess } from "auth-ui-client-api";

export const CaptchaPanel = ({ setErrors, label, placeholder, captchaImageError }: CaptchaPanelProps): ReactElement => {
  const [captcha, dispatchCaptcha] = useCaptchaContext();

  const updateCaptcha = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    dispatchCaptcha(setCaptchaValue(""));
    const captchaResponse = await captchaApiRequest(captcha.captchaId || "");
    if (isCaptchaCheckApiSuccess(captchaResponse)) {
      dispatchCaptcha(setCaptchaImage(captchaResponse.captchaImageBase64));
      return;
    }
    setErrors([errorMessage(captchaImageError)]);
  };

  return (
    <>
      {captcha.captchaImage ? (
        <>
          <div className="form-group toolbar">
            <div className="captcha">
              <Image alt="captcha" src={`data:image/png;base64, ${captcha.captchaImage}`} />
              <button
                onClick={updateCaptcha}
                className="update-captcha"
                title="Обновить картинку"
                type="button"
                data-testid="UpdateCaptchaButton"
              >
                <span className="captcha-refresh">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    width="20px"
                    height="20px"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.901 2.599A8 8 0 0 0 0 8h1.5a6.5 6.5 0 0 1 11.339-4.339L10.5 6H16V.5l-2.099 2.099z"
                      fill="currentColor"
                    />
                    <path
                      d="M14.5 8a6.5 6.5 0 0 1-11.339 4.339L5.5 10H0v5.5l2.099-2.099A8 8 0 0 0 16 8h-1.5z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="form-group">
            {label && <label htmlFor="captcha">{label}</label>}
            <input
              className="form-control"
              id="captcha"
              name="captcha"
              placeholder={placeholder}
              value={captcha.captchaValue || ""}
              maxLength={50}
              onChange={(event) => {
                dispatchCaptcha(setCaptchaValue(event.target.value));
              }}
              data-testid="CaptchaInput"
            />
          </div>
        </>
      ) : null}
    </>
  );
};

type CaptchaPanelProps = {
  setErrors: Dispatch<SetStateAction<Message[]>>;
  label?: string;
  placeholder?: string;
  captchaImageError: string;
};
