import { Identifier, IdentifierType, IdentifierTypes, StorageItem } from "auth-ui-client-api";

const ACTIVE_TAB_COOKIE_NAME = "activeTab";

export const getActiveTabCookie = (): IdentifierType | "" => {
  const matches = document.cookie.match(new RegExp("(?:^|; )" + ACTIVE_TAB_COOKIE_NAME + "=([^;]*)"));
  if (matches && matches[1] in IdentifierTypes) {
    return decodeURIComponent(matches[1]) as IdentifierType | "";
  }
  return "";
};
export const setActiveTabCookie = (value: string, document: Document): void => {
  setCookie(value, document, "activeTab");
};

export const getId = (rememberSelectedValues: boolean, document: Document, cookieName: string): string | null => {
  if (!rememberSelectedValues) {
    return null;
  }
  return getCookie(document, cookieName);
};

export const saveSelectedValuesInCookie = (
  rememberSelectedValues: boolean,
  storage: StorageItem | null,
  identifier: Identifier
): void => {
  if (!rememberSelectedValues) {
    return;
  }
  if (storage) {
    setCookie(storage.id, document, "selectedStorage");
  } else {
    deleteCookie("selectedStorage", document);
  }

  // Вне зависимости от текущего метода входа перезатираем cookie выбранных селектов
  if (identifier.type === "SELECTED_ACCOUNT" && identifier.account) {
    setCookie(identifier.account.id, document, "selectedAccount");
  } else {
    deleteCookie("selectedAccount", document);
  }

  if (identifier.type === "SELECTED_ACCOUNT" && identifier.assignment) {
    setCookie(identifier.assignment.id, document, "selectedAssignment");
  } else {
    deleteCookie("selectedAssignment", document);
  }
};
const setCookie = (value: string, document: Document, cookieName: string): void => {
  const secondsInYear: number = 3600 * 24 * 365;
  document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(value)}; path=${
    process.env.CONTEXT_ROOT
  }; max-age=${secondsInYear}`;
};

const getCookie = (document: Document, cookieName: string): string | null => {
  const matches = document.cookie.match(new RegExp("(?:^|; )" + cookieName + "=([^;]*)"));
  if (matches) {
    return decodeURIComponent(matches[1]);
  }
  return null;
};

const deleteCookie = (cookieName: string, document: Document): void => {
  document.cookie = `${encodeURIComponent(cookieName)}=""; path=${process.env.CONTEXT_ROOT}; max-age=-1`;
};
