import React, { ReactElement, useState } from "react";
import { PasswordPolicies } from "auth-ui-client-api";
import { CloseAlertButton } from "../buttons/CloseAlertButton";

export function PasswordPolicy({
  passwordPolicies,
  onGenerateClick,
  showHeader = true,
  allowClose = true,
}: Props): ReactElement | null {
  const [visible, setVisible] = useState(true);

  function toggleShowPasswordPolicy(): void {
    setVisible(!visible);
  }

  const show = visible && passwordPolicies && passwordPolicies.length;
  return show ? (
    <div>
      <div role="alert" className="policies-container alert-info alert">
        <div>
          {showHeader && <h4>Политика паролей</h4>}
          <div>
            <PasswordPolicyList passwordPolicies={passwordPolicies} />
            {onGenerateClick && <PasswordPolicyGenerateLink onGenerateClick={onGenerateClick} />}
          </div>
        </div>
        {allowClose && <CloseAlertButton onClick={toggleShowPasswordPolicy} dataTestId="ClosePasswordPolicyButton" />}
      </div>
    </div>
  ) : null;
}

function PasswordPolicyList({ passwordPolicies }: PasswordPolicyListProps): ReactElement | null {
  const show = passwordPolicies && passwordPolicies.length;
  return show ? (
    <ul>
      {passwordPolicies?.map((policy, index) => (
        <li key={index} className={!policy[1] ? "accent" : ""}>
          {policy[0]}
          {policy[1] ? " - " : null}
          {policy[1] ? <span className="accent">{policy[1]}</span> : null}
        </li>
      ))}
    </ul>
  ) : null;
}

function PasswordPolicyGenerateLink({ onGenerateClick }: PasswordPolicyGenerateLinkProps): ReactElement | null {
  return onGenerateClick ? (
    <div className="btn-line">
      <a
        className="link-action"
        onClick={onGenerateClick}
        title="Сгенерировать пароль на основе политик"
        data-testid="GenerateLink"
      >
        Сгенерировать пароль
      </a>
    </div>
  ) : null;
}

type PasswordPolicyListProps = {
  passwordPolicies: PasswordPolicies | undefined | null;
};
type PasswordPolicyGenerateLinkProps = {
  onGenerateClick?: () => void;
};

type Props = {
  passwordPolicies: PasswordPolicies | undefined | null;
  onGenerateClick?: () => void;
  showHeader?: boolean;
  allowClose?: boolean;
};
