import React, { ReactElement, useState } from "react";
import { ShowAdditionalInfoBtn } from "./ShowAdditionalInfoBtn";
import { StartedAtField } from "./StartedAtField";
import { ServicesField } from "./ServicesField";
import { ClientField } from "./ClientField";
import { StatusField } from "./StatusField";
import { ActiveSession, LastLogonData } from "auth-ui-client-api";
import { logonStatusInfo } from "./utils/statusInfo";
import { AdditionalInfoPanel } from "./AdditionalInfoPanel";
import { FieldNames } from "../../localization/FieldNames";

export function SessionListItem({
  item,
  children,
  isLoginIdentifierType,
  fieldNames,
  userIcon,
  arrowUpIcon,
  arrowDownIcon,
}: Props): JSX.Element {
  const [isOpenedAdditionalInfo, setIsOpenedAdditionalInfo] = useState<boolean>(false);
  const showAdditional = !isLoginIdentifierType && !!item.additional?.identifierType;

  return (
    <>
      <div className="SessionListItem">
        <ShowAdditionalInfoBtn
          isOpenedUserInfo={isOpenedAdditionalInfo}
          setIsOpenedUserInfo={setIsOpenedAdditionalInfo}
          showIcon={showAdditional}
          userIcon={userIcon}
          arrowUpIcon={arrowUpIcon}
          arrowDownIcon={arrowDownIcon}
        />
        <StartedAtField date={item.startedAt} />
        <ServicesField services={item.services} />
        <ClientField client={item.client} />
        <StatusField statusInfo={logonStatusInfo(item.status)} />
        {children}
      </div>
      {isOpenedAdditionalInfo && showAdditional && (
        <AdditionalInfoPanel
          data={item.additional}
          localization={{
            login: fieldNames.LOGIN_LABEL,
            email: fieldNames.EMAIL_LABEL,
            mobile: fieldNames.MOBILE_LABEL,
            account: fieldNames.ACCOUNT_LABEL,
          }}
        />
      )}
    </>
  );
}

type Props = {
  item: ActiveSession | LastLogonData;
  children?: ReactElement;
  isLoginIdentifierType: boolean;
  fieldNames: FieldNames;
  userIcon?: ReactElement;
  arrowUpIcon?: ReactElement;
  arrowDownIcon?: ReactElement;
};
