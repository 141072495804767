import { SessionCancelApiRequestTypes, SessionCancelApiResponse } from "auth-ui-client-api";
import del from "./common/del";

export async function sessionCancelApiRequest(sessionConfirmToken: string): Promise<SessionCancelApiResponse | null> {
  try {
    return await confirmCancelRequest(sessionConfirmToken);
  } catch (err) {
    console.error(`Произошла ошибка при отмене неподтвержденной сессии`, err);
  }
  return null;
}

function confirmCancelRequest(sessionConfirmToken: string): Promise<SessionCancelApiResponse> {
  const body = { sessionConfirmToken };
  return del<SessionCancelApiRequestTypes, SessionCancelApiResponse>({
    url: `./api/public/session-confirm/${sessionConfirmToken}`,
    body,
  });
}
