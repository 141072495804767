import {
  GetItems,
  GetItemsResponse,
  ItemsPage,
  ObjectWithStringValueOfK,
  Property,
  StringProperty,
} from "../properties";
import { filterStaticItems } from "./utils/filterStaticItems";

const getStaticItemsActionCreator = <
  DomainObject extends ObjectWithStringValueOfK<FilterProperty>,
  FilterProperty extends Property<DomainObject>
>(
  allItems: DomainObject[],
  filterProp: StringProperty<DomainObject, FilterProperty>
): GetItems<DomainObject> => (_: number, criteria: string | undefined): GetItemsResponse<ItemsPage<DomainObject>> =>
  Promise.resolve({
    items: filterStaticItems<DomainObject, FilterProperty>(allItems, filterProp, criteria),
    hasMore: false,
  });

export const createStaticSelectAction = <
  DomainObject extends ObjectWithStringValueOfK<FilterProperty>,
  FilterProperty extends Property<DomainObject>
>(
  elems: DomainObject[] | null,
  filterProp: StringProperty<DomainObject, FilterProperty>
): GetItems<DomainObject> =>
  elems
    ? getStaticItemsActionCreator<DomainObject, FilterProperty>(elems, filterProp)
    : () => Promise.resolve({ error: true });
