import React, { ReactElement } from "react";
import { Account } from "auth-ui-client-api";
import { UserAccountLabel } from "../label/UserAccountLabel";
import { CaretDownFilled, CloseOutlined } from "@ant-design/icons";
import { SelectNext } from "../select/SelectNext";
import { createSelectAction } from "../select/actions/selectNextActionCreators";

export function AccountSelectField({
  id,
  value,
  setter,
  storageId,
  placeholder,
}: Props): React.FunctionComponentElement<Props> {
  const onChange = (value?: Account) => {
    setter(value ?? null);
  };

  return (
    <SelectNext<Account>
      id={id}
      key={JSON.stringify(value)}
      allowClear
      defaultValue={value ?? undefined}
      onChange={onChange}
      getItems={createSelectAction(`./api/public/accounts`, { storageId })}
      render={render}
      getId="id"
      placeholder={placeholder}
      parentSelector="#account-select"
      notFoundContent={
        <div data-testid="AccountNotFoundContent">
          <span>Данные не найдены</span>
        </div>
      }
      suffixIcon={<CaretDownFilled />}
      clearIcon={<CloseOutlined />}
      data-testid="AccountSelect"
      dropdownAlign={{ offset: [0, -5] }} // должен соответствовать border-radius-у
    />
  );
}

const render = (domainObject: Account, searchParam?: string): ReactElement => {
  return <UserAccountLabel name={domainObject.userDisplayName} criteria={searchParam} login={domainObject.login} />;
};

type Props = {
  id: string;
  placeholder?: string;
  value?: Account | null;
  setter: (val: Account | null) => void;
  storageId: string | null;
};
