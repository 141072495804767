import { addIfEmpty, addIfEmptyIdentifier, isEmailValid, isMobileValid } from "../utils/validationUtils";
import { ChangePasswordFormData, ChangePasswordFormValidData } from "./ChangePasswordFormData";
import { Identifier, StorageItem, ValidationProperties } from "auth-ui-client-api";
import { CaptchaWithValue } from "../../context/captcha/types";

type ChangePasswordValidationResult = ChangePasswordFormValidData | ChangePasswordClientValidationErrorReason[];

export type ChangePasswordClientValidationErrorReason =
  | { code: "UNDEFINED_ENTER_METHOD" }
  | { code: "WRONG_MOBILE_FORMAT"; description: string | null }
  | { code: "WRONG_EMAIL_FORMAT" }
  | { code: "MISMATCHING_PASSWORDS" }
  | { code: "EMPTY_FIELDS"; fields: ChangePasswordValidationEmptyField[] };

export type ChangePasswordValidationEmptyField =
  | "STORAGE"
  | "LOGIN"
  | "EMAIL"
  | "MOBILE"
  | "ACCOUNT"
  | "OLD_PASSWORD"
  | "NEW_PASSWORD"
  | "REPEAT_PASSWORD"
  | "CAPTCHA"
  | "ASSIGNMENT";

export const isChangePasswordFormValid = (
  data: ChangePasswordValidationResult
): data is ChangePasswordFormValidData => {
  return data && "identifier" in data && data.identifier !== undefined;
};

export function validateChangePasswordForm(
  validation: ValidationProperties,
  formData: ChangePasswordFormData,
  assignmentsNotEmpty: boolean
): ChangePasswordValidationResult {
  const { captcha, storage, identifier, oldPassword, newPassword, repeatPassword, os, browser } = formData;

  if (!identifier) {
    return [{ code: "UNDEFINED_ENTER_METHOD" }];
  }
  const errors: ChangePasswordClientValidationErrorReason[] = [];

  const emptyFields = checkChangePasswordFormEmptiness(
    captcha,
    identifier,
    storage,
    oldPassword,
    newPassword,
    repeatPassword,
    assignmentsNotEmpty
  );
  if (emptyFields && emptyFields.length) {
    errors.push({ code: "EMPTY_FIELDS", fields: emptyFields });
  }

  if (!isMobileValid(identifier, validation)) {
    errors.push({ code: "WRONG_MOBILE_FORMAT", description: validation.validationMobileRegexpExplanation });
  }

  if (!isEmailValid(identifier)) {
    errors.push({ code: "WRONG_EMAIL_FORMAT" });
  }

  if (!areNewPasswordsEqual(newPassword, repeatPassword)) {
    errors.push({ code: "MISMATCHING_PASSWORDS" });
  }

  // для компиляции ts
  if (errors.length || !storage || !oldPassword || !repeatPassword) {
    return errors;
  }
  return new ChangePasswordFormValidData(captcha, storage, identifier, oldPassword, repeatPassword, os, browser);
}

const areNewPasswordsEqual = (newPassword: string, confirmNewPassword: string): boolean => {
  if (!newPassword && !confirmNewPassword) {
    return true;
  }
  return newPassword === confirmNewPassword;
};

export const checkChangePasswordFormEmptiness = (
  captcha: CaptchaWithValue,
  identifier: Identifier,
  storage: StorageItem | null,
  oldPassword: string | null,
  newPassword: string | null,
  repeatPassword: string | null,
  assignmentsNotEmpty: boolean
) => {
  const emptyFields: ChangePasswordValidationEmptyField[] = [];
  addIfEmpty<ChangePasswordValidationEmptyField>("STORAGE", emptyFields, storage?.id);
  addIfEmptyIdentifier<ChangePasswordValidationEmptyField>(identifier, emptyFields, {
    login: "LOGIN",
    email: "EMAIL",
    mobile: "MOBILE",
    account: "ACCOUNT",
  });
  if (identifier.type === "SELECTED_ACCOUNT" && assignmentsNotEmpty) {
    addIfEmpty<ChangePasswordValidationEmptyField>("ASSIGNMENT", emptyFields, identifier.assignment?.id);
  }
  addIfEmpty<ChangePasswordValidationEmptyField>("OLD_PASSWORD", emptyFields, oldPassword);
  addIfEmpty<ChangePasswordValidationEmptyField>("NEW_PASSWORD", emptyFields, newPassword);
  addIfEmpty<ChangePasswordValidationEmptyField>("REPEAT_PASSWORD", emptyFields, repeatPassword);
  if (captcha.captchaImage) {
    addIfEmpty<ChangePasswordValidationEmptyField>("CAPTCHA", emptyFields, captcha.captchaValue);
  }

  return emptyFields;
};
