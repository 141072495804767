import React, { ReactElement, useCallback, useRef } from "react";
import { useAutofillEffect } from "../../hooks/useAutofillEffect";
import { Identifier } from "auth-ui-client-api";
import { Input, InputRef } from "antd";

export function TextInput({
  identifier,
  setIdentifier,
  placeholder,
  className = "form-control",
  identifierType,
  name
}: Props): ReactElement {
  const textField = useRef<InputRef | null>(null);

  useAutofillEffect(
    textField,
    useCallback(
      (value?: string) => {
        setIdentifier(value?.trim() || "");
      },
      [setIdentifier]
    )
  );

  const maxlength = () => {
    switch (true) {
      case identifier.type === "EMAIL":
        return 100;
      default:
        return 128;
    }
  };

  const testId = identifierType === "LOGIN" ? "LoginInput" : identifierType === "EMAIL" ? "EmailInput" : "TextInput";

  const clearInitialValue = (value: string) => {
    setIdentifier(value?.trim());
  };

  const normalizeAndSaveInput = (value: string) => {
    setIdentifier(value);
  };

  return identifier ? (
    <Input
      id={identifierType}
      key={"text"}
      ref={(hRef) => {
        textField.current = hRef;
      }}
      title={placeholder}
      placeholder={placeholder}
      name={name}
      className={className}
      maxLength={maxlength()}
      value={textValue(identifier)}
      onChange={(event) => {
        normalizeAndSaveInput(event.target.value);
      }}
      onBlur={(event) => {
        clearInitialValue(event.target.value);
      }}
      data-testid={testId}
    />
  ) : (
    <></>
  );
}

const textValue = (identifier: Identifier): string => {
  switch (identifier.type) {
    case "LOGIN":
      return identifier.login || "";
    case "EMAIL":
      return identifier.email || "";
    default:
      return "";
    // TODO: Будет переделано в рамках другой задачи по переделке формы логина
    // throw new Error(`Неизвестный способ входа со строковым идентификатором: ${JSON.stringify(identifier)}`);
  }
};

type Props = {
  identifierType?: string;
  name?: string;
  identifier: Identifier;
  setIdentifier: (value: string) => void;
  placeholder?: string;
  className?: string;
};
